import React from 'react';
import { List, Space, Table } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import EntityTag from '../../../tk/bits/EntityTag';
import entityDefs from '../entities/entityDefs';
import './UploadResponseView.css';
import PropTypes from 'prop-types';


const UploadResponseView = (props) => {
    const { data, dimensions, entityDef } = props;

    if (data.filename === undefined) return <div></div>;

    const renderEntityRef = (entityRef, entityDef) => {
        if (entityRef === undefined) return <span>undefined</span>;
        if (entityRef.id === undefined) return entityRef;
        if (entityRef.id === null) return <span></span>;
        return <EntityTag entityRef={entityRef} entityDef={entityDef} />;
    };

    const renderEntityRefList = (list, entityDef) => {
        if (list === '') return <span>none</span>;
        if (!Array.isArray(list)) return renderEntityRef(list, entityDef); // if it is in the raw entity, there is no list, yet
        return (<>
            {list.map(entry => renderEntityRef(entry, entityDef))}
        </>);
    };

    const renderStatus = status => {
        if (status === undefined) return undefined;
        if (status.renderMeTag) {
            return <div style={{ paddingLeft: '40px' }}><EntityTag entityDef={entityDef} entityRef={status.me} /></div>;
        }
        if (status.ok) {
            if (status.updateEntityId === undefined || status.updateEntityId === null) {
                return <span><CheckCircleOutlined />&nbsp;&nbsp;&nbsp; new</span>;
            } else {
                return <span><CheckCircleOutlined />&nbsp;&nbsp;&nbsp; update <EntityTag
                    entityRef={{ id: status.updateEntityId }} entityDef={entityDef} /></span>;
            }
        }
        return (
            <ul style={{ marginBottom: '1px' }}>
                {status.errors.map((error, index) => {
                        const entityDefErr = entityDefs[error.relatedEntitiesType];
                        return <li key={index}>
                            {error.message}
                            <Space wrap size={[0, 0]}>
                                {error.relatedEntities && error.relatedEntities.length > 0 && !status.isParent &&
                                    error.relatedEntities.map(related =>
                                        <EntityTag
                                            entityRef={{ id: related }}
                                            entityDef={entityDefErr}
                                        />
                                    )}
                            </Space>
                        </li>;
                    }
                )}
            </ul>
        );
    };

    const prettyPrintLabel = label => {
        return label
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, function(str) {
                return str.toUpperCase();
            });
    };

    if (data.errors && data.errors.length > 0) {
        return (
            <List size="small" bordered>
                {data.errors && data.errors.map((error, index) =>
                    <List.Item key={index}>{error}</List.Item>
                )}
            </List>
        );
    }

    if (data.columnDefinitions === null) {
        data.columnDefinitions = [];
    }

    const columns = data.columnDefinitions.map(col => {
        let colDef = {
            title: prettyPrintLabel(col.label),
            dataIndex: col.label,
            key: col.label,
            width: 150,
            ellipsis: true,
            sorter: (a, b) => {
                if (a[col.label] > b[col.label]) {
                    return 1;
                } else if (a[col.label] < b[col.label]) {
                    return -1;
                } else {
                    return 0;
                }
            }
        };
        if (col.dataTypeDef.dataType === 'ENTITY' || col.dataTypeDef.dataType === 'ENUM') {
            const entityDef = entityDefs[col.dataTypeDef.entityType];
            if (col.dataTypeDef.isList) {
                colDef.render = list => renderEntityRefList(list, entityDef);
            } else {
                colDef.render = entityRef => renderEntityRef(entityRef, entityDef);
            }
        }
        return colDef;
    });
    columns.unshift({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
        fixed: 'left',
        filters: [
            {
                text: <span>OK</span>,
                value: true
            },
            {
                text: <span>Errors</span>,
                value: false
            }
        ],
        onFilter: (value, record) => record.status.ok === value,
        render: renderStatus
    });
    columns.unshift({
        title: '#',
        fixed: 'left',
        width: 70,
        render: (text, record, index) => {
            if (record.key < 0) {
                return <span></span>;
            }
            return <span>{record.key + 1}</span>;
        }
    });

    function makeRenderFromDbEntity(key, dbEntity) {
        let newVar = {
            key: key,
            ...dbEntity
        };
        if (dbEntity.attributes !== undefined) {
            dbEntity.attributes.forEach(attribute => {
                newVar[attribute.entityRef.name] = attribute.value;
            });
        }
        return newVar;
    }

    const dataSource = data.rows.map((row, index) => {
            if (row.dbEntity != null) {
                let newVar = makeRenderFromDbEntity(index, row.dbEntity);

                if (row.rawEntity !== null && row.rawEntity.attachment !== undefined) {
                    newVar.attachment = row.rawEntity.attachment;
                }

                if (row.errors.length > 0) {
                    newVar.status = {
                        ok: false,
                        errors: row.errors
                    };
                    if (row.errors[0].code === 6 && row.prevEntity) {
                        const child = makeRenderFromDbEntity(index - 100000, row.prevEntity);
                        child.status = {};
                        child.status.me = {
                            id: child[entityDef.idProp]
                        };
                        child.status.renderMeTag = true;
                        newVar.children = [child];
                        newVar.status.isParent = true;
                    }
                } else {
                    newVar.status = {
                        ok: true,
                        updateEntityId: row.updateEntityId
                    };
                }

                return newVar;
            }
            return {
                key: index,
                ...row.rawEntity,
                status: {
                    ok: false,
                    errors: row.errors
                }
            };
        }
    );

    console.log('ERR SOURCE', dataSource);

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
                position: ['bottomLeft'],
                total: dataSource.size

            }}
            size="small"
            scroll={{
                x: 300,
                y: dimensions.height - 145
            }}
        />
    );
};

UploadResponseView.propTypes = {
    data: PropTypes.object,
    dimensions: PropTypes.object,
    entityDef: PropTypes.object
};

export default UploadResponseView;
