import React, { useState } from 'react';
import useWS2Axios from '../../hooks/useWS2Axios';
import useEntity from '../../hooks/useEntity';
import entityDefs from '../../pages/editorial/entities/entityDefs';
import { getEntity } from '../../lib/entityRequests';
import { Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;


const AddAttributesFromSchemaSelector = ({ entityType, fields, append }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { ws2Axios } = useWS2Axios();
    const { entity } = useEntity(entityDefs.term, 52932); // Data Model Extensions, PANGAEA

    let schemas = [];
    if (entity) {
        schemas = entity.termsRelatedReverse
            .filter(rev => rev.relationType.id === 1) // narrower term
            .map(rev => rev.term);
    }

    const getNarrowerTermAttributeRecursive = async entityRef => {
        const entity = await getEntity(ws2Axios, entityDefs.term, entityRef.id);
        if (entity.termCategory.id === 4) { // attributes
            return [entityRef];
        } else if (entity.termCategory.id === 3) { // classes
            const promises = entity.termsRelatedReverse
                .filter(rev => rev.relationType.id === 1) // narrower term
                .map(rev => getNarrowerTermAttributeRecursive(rev.term));
            const results = await Promise.all(promises);
            return results.flat();
        } else {
            return [];
        }
    };

    return (
        <Select
            showSearch
            style={{ width: '300px', minWidth: '200px' }}
            value={{ value: null, label: <span><PlusOutlined /> Add terms from schema</span> }}
            onChange={async v => {
                if (v === undefined) {
                    return;
                }
                try {
                    const entity = await getEntity(ws2Axios, entityDefs.term, v.value);
                    const sups = entity.termsRelatedReverse
                        .filter(rev => rev.relationType.id === 1) // narrower term
                        .map(rev => rev.term)
                        .filter(term => term.commonName.startsWith('level: ' + entityType));
                    console.log('Look at term', entity);
                    if (sups.length === 0) {
                        throw new Error('Cannot find level: ' + entityType);
                    }
                    const entityRef = sups[0];
                    const list = await getNarrowerTermAttributeRecursive(entityRef);
                    list.forEach(entityRef => {
                        const exists = fields.find(r => {
                            return r.term.id === entityRef.id;
                        });
                        if (!exists) {
                            append({ term: entityRef, value: '' });
                        }
                    });
                    setDropdownVisible(false);
                } catch (err) {
                    console.log('ERROR: ', err);
                }
            }}
            filterOption={false}
            notFoundContent={null}
            allowClear={false}
            labelInValue={true}
            placeholder={'Add terms from schema'}
            open={dropdownVisible}
            onDropdownVisibleChange={open => {
                if (!open) {
                    setDropdownVisible(false);
                }
            }}
            onInputKeyDown={() => {
                setDropdownVisible(true);
            }}
            onSelect={() => {
                setDropdownVisible(false);
            }}
            onClick={() => {
                setDropdownVisible(true);
            }}
            dropdownRender={menu => menu}
            popupMatchSelectWidth={300}
        >
            {schemas.map(entityRef => {
                    console.log('schema selection', entityRef);
                    return <Option key={entityRef.id} value={entityRef.id}>{entityRef.commonName}</Option>;
                }
            )}
        </Select>

    );
};

export default AddAttributesFromSchemaSelector;
