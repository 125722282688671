import React, {useState} from "react";
import {entityFields} from "../entities/entityFields";
import SelectMultiEntityInput from "../../../tk/input/SelectMultiEntityInput";
import entityDefs from "../entities/entityDefs";
import EntityTag from "../../../tk/bits/EntityTag";
import {Button, Row, Space} from "antd";
import {pangaeaColor} from "../../../lib/globalColors";


const ListExport = ({entityDef, rsql, onClose}) => {
    const allFields = entityFields[entityDef.entityType]
        .filter(field => field.paramName !== 'parentDataset')
        .filter(field => field.type && field.type.dataType !== "entityRefList");

    const [selectedFields, setSelectedFields] = useState([]);

    const select = selectedFields
        .map(selectedFields => selectedFields.id)
        .reduce((acc, curr) => acc ? acc + "," + curr : curr, undefined);

    return (
        <>
            <div>
                EntityType: {entityDef.label}<br/>
                RSQL: {rsql}<br/><br/>
                Available columns<br/>
                {allFields.map(field => {
                    const entityRef = {id: field.paramName, name: field.label};
                    return (
                        <EntityTag
                            key={entityRef.id}
                            entityDef={entityDefs.fieldDesc}
                            entityRef={entityRef}
                            onClick={() => {
                                if (selectedFields.find(e => e.id === entityRef.id)) return;
                                setSelectedFields([...selectedFields, entityRef]);
                            }}
                        />
                    );
                })}
                <br/><br/>
                Selected columns
                <SelectMultiEntityInput
                    value={selectedFields}
                    onChange={setSelectedFields}
                    entityDef={entityDefs.fieldDesc}
                    disableEntitySearch={true}
                />
            </div>
            <br/>
            <Row justify="end">
                <Space>
                    <Button onClick={onClose}>
                        Close
                    </Button>
                    <a
                        href={import.meta.env.VITE_APIBASEURL + "query/list/select/" + entityDef.entityType + "?q=" + rsql + (select ? "&select=" + select : "")}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            color: '#fff',
                            backgroundColor: pangaeaColor
                        }}
                    >
                        Download
                    </a>
                </Space>
            </Row>
        </>
    );
}

export default ListExport;
