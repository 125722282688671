import { emptyEntityRef } from '../../../lib/entityUtils';
import EntityFormItem from '../../../tk/forms/EntityFormItem';
import TextAreaLimitedField from '../../../tk/input/TextAreaLimitedField';
import React from 'react';
import InputLimitedField from '../../../tk/input/InputLimitedField';
import { SelectInputField } from '../../../tk/input/SelectInputField';
import NumberInputField from '../../../tk/input/NumberInputField';
import UriInputField from '../../../tk/input/UriInputField';
import SelectEntityField from '../../../tk/input/SelectEntityField';
import TextAreaField from '../../../tk/input/TextAreaField';
import entityDefs from './entityDefs';
import DateInputField from '../../../tk/input/DateInputField';
import DatetimeInputField from '../../../tk/input/DatetimeInputField';
import InputField from '../../../tk/input/InputField';
import TicketInputField from '../../../tk/input/TicketInputField';
import dayjs from 'dayjs';
import ReadonlyField from '../detailforms/ReadonlyField';
import SelectMultiEntityField from '../../../tk/input/SelectMultiEntityField';
import AttributeTableField from '../../../tk/inputComplex/AttributeTableField';
import RelatedReferenceTableField from '../../../tk/inputComplex/RelatedReferenceTableField';
import RelatedDatasetTableField from '../../../tk/inputComplex/RelatedDatasetTableField';
import SelectSequencedEntityField from '../../../tk/input/SelectSequencedEntityField';
import DatasetStaffsTableField from '../../../tk/inputComplex/DatasetStaffsTableField';
import { renderEditableNumberInput } from '../../test/TestPage2';

const fieldType = {
    textAreaLimited: {
        dataType: 'string',
        empty: '',
        render: field => (
            <TextAreaLimitedField
                {...field}
                maxLength={field.limit}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    textArea: {
        dataType: 'string',
        empty: '',
        render: field => {
            return (
                <TextAreaField
                    {...field}
                    rules={{ required: field.required }}
                    autoFocus={field.autoFocus}
                />
            );
        }
    },
    dataSeriesConfigFormat: {
        dataType: 'string',
        special: true,
        empty: '',
        render: field => {
            return (
                <TextAreaField
                    {...field}
                    rules={{ required: field.required }}
                    autoFocus={field.autoFocus}
                />
            );
        }
    },
    input: {
        dataType: 'string',
        empty: '',
        render: field => (
            <InputField
                {...field}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    inputLimited: {
        dataType: 'string',
        empty: '',
        render: field => (
            <InputLimitedField
                {...field}
                maxLength={field.limit}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    selectType: {
        dataType: 'entityRef',
        empty: emptyEntityRef,
        render: field => (
            <SelectInputField
                {...field}
                enumType={field.entityDef}
                rules={{ validate: entityRef => !field.required || entityRef.id !== null || 'HALT' }}
                autoFocus={field.autoFocus}
            />
        )
    },
    longType: {
        dataType: 'long',
        empty: null,
        render: field => (
            <NumberInputField
                {...field}
                rules={{ required: field.required }}
                min={field.min}
                max={field.max}
                autoFocus={field.autoFocus}
            />
        )
    },
    doubleType: {
        dataType: 'double',
        empty: null,
        render: field => (
            <NumberInputField
                {...field}
                rules={{ required: field.required }}
                min={field.min}
                max={field.max}
                autoFocus={field.autoFocus}
            />
        )
    },
    uriType: {
        dataType: 'string',
        empty: '',
        render: field => (
            <UriInputField
                {...field}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    ticketType: {
        dataType: 'string',
        empty: '',
        render: field => (
            <TicketInputField
                {...field}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    MultiEntityRefType: {
        dataType: 'entityRefList',
        special: true,
        empty: [],
        valueKey: 'entityRef',
        render: field => (
            <SelectMultiEntityField
                {...field}
                size="tiny"
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    MultiEntityRefSortableType: {
        dataType: 'entityRefList',
        empty: [],
        valueKey: 'entityRef',
        render: field => (
            <SelectMultiEntityField
                {...field}
                size="tiny"
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
                sortable={true}
            />
        )
    },
    RelatedReferenceType: {
        dataType: 'entityRefList',
        special: true,
        empty: [],
        // fieldNameMappings: {reference: "entityRef", relationType: "relation"},
        fieldNameMappings: (dataValue) => {
            const mappings = { reference: 'entityRef', relationType: 'relation' };
            let dataValueRenamedKeys = {};
            Object.keys(dataValue).forEach(key => {
                const newKey = mappings[key] || key;
                dataValueRenamedKeys[newKey] = dataValue[key];
            });
            return dataValueRenamedKeys;
        },
        valueKey: 'relatedEntityRef',
        render: field => (<RelatedReferenceTableField
            paramName={field.paramName} //in this case 'references.value'
        />),
        remove: {
            render: field => {
                return <SelectMultiEntityField
                    {...field}
                    paramName={(field.paramName)}
                    size="tiny"
                    rules={{ required: field.required }}
                    autoFocus={field.autoFocus}
                />;
            },
            fieldNameMappings: (dataValue) => {
                return { entityRef: { ...dataValue } };
            }
        }
    },
    RelatedDatasetType: {
        dataType: 'entityRefList',
        special: true,
        empty: [],
        // fieldNameMappings: {datasetRelated: "entityRef", relationType: "relation"},
        fieldNameMappings: (dataValue) => {
            const mappings = { datasetRelated: 'entityRef', relationType: 'relation' };
            let dataValueRenamedKeys = {};
            Object.keys(dataValue).forEach(key => {
                const newKey = mappings[key] || key;
                dataValueRenamedKeys[newKey] = dataValue[key];
            });
            return dataValueRenamedKeys;
        },
        valueKey: 'relatedEntityRef',
        render: field => (<RelatedDatasetTableField
            paramName={field.paramName} //in this case 'datasets.value'
            // .value is appended to the name attribute of an input element to indicate the actual value of the input (react hook form)
        />),
        remove: {
            render: field => {
                return <SelectMultiEntityField
                    {...field}
                    paramName={(field.paramName)}
                    size="tiny"
                    rules={{ required: field.required }}
                    autoFocus={field.autoFocus}
                />;
            },
            fieldNameMappings: (dataValue) => {
                return { entityRef: { ...dataValue } };
            }
        }
    },
    ValuedEntityRefType: (attributeEntityType) => ({
        dataType: 'entityRefList',
        special: true,
        empty: [],
        valueKey: 'valuedEntityRef', //TODO unncessary? Delete?
        render: field => {
            //TODO: campaign?? dataset?
            return <AttributeTableField
                entityType={attributeEntityType}
                paramName={field.paramName}
                size="tiny"
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />;
        }
    }),
    SequentialEntityRefType: {
        dataType: 'entityRefList',
        special: true,
        empty: [], //TODO: customEmpty type?,
        valueKey: 'sequencedEntityRef',
        render: field => {
            return <SelectSequencedEntityField
                paramName={field.paramName}
                entityDef={field.entityDef}
            />;
        },
        remove: {
            render: field => {
                return <SelectMultiEntityField
                    {...field}
                    paramName={(field.paramName)}
                    size="tiny"
                    rules={{ required: field.required }}
                    autoFocus={field.autoFocus}
                />;
            },
            valueKey: 'entityRef'
        }
    },
    StaffsWithAffiliationsEntityRef: {
        dataType: 'entityRefList',
        special: true,
        empty: [], //TODO: customEmpty type?,
        valueKey: 'staffsWithAffiliationsEntityRef',
        //to avoid f[indexColumn] not found error when switching between ADD and REMOVE
        //use addKey, removeKey in corresponding entity field
        fieldNameMappings: (dataValue) => {
            if (dataValue.staffs) {
                const datasetStaffs = {
                    staffs: dataValue.staffs,
                    institution: dataValue.institution,
                    institution2: dataValue.institution2
                };
                return {
                    datasetStaffs,
                    sequence: dataValue.sequence
                };
            } else {
                return dataValue;
            }
        },
        render: field => {
            return <DatasetStaffsTableField
                paramName={field.paramName}
                batchColumn={{
                    index: 2,
                    column: {
                        title: 'Staff N (sequence)',
                        dataIndex: 'sequence',
                        width: 90,
                        render: renderEditableNumberInput(field.paramName, 'sequence', 1, { width: 80, minWidth: 80 })
                    }
                }}
            />;
        },
        remove: {
            render: field => {
                return <SelectMultiEntityField
                    {...field}
                    paramName={(field.paramName)}
                    size="tiny"
                    rules={{ required: field.required }}
                    autoFocus={field.autoFocus}
                />;
            },
            valueKey: 'entityRef'
        }
    },
    entityRefType: {
        dataType: 'entityRef',
        empty: emptyEntityRef,
        render: field => (
            <SelectEntityField
                {...field}
                size="tiny"
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    dateType: {
        dataType: 'datetime',
        empty: null,
        render: field => (
            <DateInputField
                {...field}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
            />
        )
    },
    datetimeType: {
        dataType: 'datetime',
        empty: null,
        render: field => (
            <DatetimeInputField
                {...field}
                rules={{ required: field.required }}
                autoFocus={field.autoFocus}
                optionalPresets={field.optionalPresets}
            />
        )
    },
    parameterTermTable: {
        dataType: 'entityRefList',
        empty: [],
        special: true,
        render: () => (
            <span>Select term operation</span>
        )
    },
    datetimeReadonlyType: {
        render: field => (
            <ReadonlyField
                {...field}
                type="date"
            />
        )
    },
    entityRefReadonlyType: {
        render: field => (
            <ReadonlyField
                {...field}
                type="entity"
            />
        )
    },
    textReadonlyType: {
        render: field => (
            <ReadonlyField
                {...field}
            />
        )
    }
};

const institutionFields = [
    {
        paramName: 'abbreviation',
        label: 'Abbreviation',
        type: fieldType.textAreaLimited,
        limit: 80,
        batchEditable: true
    },
    {
        paramName: 'name',
        label: 'Name',
        type: fieldType.textAreaLimited,
        limit: 255,
        autoFocus: true,
        required: true,
        batchEditable: true
    },
    {
        paramName: 'city',
        label: 'City',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'contact',
        label: 'Contact',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'country',
        label: 'Country',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'fax',
        label: 'Fax',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'phone',
        label: 'Phone',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'state',
        label: 'State',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'street',
        label: 'Street',
        type: fieldType.input,
        batchEditable: true
    },
    {
        paramName: 'uri',
        label: 'URI',
        type: fieldType.uriType,
        batchEditable: true
    },
    {
        paramName: 'crossrefFunderId',
        label: 'Crossref funder ID',
        type: fieldType.uriType,
        batchEditable: true
    },
    {
        paramName: 'institutionType',
        label: 'Institution type',
        type: fieldType.selectType,
        entityDef: entityDefs.institutionType,
        batchEditable: true
    },
    {
        paramName: 'ror',
        label: 'ROR',
        type: fieldType.uriType,
        batchEditable: true
    },
    {
        paramName: 'keywords',
        label: 'Keywords',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.keyword,
        batchEditable: true
    },
    {
        paramName: 'journalsReverse',
        label: 'Journals',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.journal
    },
    {
        paramName: 'panGroupsReverse',
        label: 'Groups',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.panGroup
    },
    {
        paramName: 'staffsReverse',
        label: 'Staffs',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.staffs
    },
    {
        paramName: 'basesReverse',
        label: 'Bases',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.basis
    },
    {
        paramName: 'projectsReverse',
        label: 'Projects',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.project
    },
    {
        paramName: 'awardsReverse',
        label: 'Awards',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.award
    },
    {
        paramName: 'datasetsReverse',
        label: 'Datasets',
        type: fieldType.MultiEntityRefType,
        entityDef: entityDefs.dataset
    }
];

const termFields = [
    {
        paramName: 'name',
        label: 'Name',
        type: fieldType.textAreaLimited,
        limit: 255,
        required: true,
        autoFocus: true,
        batchEditable: true
    },
    {
        paramName: 'abbreviation',
        label: 'Abbreviation',
        type: fieldType.inputLimited,
        limit: 32,
        batchEditable: true
    },
    {
        paramName: 'comment',
        label: 'Comment',
        type: fieldType.textArea,
        batchEditable: true
    },
    {
        paramName: 'description',
        label: 'Description',
        type: fieldType.textArea,
        batchEditable: true
    },
    {
        paramName: 'semanticUri',
        label: 'Semantic URI',
        type: fieldType.uriType,
        batchEditable: true
    },
    {
        paramName: 'uri',
        label: 'URI',
        type: fieldType.uriType,
        batchEditable: true
    },
    {
        paramName: 'termCategory',
        label: 'Category',
        type: fieldType.selectType,
        required: true,
        entityDef: entityDefs.termCategory,
        batchEditable: true
    },
    {
        paramName: 'termStatus',
        label: 'Status',
        type: fieldType.selectType,
        required: true,
        entityDef: entityDefs.termStatus,
        batchEditable: true
    },
    {
        paramName: 'terminology',
        label: 'Terminology',
        type: fieldType.entityRefType,
        required: true,
        entityDef: entityDefs.terminology,
        batchEditable: true
    }];

export const entityFields = {
    award: [
        {
            paramName: 'title',
            label: 'Title',
            type: fieldType.textArea,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'awardNumber',
            label: 'Award number',
            type: fieldType.textAreaLimited,
            limit: 80,
            required: true,
            batchEditable: true
        },
        {
            paramName: 'institution',
            label: 'Funder',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            required: true,
            batchEditable: true
        },
        {
            paramName: 'project',
            label: 'Project',
            type: fieldType.entityRefType,
            entityDef: entityDefs.project,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'datasetsReverse',
            label: 'Datasets',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.dataset
        }
    ],
    basis: [
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.inputLimited,
            limit: 40,
            required: true,
            autoFocus: true,
            batchEditable: false
        },
        {
            paramName: 'callsign',
            label: 'Callsign',
            type: fieldType.inputLimited,
            limit: 32,
            batchEditable: true
        },
        {
            paramName: 'imoNumber',
            label: 'IMO number',
            type: fieldType.inputLimited,
            limit: 32,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            batchEditable: true
        },
        {
            paramName: 'campaignsReverse',
            label: 'Campaigns',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.campaign
        }
    ],
    campaign: [
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.inputLimited,
            limit: 32,
            required: true,
            autoFocus: true,
            batchEditable: false
        },
        {
            paramName: 'nameOptional',
            label: 'Name (optional)',
            type: fieldType.textAreaLimited,
            limit: 80,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'datetimeBegin',
            label: 'Date begin',
            type: fieldType.dateType,
            batchEditable: true
        },
        {
            paramName: 'datetimeEnd',
            label: 'Date end',
            type: fieldType.dateType,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'basis',
            label: 'Basis',
            type: fieldType.entityRefType,
            entityDef: entityDefs.basis,
            batchEditable: true
        },
        {
            paramName: 'project',
            label: 'Project',
            type: fieldType.entityRefType,
            entityDef: entityDefs.project,
            batchEditable: true
        },
        {
            paramName: 'reference',
            label: 'Reference',
            type: fieldType.entityRefType,
            entityDef: entityDefs.reference,
            batchEditable: true
        },
        {
            paramName: 'attribute',
            label: 'Attributes',
            type: fieldType.ValuedEntityRefType('campaign/attribute'),
            entityDef: entityDefs.term,
            batchEditable: true
        },
        {
            paramName: 'staffs',
            label: 'Staffs',
            addKey: 'add',
            removeKey: 'remove',
            type: fieldType.MultiEntityRefSortableType,
            batchType: fieldType.SequentialEntityRefType,
            entityDef: entityDefs.staffs,
            batchEditable: true
        },
        {
            paramName: 'eventsReverse',
            label: 'Events',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.event
        }
    ],
    dataset: [
        {
            paramName: 'attribute',
            label: 'Attributes',
            type: fieldType.ValuedEntityRefType('dataset/attribute'),
            entityDef: entityDefs.term, //TODO:is correct?
            batchEditable: true
        },
        {
            paramName: 'ticket',
            label: 'Ticket',
            type: fieldType.ticketType,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'datasetStatus',
            label: 'Status',
            type: fieldType.selectType,
            entityDef: entityDefs.datasetStatus,
            required: true,
            helpHref: 'https://wiki.pangaea.de/wiki/Status',
            batchEditable: true
        },
        {
            paramName: 'collectionType',
            label: 'Collection type',
            type: fieldType.selectType,
            entityDef: entityDefs.collectionType,
            batchEditable: true
        },
        {
            paramName: 'loginOption',
            label: 'Protection',
            type: fieldType.selectType,
            entityDef: entityDefs.loginOption,
            required: true,
            batchEditable: true
        },
        {
            paramName: 'curationLevel',
            label: 'Curation level',
            type: fieldType.selectType,
            entityDef: entityDefs.curationLevel,
            helpHref: 'https://wiki.pangaea.de/wiki/Curation_levels',
            batchEditable: true
        },
        {
            paramName: 'processingLevel',
            label: 'Processing level',
            type: fieldType.selectType,
            entityDef: entityDefs.processingLevel,
            helpHref: 'https://wiki.pangaea.de/wiki/Processing_levels',
            batchEditable: true
        },
        {
            paramName: 'moratoriumUntil',
            label: 'Moratorium until',
            type: fieldType.datetimeType,
            optionalPresets: [
                { label: 'In 3 months', value: dayjs().startOf('d').add(3, 'M') },
                { label: 'In 6 months', value: dayjs().startOf('d').add(6, 'M') },
                { label: 'In 1 year', value: dayjs().startOf('d').add(1, 'y') },
                { label: 'In 2 years', value: dayjs().startOf('d').add(2, 'y') }
            ],
            batchEditable: true
        },
        {
            paramName: 'datetimePublication',
            label: 'Publication date',
            type: fieldType.datetimeReadonlyType
        },
        {
            paramName: 'title',
            label: 'Title',
            type: fieldType.textAreaLimited,
            limit: 255,
            required: true,
            batchEditable: true
        },
        {
            paramName: 'staffs',
            label: 'Author(s)',
            addKey: 'add',        //to avoid f[indexColumn] not found error when switchig between ADD and REMOVE
            removeKey: 'remove',  //to avoid f[indexColumn] not found error when switchig between ADD and REMOVE
            type: fieldType.StaffsWithAffiliationsEntityRef,
            entityDef: entityDefs.staffs,
            batchEditable: true
            // TODO
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            batchEditable: true
        },
        {
            paramName: 'keywords',
            label: 'Keywords',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.keyword,
            batchEditable: true
        },
        {
            paramName: 'abstractText',
            label: 'Abstract',
            type: fieldType.textArea,
            helpHref: 'https://wiki.pangaea.de/wiki/Abstract',
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Dataset comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'commentInternal',
            label: 'Internal comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'project',
            label: 'Projects',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.project,
            batchEditable: true
        },
        {
            paramName: 'award',
            label: 'Awards',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.award,
            batchEditable: true
            // TODO
        },
        {
            paramName: 'filename',
            label: 'Export filename',
            type: fieldType.textAreaLimited,
            limit: 255,
            batchEditable: true
        },
        {
            paramName: 'references',
            label: 'Related references',
            type: fieldType.RelatedReferenceType,
            addKey: 'add',
            removeKey: 'remove',
            entityDef: entityDefs.reference, // TODO: Is entityDef necessary?
            batchEditable: true
            // TODO
        },
        {
            paramName: 'datasets',
            label: 'Related datasets',
            type: fieldType.RelatedDatasetType,
            addKey: 'add',
            removeKey: 'remove',
            entityDef: entityDefs.dataset,
            batchEditable: true
            // TODO
        },
        {
            paramName: 'parentDataset',
            label: 'Parent dataset',
            type: fieldType.entityRefType,
            entityDef: entityDefs.dataset,
            batchEditable: false
            // TODO: needs special batch
        },
        {
            paramName: 'alternativeTitle',
            label: 'Alternative title',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'topotype',
            label: 'Topotype',
            type: fieldType.entityRefType,
            entityDef: entityDefs.topotype,
            helpHref: 'https://wiki.pangaea.de/wiki/Intern:Topologic_type',
            batchEditable: true
        },
        {
            paramName: 'license',
            label: 'License',
            type: fieldType.entityRefType,
            entityDef: entityDefs.license,
            helpHref: 'https://wiki.pangaea.de/wiki/License',
            batchEditable: true
        },
        {
            paramName: 'history',
            label: 'Dataset history'
            // TODO
        },
        {
            paramName: 'groupAccess',
            label: 'Group access'
            // TODO
        },
        {
            paramName: 'userAccess',
            label: 'User access'
            // TODO
        },
        {
            paramName: 'event',
            label: 'Events',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.event,
            batchEditable: true
            // TODO
        },
        {
            paramName: 'datasetSelf',
            label: 'Dataset',
            type: fieldType.entityRefType,
            entityDef: entityDefs.dataset,
            batchEditable: true
        }
    ],
    event: [
        {
            paramName: 'attribute',
            label: 'Attributes',
            type: fieldType.ValuedEntityRefType('event/attribute'),
            entityDef: entityDefs.term, //TODO:is correct?
            batchEditable: true
        },
        {
            paramName: 'label',
            label: 'Label',
            type: fieldType.textAreaLimited,
            limit: 50,
            required: true,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'labelOptional',
            label: 'Label optional',
            type: fieldType.textAreaLimited,
            limit: 50,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'datetime',
            label: 'Time begin',
            type: fieldType.datetimeType,
            batchEditable: true
        },
        {
            paramName: 'datetime2',
            label: 'Time end',
            type: fieldType.datetimeType,
            batchEditable: true
        },
        {
            paramName: 'elevation',
            label: 'Elevation',
            type: fieldType.doubleType,
            batchEditable: true
        },
        {
            paramName: 'elevation2',
            label: 'Elevation 2',
            type: fieldType.doubleType,
            batchEditable: true
        },
        {
            paramName: 'latitude',
            label: 'Latitude',
            type: fieldType.doubleType,
            min: -90,
            max: 90,
            batchEditable: true
        },
        {
            paramName: 'latitude2',
            label: 'Latitude 2',
            type: fieldType.doubleType,
            min: -90,
            max: 90,
            batchEditable: true
        },
        {
            paramName: 'longitude',
            label: 'Longitude',
            type: fieldType.doubleType,
            min: -180,
            max: 180,
            batchEditable: true
        },
        {
            paramName: 'longitude2',
            label: 'Longitude 2',
            type: fieldType.doubleType,
            min: -180,
            max: 180,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'method',
            label: 'Method',
            type: fieldType.entityRefType,
            entityDef: entityDefs.method,
            batchEditable: true
        },
        {
            paramName: 'campaign',
            label: 'Campaign',
            type: fieldType.entityRefType,
            entityDef: entityDefs.campaign,
            batchEditable: true
        },
        {
            paramName: 'project',
            label: 'Project',
            type: fieldType.entityRefType,
            entityDef: entityDefs.project,
            batchEditable: true
        },
        {
            paramName: 'termLocation',
            label: 'Location',
            type: fieldType.entityRefType,
            entityDef: entityDefs.location,
            batchEditable: true
        },
        {
            paramName: 'keywords',
            label: 'Keywords',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.keyword,
            batchEditable: true
        },
        {
            paramName: 'eventSelf',
            label: 'Event',
            type: fieldType.entityRefType,
            entityDef: entityDefs.event,
            batchEditable: true
        },
        {
            paramName: 'datasetsReverse',
            label: 'Datasets Extra',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.dataset
        }
    ],
    funder: [
        ...institutionFields
    ],
    institution: [
        ...institutionFields
    ],
    journal: [
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.textAreaLimited,
            limit: 255,
            required: true,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'discipline',
            label: 'Discipline',
            type: fieldType.textAreaLimited,
            limit: 255,
            batchEditable: true
        },
        {
            paramName: 'issn',
            label: 'ISSN',
            type: fieldType.inputLimited,
            limit: 12,
            batchEditable: true
        },
        {
            paramName: 'periodicity',
            label: 'Periodicity',
            type: fieldType.inputLimited,
            limit: 32,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            batchEditable: true
        },
        {
            paramName: 'keywords',
            label: 'Keywords',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.keyword,
            batchEditable: true
        },
        {
            paramName: 'referencesReverse',
            label: 'References',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.reference
        }
    ],
    method: [
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.textAreaLimited,
            limit: 255,
            required: true,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'description',
            label: 'Description',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'reference',
            label: 'Reference',
            type: fieldType.entityRefType,
            entityDef: entityDefs.reference,
            batchEditable: true
        },
        {
            paramName: 'abbreviation',
            label: 'Abbreviation',
            type: fieldType.inputLimited,
            limit: 40,
            batchEditable: true
        },
        {
            paramName: 'parametersReverse',
            label: 'Parameters',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.parameter
        },
        {
            paramName: 'eventsReverse',
            label: 'Events',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.event
        },
        {
            paramName: 'seriesReverse',
            label: 'Series',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.series
        }
        //Terms
    ],
    panGroup: [
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.textAreaLimited,
            limit: 60,
            required: true,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'panUsersReverse',
            label: 'Users',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.panUser
        }
    ],
    panUser: [
        {
            paramName: 'username',
            label: 'Username',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'fullName',
            label: 'Full name',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'email',
            label: 'Email',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'orcid',
            label: 'ORCID',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'phone',
            label: 'Phone',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'description',
            label: 'Description',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'staffs',
            label: 'Staffs',
            type: fieldType.entityRefType,
            entityDef: entityDefs.staffs
        },
        {
            paramName: 'groups',
            label: 'Groups',
            type: fieldType.entityRefType,
            entityDef: entityDefs.panGroup
        },
        {
            paramName: 'created',
            label: 'Created',
            type: fieldType.datetimeReadonlyType
        },
        {
            paramName: 'lastLogin',
            label: 'Last Login',
            type: fieldType.datetimeReadonlyType
        },
        {
            paramName: 'logins',
            label: '#Logins',
            type: fieldType.textReadonlyType
        }
    ],
    parameter: [
        {
            paramName: 'abbreviation',
            label: 'Abbreviation',
            type: fieldType.inputLimited,
            limit: 40,
            batchEditable: true
        },
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.textAreaLimited,
            limit: 255,
            required: true,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'unit',
            label: 'Unit',
            type: fieldType.inputLimited,
            limit: 80,
            batchEditable: true
        },
        {
            paramName: 'dataType',
            label: 'Data type',
            type: fieldType.selectType,
            entityDef: entityDefs.dataType,
            required: true,
            batchEditable: true
        },
        {
            paramName: 'description',
            label: 'Description',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'format',
            label: 'Default Format',
            type: fieldType.inputLimited,
            limit: 40,
            batchEditable: true
        },
        {
            paramName: 'rangeMin',
            label: 'Range min',
            type: fieldType.doubleType,
            batchEditable: true
        },
        {
            paramName: 'rangeMax',
            label: 'Range max',
            type: fieldType.doubleType,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'method',
            label: 'Default method',
            type: fieldType.entityRefType,
            entityDef: entityDefs.method,
            batchEditable: true
        },
        {
            paramName: 'reference',
            label: 'Reference',
            type: fieldType.entityRefType,
            entityDef: entityDefs.reference,
            batchEditable: true
        },
        {
            paramName: 'ucum',
            label: 'UCUM',
            type: fieldType.inputLimited,
            limit: 80,
            batchEditable: true
        },
        {
            paramName: 'keywords',
            label: 'Keywords',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.keyword,
            batchEditable: true
        },
        {
            paramName: 'terms',
            label: 'Parameter annotation',
            type: fieldType.parameterTermTable,
            batchEditable: true
        },
        {
            paramName: 'parameterSelf',
            label: 'Parameter',
            type: fieldType.entityRefType,
            entityDef: entityDefs.parameter,
            batchEditable: true
        },
        {
            paramName: 'dataSeriesReverse',
            label: 'Data Series',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.series
        }
    ],
    project: [
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.textArea,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'label',
            label: 'Label',
            type: fieldType.inputLimited,
            limit: 30,
            required: true,
            batchEditable: false
        },
        {
            paramName: 'projectType',
            label: 'Project type',
            type: fieldType.selectType,
            entityDef: entityDefs.projectType,
            batchEditable: true
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            batchEditable: true
        },
        {
            paramName: 'staffs',
            label: 'Coordinator',
            type: fieldType.entityRefType,
            entityDef: entityDefs.staffs,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'uriData',
            label: 'URI data',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'campaignsReverse',
            label: 'Campaigns',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.campaign
        },
        {
            paramName: 'eventsReverse',
            label: 'Events',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.event
        },
        {
            paramName: 'awardsReverse',
            label: 'Awards',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.award
        },
        {
            paramName: 'datasetsReverse',
            label: 'Datasets',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.dataset
        }
    ],
    reference: [
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'staffs',
            label: 'Staffs',
            addKey: 'add',
            removeKey: 'remove',
            type: fieldType.MultiEntityRefSortableType,
            batchType: fieldType.SequentialEntityRefType,
            entityDef: entityDefs.staffs,
            batchEditable: true
        },
        {
            paramName: 'title',
            label: 'Title',
            type: fieldType.textArea,
            batchEditable: true,
            required: true
        },
        {
            paramName: 'source',
            label: 'Source',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'journal',
            label: 'Journal',
            type: fieldType.entityRefType,
            entityDef: entityDefs.journal,
            batchEditable: true
        },
        {
            paramName: 'abstractText',
            label: 'Abstract',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'year',
            label: 'Year',
            type: fieldType.longType,
            min: 0,
            max: 5000,
            batchEditable: true
        },
        {
            paramName: 'volume',
            label: 'Volume',
            type: fieldType.inputLimited,
            limit: 20,
            batchEditable: true
        },
        {
            paramName: 'pages',
            label: 'Pages',
            type: fieldType.inputLimited,
            limit: 20,
            batchEditable: true
        },
        {
            paramName: 'serialNumber',
            label: 'Serial number',
            type: fieldType.inputLimited,
            limit: 64,
            batchEditable: true
        },
        {
            paramName: 'referenceStatus',
            label: 'Reference status',
            type: fieldType.selectType,
            entityDef: entityDefs.referenceStatus,
            batchEditable: true
        },
        {
            paramName: 'referenceType',
            label: 'Reference type',
            type: fieldType.selectType,
            entityDef: entityDefs.referenceType,
            batchEditable: true
        },
        {
            paramName: 'keywords',
            label: 'Keywords',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.keyword,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'parametersReverse',
            label: 'Parameters',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.parameter
        },
        {
            paramName: 'methodsReverse',
            label: 'Methods',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.method
        },
        {
            paramName: 'campaignsReverse',
            label: 'Campaigns',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.campaign
        },
        {
            paramName: 'datasetsReverse',
            label: 'Datasets',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.dataset
        }
        /* ,
        {
            paramName: 'attachments',
            label: 'Attachments',
            type: fieldType.entityRefType,
            entityDef: entityDefs.attachment,
            batchEditable: false
        }
        */
    ],
    series: [
        {
            paramName: 'parameter',
            label: 'Parameter',
            type: fieldType.entityRefType,
            entityDef: entityDefs.parameter,
            batchEditable: true
        },
        {
            paramName: 'dataType',
            label: 'Data type',
            type: fieldType.entityRefReadonlyType,
            entityDef: entityDefs.dataType
        },
        {
            paramName: 'format',
            label: 'Format',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'nDataPoints',
            label: 'Data points',
            type: fieldType.textReadonlyType
        },
        {
            paramName: 'staffs',
            label: 'PI',
            type: fieldType.entityRefType,
            entityDef: entityDefs.staffs,
            batchEditable: true
        },
        {
            paramName: 'method',
            label: 'Method',
            type: fieldType.entityRefType,
            entityDef: entityDefs.method,
            batchEditable: true
        },
        {
            paramName: 'dataset',
            label: 'Dataset',
            type: fieldType.entityRefType,
            entityDef: entityDefs.dataset,
            batchEditable: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea,
            batchEditable: true
        },
        {
            paramName: 'configFormat',
            label: 'Dataset config format',
            type: fieldType.dataSeriesConfigFormat,
            batchEditable: true
        }
    ],
    staffs: [
        {
            paramName: 'nameLast',
            label: 'Last name',
            type: fieldType.inputLimited,
            limit: 80,
            required: true,
            autoFocus: true,
            batchEditable: true
        },
        {
            paramName: 'nameFirst',
            label: 'First name',
            type: fieldType.inputLimited,
            limit: 80,
            batchEditable: true
        },
        {
            paramName: 'email',
            label: 'Email',
            type: fieldType.inputLimited,
            limit: 80,
            batchEditable: true
        },
        {
            paramName: 'orcid',
            label: 'ORCID',
            type: fieldType.inputLimited,
            limit: 19,
            batchEditable: false
        },
        {
            paramName: 'phone',
            label: 'Phone',
            type: fieldType.inputLimited,
            limit: 20,
            batchEditable: true
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType,
            batchEditable: true
        },
        {
            paramName: 'institution',
            label: 'Institution',
            type: fieldType.entityRefType,
            entityDef: entityDefs.institution,
            batchEditable: true
        },
        {
            paramName: 'keywords',
            label: 'Keywords',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.keyword,
            batchEditable: true
        },
        {
            paramName: 'users',
            label: 'Users rev',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.panUser,
            batchEditable: false
        },
        {
            paramName: 'dataSeriesReverse',
            label: 'Dataseries rev',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.series,
            batchEditable: false
        },
        {
            paramName: 'projectsReverse',
            label: 'Projects rev',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.project,
            batchEditable: false
        },
        {
            paramName: 'referencesReverse',
            label: 'References rev',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.reference,
            batchEditable: false
        },
        {
            paramName: 'datasetsReverse',
            label: 'Datasets rev',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.dataset,
            batchEditable: false
        },
        {
            paramName: 'campaignsReverse',
            label: 'Campaigns rev',
            type: fieldType.MultiEntityRefType,
            entityDef: entityDefs.campaign,
            batchEditable: false
        },
    ],
    term: [
        ...termFields
    ],
    keyword: [
        ...termFields
    ],
    location: [
        ...termFields
    ],
    terminology: [
        {
            paramName: 'abbreviation',
            label: 'Abbreviation',
            type: fieldType.inputLimited,
            limit: 20,
            required: true,
            autoFocus: true
        },
        {
            paramName: 'name',
            label: 'Name',
            type: fieldType.inputLimited,
            limit: 255,
            required: true
        },
        {
            paramName: 'comment',
            label: 'Comment',
            type: fieldType.textArea
        },
        {
            paramName: 'creator',
            label: 'Creator',
            type: fieldType.inputLimited,
            limit: 255
        },
        {
            paramName: 'description',
            label: 'Description',
            type: fieldType.textArea
        },
        {
            paramName: 'uri',
            label: 'URI',
            type: fieldType.uriType
        }
    ]
};

export const renderField = (field, paramNameOverride) => {
    const preparedField = {
        ...field
    };
    if (paramNameOverride !== undefined) {
        preparedField.paramName = paramNameOverride;
    }
    return (
        <EntityFormItem
            key={preparedField.paramName}
            label={preparedField.label}
            paramName={preparedField.paramName}
            entityDef={preparedField.entityDef}
            required={preparedField.required}
            helpHref={preparedField.helpHref}
        >
            {field.type.render(preparedField)}
        </EntityFormItem>
    );
};

export const renderFieldPlain = (field, paramNameOverride) => {
    const preparedField = {
        ...field,
        paramName: paramNameOverride || field.paramName,
        type: field.batchType || field.type
    };

    //if custom batch render exists, different from entityDetails render,
    // e.g. CapmaignDetails staffs
    return <>{preparedField.type.render(preparedField)}</>;
};
export const renderFieldPlainRemove = (field, paramNameOverride) => {
    const preparedField = {
        ...field,
        paramName: paramNameOverride || field.paramName,
        type: field.batchType || field.type
    };
    if (preparedField.type.remove?.render) {
        return <>{preparedField.type.remove.render(preparedField)}</>;
    } else {
        return <>{preparedField.type.render(preparedField)}</>;
    }
};

