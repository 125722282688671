import React from 'react';
import PropTypes from 'prop-types';
import EntityTag from '../bits/EntityTag';
import MyError from '../bits/MyError';


const style = {
    paddingTop: '0px',
    paddingLeft: '10px'
};

const ErrorDisplay = (props) => {
    const { message } = props;
    return (
        <div className="ant-form-item-explain-error" style={style}>
            {message}
        </div>
    );
};

ErrorDisplay.propTypes = {
    message: PropTypes.string.isRequired
};

export const ValidationErrorsDisplay = ({ errors, entityDef }) => {
    if (errors === undefined) {
        return undefined;
    }
    return (
        <div>
            <h3><MyError /> Validation Errors</h3>
            {errors.map(entityRefError => (
                <div key={entityRefError.entityRef.id}>
                    <EntityTag entityDef={entityDef} entityRef={entityRefError.entityRef} />
                    {Object.entries(entityRefError.errors).map(([key, values]) => (
                        <span key={key + entityRefError.entityRef.id}>
                            <b>{key}</b>: {values.map(value => <span key={value}>{value}</span>)}.&nbsp;
                        </span>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ErrorDisplay;
