import React, { useContext } from 'react';
import { FormExtContext } from './FormExt';
import ErrorDisplay from '../error/ErrorDisplay';
import PropTypes from 'prop-types';


const FormFieldError = (props) => {
    const { paramName } = props;
    const { errors } = useContext(FormExtContext);

    if (errors === undefined || errors[paramName] === undefined) {
        return undefined;
    }

    switch (errors[paramName].type) {
        case 'server':
            return <ErrorDisplay message={errors[paramName].message} />;
        case 'required':
            return <ErrorDisplay message="Required field" />;
        default:
            return <ErrorDisplay message="Error" />;
    }
};

FormFieldError.propTypes = {
    paramName: PropTypes.string.isRequired
};

export default FormFieldError;
