import TableField2, {findUnsortedIndex} from "./TableField2";
import {renderEditableTextArea, renderEntityRef} from "../../pages/test/TestPage2";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import HelpLink from "../bits/HelpLink";
import SelectField from "../input/SelectField";
import {smallTagStyle} from "./inputComplexLib";
import React, {useContext} from "react";
import useEnumType from "../../hooks/useEnumType";
import {FormExtContext} from "../forms/FormExt";
import EntityTagList from "../bits/EntityTagList";

const RelatedReferenceTableField = ({paramName}) => {
    const relationTypesForReferences = useEnumType('relationTypesForReferences');
    const {watch, getValues} = useContext(FormExtContext);
    const addedIds = watch(paramName).map(entry => entry.reference.id)
    return (
        <TableField2
            filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
            paramName={paramName}
            entityDef={entityDefs.reference}
            columns={[
                {
                    title: 'ID',
                    dataIndex: 'reference.id',
                    width: 60,
                    align: 'right',
                    render: (_, row) => row.reference.id,
                    sorter: (a, b) => a.reference.id - b.reference.id,
                    defaultSortOrder: 'ascend'
                },
                {
                    title: 'Year',
                    dataIndex: 'year',
                    width: 60,
                    align: 'right',
                    sorter: (a, b) => a.year - b.year,
                    defaultSortOrder: 'ascend'
                },
                {
                    title: 'Author(s)',
                    dataIndex: 'staffs',
                    width: 270,
                    render: staffs => <EntityTagList entityDef={entityDefs.staffs} list={staffs}/>,
                    sorter: (a, b) => {
                        console.log("A:", a.staffs, "   B:", b.staffs);
                        if (a.staffs.length === 0 && b.staffs.length === 0) return 0;
                        if (a.staffs.length === 0) return 1;
                        if (b.staffs.length === 0) return -1;
                        return a.staffs[0].commonName > b.staffs[0].commonName ? 1 : -1;
                    },
                    defaultSortOrder: 'ascend'
                },
                {
                    title: 'Reference',
                    dataIndex: 'reference',
                    width: 270,
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.reference),
                    sorter: (a, b) => a.reference.commonName > b.reference.commonName ? 1 : -1
                },
                {
                    title: <span>Relation type&nbsp;<HelpLink
                        href='https://wiki.pangaea.de/wiki/Intern:Reference_relation'/></span>,
                    dataIndex: 'relationType',
                    width: 210,
                    render: (_, row) => {
                        const origIndex = findUnsortedIndex(row.key, getValues, paramName, "reference");
                        return <SelectField
                            //paramName=references in DatasetDetails, or references.value in BatchEditContent
                            paramName={paramName + "." + origIndex + '.relationType'}
                            values={relationTypesForReferences}
                            tagStyle={smallTagStyle}
                        />;
                    },
                    multiEdit: 'type',
                    multiEditOptions: relationTypesForReferences,
                    sorter: (a, b) => a.relationType.name > b.relationType.name ? 1 : -1
                },
                {
                    title: 'Comment',
                    dataIndex: 'comment',
                    width: 300,
                    render: renderEditableTextArea(paramName, 'comment', getValues, 'reference'),
                    multiEdit: 'text',
                    sorter: (a, b) => a.comment > b.comment ? 1 : -1
                }
            ]}
            newRowFn={entityRef => ({
                reference: entityRef,
                relationType: {id: 12, commonName: "Related to"},
                comment: ''
            })}
        />
    )
}

export default RelatedReferenceTableField;