import entityDefs from "../../pages/editorial/entities/entityDefs";
import React, {useContext, useEffect, useState} from "react";
import {Button, Row, Space, Table, Upload} from "antd";
import {FormExtContext} from "../forms/FormExt";
import {useFieldArray} from "react-hook-form";
import {DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import {apiBaseUrl, refAttachmentBaseUrl} from "../../lib/config";
import {handleCatchErrorResponse, uploadFile} from "../../lib/importFile";
import useWS2Axios from "../../hooks/useWS2Axios";
import {
    uploadInfoCompletedUploads,
    uploadInfoOnDraggerChange,
    uploadInfoOnDraggerCustomRequest,
    uploadInfoReady
} from "./UploadInfo";
import FileSize from "../bits/FileSize";


const RelatedAttachmentTableField = ({paramName, referenceId}) => {
    const {control} = useContext(FormExtContext);
    const {register} = useContext(FormExtContext);
    register(paramName);
    const {fields, remove, append} = useFieldArray({
        control,
        name: paramName
    });

    const {ws2Axios} = useWS2Axios();

    const [isImporting, setIsImporting] = useState(false);

    const [draggerList, setDraggerList] = useState([]);

    // Keep track of upload status of attachments.
    const [attachmentUploadState, setAttachmentUploadState] = useState(new Map());

    const deleteStagedFile = (attachmentId, onSuccess, onError, setResult) => {
        const timeoutMilliseconds = import.meta.env.VITE_UPLOAD_TIMEOUT_MS;
        const apiPath = entityDefs.attachment.apiGet + "/unstage";

        console.log('Deleting attachment with id', attachmentId);
        return ws2Axios.delete(apiBaseUrl + apiPath + '/' + attachmentId, {timeout: timeoutMilliseconds})
            .then(response => {
                if (setResult !== undefined) {
                    setResult({...response.data, attachmentId: attachmentId});
                }

                if (response?.data?.success) {
                    if (onSuccess !== undefined) {
                        onSuccess(response);
                    }
                } else {
                    if (onError !== undefined) {
                        onError(response);
                    }
                }
            })
            .catch(errorResponse => {
                handleCatchErrorResponse(errorResponse, onError, setResult, undefined);
            });
    };


    const draggerEntryWithCustomError = (oldDraggerEntry, uploadState) => {
        const filename = oldDraggerEntry.name;
        if (!uploadState.has(filename)) {
            return oldDraggerEntry;
        }

        const info = uploadState.get(filename);
        if (info?.success) {
            return oldDraggerEntry;
        }

        const errors = info?.errors;
        const error = errors === undefined ? 'Upload error' : errors[0];

        return {
            ...oldDraggerEntry,
            status: 'error',
            response: error // Custom error message to show.
        }
    };


    const removeSuccessfulFilesFromUploadstate = (uploadState) => {
        for (const filename of uploadState.keys()) {
            const attachmentInfo = uploadState.get(filename);
            if (attachmentInfo.state === 'upload_succeeded') {
                uploadState.delete(filename);
            }
        }

        setAttachmentUploadState(uploadState); // TODO: keine gute Idee, mit immer.js wird das besser, denke ich
    };


    useEffect(() => {
        const updatedDraggerFileList = (draggerList, filenames, uploadState) => {
            const newDraggerList = [];
            for (const draggerEntry of draggerList) {
                const filename = draggerEntry.name;
                if (!filenames.includes(filename)) {
                    const newDraggerEntry = draggerEntryWithCustomError(draggerEntry, uploadState);
                    newDraggerList.push(newDraggerEntry);
                }
            }

            return newDraggerList;
        };

        if (isImporting) {
            return;
        }

        const filenamesOfCompletedUploads = uploadInfoCompletedUploads(attachmentUploadState);
        const currentUploadState = new Map(attachmentUploadState);
        setDraggerList(oldDraggerList => updatedDraggerFileList(oldDraggerList, filenamesOfCompletedUploads, currentUploadState));
        removeSuccessfulFilesFromUploadstate(attachmentUploadState);
    }, [isImporting, attachmentUploadState]);


    const updateAttachmentFields = (map) => {
        const filenames = map.keys();
        const fileNamesInFields = fields.map(field => field.filename);

        for (const filename of filenames) {
            if (!fileNamesInFields.includes(filename)) {
                const info = map.get(filename);
                if (info === undefined) {
                    console.log('Cannot retrieve info for', filename);

                    continue;
                }

                if (!info.success) {
                    continue;
                }

                const newEntry = {
                    filename: info.filename,
                    idAttachment: info.attachmentId,
                    size: info.size,
                    ephemeral: true
                }

                append(newEntry);
            } else {
                console.log('File', filename, 'not found in', Array.from(fileNamesInFields));
            }
        }
    };


    const resetAttachmentList = (draggerInfo) => {
        uploadInfoOnDraggerChange(attachmentUploadState, draggerInfo);
    };


    const maxFileSizeMiB = 100;
    const maxFileSize = 1024 * 1024 * maxFileSizeMiB;

    const startFileUpload = (myAxios, onSuccess, onError, options, setResult, apiPath) => {
        const file = options.file;

        console.log('Starting upload of', file.name);

        if (file.size > maxFileSize) {
            const errorMsg = 'File size exceeds ' + maxFileSizeMiB + ' MiB.';
            options.onError({event: errorMsg});

            const result = {
                success: false,
                originalFilename: file.name,
                originalFileSizeInBytes: file.size,
                stagingFileName: '',
                errors: [errorMsg],
                attachmentId: -1,
                filename: file.name
            }

            return new Promise((resolve, reject) => {
                onError(result);
                setResult(result);

                reject(errorMsg);
            });
        }

        return uploadFile(
            ws2Axios,
            undefined,
            undefined,
            options.file,
            result => {
                if (result.success) {
                    options.onSuccess(result);
                    if (onSuccess) {
                        onSuccess(result);
                    }
                } else {
                    options.onError(result);
                    if (onError) {
                        onError(result);
                    }
                }

                if (setResult) {
                    setResult(result);
                }
            },
            apiPath
        )
    };


    const updateAttachmentInfo = (fileName, updateFunc, map) => {
        const attachmentInfo = map.has(fileName) ? map.get(fileName) : {};
        const newAttachmentInfo = updateFunc(attachmentInfo);
        map.set(fileName, newAttachmentInfo);
    };


    const storeUploadSuccess = (fileName, result, map) => {
        updateAttachmentInfo(
            fileName,
            (info) => {
                return {
                    ...info,
                    success: true,
                    complete: true,
                    state: 'upload_succeeded',
                    attachmentId: result.attachmentId,
                    filename: result.filename,
                    originalFileSizeInBytes: result.originalFileSizeInBytes,
                    size: result.originalFileSizeInBytes,
                    sizeKB: Number(result.originalFileSizeInBytes / 1024).toFixed(2)
                }
            },
            map);
    };


    const storeUploadErrors = (fileName, result, map) => {
        updateAttachmentInfo(
            fileName,
            (info) => {
                return {
                    ...info,
                    success: false,
                    complete: true,
                    state: 'upload_failed',
                    filename: fileName,
                    errors: result.errors
                }
            },
            map);
    };


    const storeUploadResult = (fileName, result, map) => {
        updateAttachmentInfo(
            fileName,
            (info) => {
                return {
                    ...info,
                    result: result
                }
            },
            map);
    };


    const mergeObjects = (o1, o2) => {
        const o = o1 === undefined ? {} : o1;

        return o2 === undefined ? o : {...o, ...o2};
    }


    const startAllFileUploads = (myAxios, apiPath) => {
        const runningUploads = [];

        // Start uploads and collect a list of pending promises.
        const tempMap = new Map();
        for (const fileName of attachmentUploadState.keys()) {
            const pendingAttachmentInfo = attachmentUploadState.get(fileName);
            const options = pendingAttachmentInfo.options;

            // Already run: no need to upload again.
            if (pendingAttachmentInfo.complete) {
                // Nothing to do.
            } else {
                const uploadPromise =
                    startFileUpload(
                        myAxios,
                        // onSuccess
                        (result) => {
                            console.log('Success:', result);
                            storeUploadSuccess(fileName, result, tempMap);
                        },
                        // onError
                        (result) => {
                            console.log('Error:', result);
                            storeUploadErrors(fileName, result, tempMap);
                        },
                        options,
                        // setResult
                        (result) => {
                            storeUploadResult(fileName, result, tempMap);
                        },
                        apiPath);
                runningUploads.push(uploadPromise);
                attachmentUploadState.set(fileName, {
                    ...pendingAttachmentInfo,
                    complete: false,
                    promise: uploadPromise
                });
            }
        }

        setIsImporting(true);

        const uploads = Promise.allSettled(runningUploads);
        uploads.then((results) => {
            console.log('All file uploads are complete.');
            for (const fileName of attachmentUploadState.keys()) {
                const pendingAttachmentInfo = attachmentUploadState.get(fileName);
                const tempAttachmentInfo = tempMap.get(fileName);
                attachmentUploadState.set(fileName, {
                    ...mergeObjects(pendingAttachmentInfo, tempAttachmentInfo),
                    complete: true
                });
            }

            updateAttachmentFields(tempMap);

            setAttachmentUploadState(attachmentUploadState);

            setIsImporting(false);
        });
    };


    const enqueueFileForUpload = (myAxios, options, apiPath) => {
        uploadInfoOnDraggerCustomRequest(attachmentUploadState, options);

        if (uploadInfoReady(attachmentUploadState)) {
            startAllFileUploads(myAxios, apiPath);
        }
    };


    const columns = [
        {
            title: 'Id',
            dataIndex: 'idAttachment',
            key: 'ID'
        },
        {
            title: 'File name',
            dataIndex: 'filename',
            key: 'filename',
            render: (filename, record) => {
                const isPersistent = record.ephemeral !== true;
                const filenameRawUrlEncoded = encodeURIComponent(filename);
                const url = referenceId !== undefined && isPersistent ?
                    refAttachmentBaseUrl + referenceId + "/attachments/" + filenameRawUrlEncoded :
                    undefined;

                return url !== undefined ?
                    (<a
                        href={url}
                        download={filename}
                        target="_blank"
                        rel="noreferrer">
                        {filename}
                    </a>) :
                    filename;
            }
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            render: size => <FileSize sizeInBytes={size}/>
        },
        {
            title: '',
            dataIndex: 'idAttachment',
            key: 'delete',
            render: (idAttachment, _, index) => {
                return (
                    <Button type="link" onClick={() => {
                        deleteStagedFile(idAttachment, undefined, undefined, undefined);
                        remove(index);
                    }}>
                        <DeleteOutlined/>
                    </Button>
                );
            }
        }
    ];


    return (
        <div>
            {fields.length > 0 &&
                <Table
                    dataSource={fields}
                    columns={columns}
                    className='mini-table'
                    size='small'
                    rowKey='idAttachment'
                    pagination={false}
                />
            }
            <Row justify='space-between' style={{marginTop: '8px'}}>

                <Upload.Dragger
                    height={64}
                    name="files"
                    multiple={true}
                    disabled={isImporting}
                    fileList={draggerList}
                    itemRender={undefined}
                    onChange={info => {
                        resetAttachmentList(info);
                        setDraggerList(info.fileList);
                    }}
                    customRequest={(options) => {
                        const apiPath = entityDefs.attachment.apiGet + "/stage";
                        enqueueFileForUpload(ws2Axios, options, apiPath);
                    }}
                >

                    <Space
                        style={{
                            paddingLeft: '32px',
                            paddingRight: '32px'
                        }}
                    >
                        <InboxOutlined style={{fontSize: '32px'}}/>
                        <span>Click or drop file(s) here</span>
                    </Space>
                </Upload.Dragger>
                {fields.length > 0 &&
                    <Button
                        onClick={() => {
                            for (const record of fields) {
                                deleteStagedFile(record.idAttachment, undefined, undefined, undefined);
                            }
                            remove();
                        }}
                    >
                        Delete all
                    </Button>
                }
            </Row>
        </div>
    )
}

export default RelatedAttachmentTableField;
