import { useContext } from 'react';
import { FormExtContext } from '../../../tk/forms/FormExt';
import dayjs from 'dayjs';
import EntityTag from '../../../tk/bits/EntityTag';
import OrcidLink from '../../../tk/bits/OrcidLink';


const ReadonlyField = (props) => {
    const { paramName, type, entityDef } = props;
    const { getValues } = useContext(FormExtContext);
    const value = getValues(paramName);
    if (!value) {
        return '---';
    } else if (type === 'datetime') {
        return <span>{dayjs.utc(value).format('YYYY-MM-DD HH:mm')}</span>;
    } else if (type === 'date') {
        return <span>{dayjs.utc(value).format('YYYY-MM-DD')}</span>;
    } else if (type === 'entity') {
        return <EntityTag entityRef={value} entityDef={entityDef} />;
    } else if (type === 'orcid') {
        return <OrcidLink orcid={value} />;
    } else {
        return <span>{value}</span>;
    }
};

export default ReadonlyField;
