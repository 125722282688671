import React, { useCallback, useContext, useEffect, useState } from 'react';
import Graphin, {GraphinContext, Behaviors, Components } from '@antv/graphin';
import entityDefs from '../entities/entityDefs';
import ResponsiveArea, { ResponsiveContext } from '../../../tk/cards/ResponsiveArea';
import { getEntity } from '../../../lib/entityRequests';
import useWS2Axios from '../../../hooks/useWS2Axios';
import EntityDetailLoader from '../entities/EntityDetailLoader';
import { useImmer } from 'use-immer';
import FlexLayoutContext from '../../../contexts/FlexLayoutContext';
import Color from 'color';
import { Radio, Space } from 'antd';
import { Eye, GitBranch, GitMerge, MousePointer, Move } from 'react-feather';
import EntityTagList from '../../../tk/bits/EntityTagList';
import { useDrop } from 'react-dnd';
import { dropEntities } from '../../../lib/entityUtils';
import useEntitySearch from '../../../hooks/useEntitySearch';
import { DndHighlight } from '../../../lib/dropIndicatorStyle';
import { current } from 'immer';
import BetaTag from '../../../tk/bits/BetaTag';
import HelpLink from '../../../tk/bits/HelpLink';

const {
    ActivateRelations,
    ZoomCanvas,
    ClickSelect,
    DragNode,
    LassoSelect
} = Behaviors;

const {
    MiniMap,
    FishEye,
    Legend
} = Components;

const MAX_EXPAND_NODES = 20;

// noinspection JSUnusedGlobalSymbols
const layout = {
    type: 'graphin-force',
    preset: {
        type: 'concentric'
    },
    animation: false,
    defSpringLen: () => 200
};

const id = (entityRef) => {
    return entityRef.id.toString();
};

const massage = (name) => {
    const maxLineLength = name.length > 15 ? 15 : 9;
    const parts = name.split(' ');
    let lineLength = 0;
    let result = '';

    let maxLength = 0;
    let lines = 1;

    for (let i = 0; i < parts.length; i++) {
        if (lineLength === 0) {
            result += parts[i];
            lineLength = parts[i].length;
        } else if (lineLength + parts[i].length < maxLineLength) {
            result += ' ' + parts[i];
            lineLength += 1 + parts[i].length;
        } else {
            result += '\n' + parts[i];
            lineLength = parts[i].length;
            lines++;
        }
        if (lineLength > maxLength) {
            maxLength = lineLength;
        }
    }
    return { result, maxLength, lines };
};

const makeCollapsedTermNode = (entityRef) => {
    const entityDef = entityDefs.term;
    const m = massage(entityRef.commonName);
    const fontSize = Math.max(1, 16 - m.maxLength) * 0.7 + 12;
    const offset = (fontSize * 0.6) * m.lines - 3;

    return {
        comboId: undefined,
        id: id(entityRef),
        label: entityRef.commonName,
        type: 'graphin-circle',
        style: {
            keyshape: {
                size: 100,
                fill: '#404040',
                stroke: '#404040',
                opacity: 0.1,
                fillOpacity: 0.1
            },
            label: {
                value: m.result,
                position: 'center',
                offset: [0, offset],
                fontSize: fontSize,
                fill: '#000000'
            },
            halo: {
                stroke: 'black',
                strokeWidth: 1
            }
        },
        extra: {
            isExtended: false,
            entityType: entityDef.entityType,
            legendType: 'collapsed'
        },
        status: {
            selected: false
        }
    };
};

const componentColor2 = component => {
    const hash = component * 1000;
    const color = Color.rgb(16, 142, 233); //.darken(0.3);
    return color.rotate(hash);
};

const makeExtendedTermNode = (entity, selected) => {
    const node = makeCollapsedTermNode(entity.me);
    const fillColor = componentColor2(entity.terminology.id).lighten(0.3).toString();
    const strokeColor = componentColor2(entity.termCategory.id).lighten(0.3).toString();

    node.style.keyshape.fill = fillColor; //entityDef.bgColor;
    node.style.keyshape.stroke = strokeColor; //entityDef.color;
    node.style.keyshape.opacity = 1;
    node.style.keyshape.fillOpacity = 0.5;
    node.extra.isExtended = true;
    node.extra.legendType = entity.terminology.commonName;
    node.status.selected = selected;

    const numNodes = entity.termsRelated.length + entity.termsRelatedReverse.length;
    if (numNodes >= MAX_EXPAND_NODES) {
        const nodesStr = numNodes.toString();
        node.style.badges = [
            {
                position: 'RT',
                offset: [-20, 0],
                type: 'text',
                fontSize: 16,
                value: nodesStr,
                size: [nodesStr.length * 16, 24],
                fill: 'red',
                color: '#fff'
            }
        ];
    }

    return node;
};

// noinspection JSUnusedLocalSymbols
const makeEdge = (node1, node2, relationType) => {
    const strokeColor = componentColor2(relationType.id).lighten(0.3).toString();
    return {
        source: id(node1),
        target: id(node2),
        style: {
            keyshape: {
                lineWidth: 3,
                stroke: strokeColor
            }
            /*
            label: {
                value: relationType.name
            }
             */
        }
    };
};

const existsNode = (entityRef, nodes) => nodes.findIndex(node => {
    if (!node || !node.id || !entityRef || !entityRef.id) {
        console.log('EXISTS NODE ERR', node?.id, entityRef);
        return -1;
    }
    return node.id === id(entityRef);
}) !== -1;

const existsEdge = (entityRef, termRelation, edges) => {
    return edges.findIndex(edge => edge.source === id(entityRef) && edge.target === id(termRelation.termRelated)) !== -1;
};

const existsEdgeRev = (entityRef, termRelation, edges) => {
    return edges.findIndex(edge => edge.target === id(entityRef) && edge.source === id(termRelation.termRelated)) !== -1;
};

const appendTerm = (entity, nodes, edges, maxNodes) => {
    const newNodes = [];
    const newEdges = [];
    entity.termsRelated.forEach(termRelation => {
        if (maxNodes && newNodes.length >= maxNodes) return;
        if (!existsNode(termRelation.termRelated, [...nodes, ...newNodes])) {
            newNodes.push(makeCollapsedTermNode(termRelation.termRelated));
        }
        if (!existsEdge(entity.me, termRelation, [...edges, ...newEdges])) {
            newEdges.push(makeEdge(entity.me, termRelation.termRelated, termRelation.relationType));
        }
    });
    entity.termsRelatedReverse.forEach(termRelation => {
        if (maxNodes && newNodes.length >= maxNodes) return;
        if (!existsNode(termRelation.term, [...nodes, ...newNodes])) {
            newNodes.push(makeCollapsedTermNode(termRelation.term));
        }
        if (!existsEdgeRev(entity.me, termRelation, [...edges, ...newEdges])) {
            newEdges.push(makeEdge(termRelation.term, entity.me, termRelation.relationType));
        }
    });
    return [newNodes, newEdges];
};

const ResponsiveBehaviour = () => {
    const { dimensions } = useContext(ResponsiveContext);
    const { graph } = useContext(GraphinContext);

    useEffect(() => {
        graph.changeSize(dimensions.width - 5, dimensions.height - 5);
    }, [dimensions, graph]);
    return null;
};

const ClickBehavior = ({ onClick }) => {
    const { graph, apis } = useContext(GraphinContext);

    useEffect(() => {
        const handleClick = (evt) => {
            const node = evt.item;
            const model = node.getModel();
            onClick(model.id, graph, apis);
        };
        graph.on('node:click', handleClick);
        return () => {
            graph.off('node:click', handleClick);
        };
    }, [apis, graph, onClick]);
    return null;
};

const DoubleClickOpen = () => {
    const { graph, apis } = useContext(GraphinContext);
    const { addEntityDetailTab } = useContext(FlexLayoutContext);

    useEffect(() => {
        const handleClick = (evt) => {
            const node = evt.item;
            const model = node.getModel();
            addEntityDetailTab(
                { id: Number(model.id) },
                entityDefs[model.extra.entityType]
            );
        };
        graph.on('node:dblclick', handleClick);
        return () => {
            graph.off('node:dblclick', handleClick);
        };
    }, [addEntityDetailTab, apis, graph]);
    return null;
};

const toolbarButtonStyle = {
    height: '32px',
    //width: '32px',
    paddingTop: '4px'
};

const iconSize = 18;

const MyToolbar = ({ active, setActive, selectedNodes }) => {
    return (
        <div
            style={{
                position: 'absolute',
                left: '8px',
                top: '16px',
                zIndex: 10
            }}
        >
            <Space>
                <Radio.Group
                    value={active}
                    onChange={(e) => {
                        setActive(e.target.value);
                    }}
                    buttonStyle="solid"
                    style={{
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px'
                    }}
                >
                    <Radio.Button value="move" style={toolbarButtonStyle}><Move size={iconSize} /></Radio.Button>
                    <Radio.Button value="select" style={toolbarButtonStyle}><MousePointer
                        size={iconSize} /></Radio.Button>
                    <Radio.Button value="expand" style={toolbarButtonStyle}><GitBranch
                        size={iconSize} /></Radio.Button>
                    <Radio.Button value="collapse" style={toolbarButtonStyle}><GitMerge
                        size={iconSize} /></Radio.Button>
                    <Radio.Button value="fisheye" style={toolbarButtonStyle}><Eye size={iconSize} /></Radio.Button>
                </Radio.Group>
                <HelpLink category="NetworkGraph" title="Terms" />
                <BetaTag />
            </Space>
            <EntityTagList
                entityDef={entityDefs.term}
                list={selectedNodes.map(s => {
                    return { id: s.id, name: s.label };
                })}
                max={3}
            />
        </div>
    );
};

const MyDragCanvas = () => {
    const { graph } = useContext(GraphinContext);
    useEffect(() => {
        graph.removeBehaviors('drag-canvas', 'default');
        graph.addBehaviors('drag-canvas', 'default');
        return () => {
            try {
                graph.removeBehaviors('drag-canvas', 'default');
            } catch (e) {
                // When closing the tab, we end up here.
                console.error(e);
            }
        };
    }, [graph]);
    return null;
};

const TrackSelection = ({ setSelectedNodes }) => {
    const { graph } = useContext(GraphinContext);
    useEffect(() => {
        const handleSelect = () => {
            const s = graph
                .findAllByState('node', 'selected')
                .map(node => node._cfg.model);
            setSelectedNodes(s);
        };
        graph.on('nodeselectchange', handleSelect);
        return () => {
            graph.off('nodeselectchange', handleSelect);
        };
    });
};

const DropBehaviour = ({ setDrop, setDropStatus, onChange }) => {
    const { graph } = useContext(GraphinContext);
    // {canDrop, isOver}
    const [, setIsLoading] = useState(false);
    const { ws2Axios } = useWS2Axios();
    const { doRsqlSearch } = useEntitySearch(entityDefs.term.entityType, undefined, 50, ws2Axios);

    const dropMe = useCallback(() => {
            return dropEntities(
                entityDefs.term,
                () => undefined,
                async entityRefs => {
                    const graphNodes = graph.getNodes().map(graphNode => graphNode._cfg.model);
                    const graphEdges = graph.getEdges().map(graphEdge => graphEdge._cfg.model);

                    const newEntities = [];
                    for (let i = 0; i < entityRefs.length; i++) {
                        const addEntity = await getEntity(ws2Axios, entityDefs.term, id(entityRefs[i]));
                        newEntities.push(addEntity);
                    }

                    const newNodes = [];
                    newEntities.forEach(entity => {
                        const exist = graph.findById(id(entity.me));
                        if (!exist || exist._cfg.model.extra.isExtended === false) {
                            newNodes.push(makeExtendedTermNode(entity, true));
                        } else {
                            const extendNode = exist._cfg.model;
                            extendNode.status.selected = true;
                            newNodes.push(extendNode);
                        }
                    });

                    const newEdges = [];
                    newEntities.forEach(entity => {
                        const [appNodes, appEdges] = appendTerm(
                            entity,
                            [...graphNodes, ...newNodes],
                            [...graphEdges, ...newEdges],
                            MAX_EXPAND_NODES
                        );
                        newNodes.push(...appNodes);
                        newEdges.push(...appEdges);
                    });

                    onChange(data => {
                        data.nodes.forEach(node => {
                            node.status.selected = false;
                        });
                        newNodes.forEach(newNode => {
                            const exist = data.nodes.find(node => node.id === newNode.id);
                            if (exist) {
                                exist.style = newNode.style;
                                exist.extra = newNode.extra;
                                exist.status = newNode.status;
                            } else {
                                data.nodes.push(newNode);
                            }
                        });
                        data.edges.push(...newEdges);

                        if (newEntities.length === 1) {
                            return id(newEntities[0].me);
                        }
                    });
                },
                setIsLoading,
                doRsqlSearch
            );
        },
        [doRsqlSearch, graph, onChange, ws2Axios]
    );

    const [dropStatus, drop] = useDrop(dropMe, [dropMe]);

    useEffect(() => {
            setDrop(drop);
        },
        [setDrop, drop]
    );

    useEffect(() => {
            setDropStatus(dropStatus);
        },
        [setDropStatus, dropStatus]
    );

    return null;
};

const FocusBehaviour = ({ data, focusId }) => {
    const { graph } = useContext(GraphinContext);
    const [lastFocusId, setLastFocusId] = useState(undefined);
    useEffect(() => {
            if (lastFocusId !== focusId) {
                setTimeout(() => {
                    graph.focusItem(focusId);
                    setLastFocusId(focusId);
                }, 100);
            }
        },
        [data, focusId, graph, lastFocusId]
    );
    return null;
};

const NetworkDisplay = ({ data, onChange, selectedNodes, setSelectedNodes, focusId }) => {
    const { ws2Axios } = useWS2Axios();
    const [activeTool, setActiveTool] = useState('move');
    const [drop, setDrop] = useState();
    const [dropStatus, setDropStatus] = useState({ canDrop: false, isOver: false });
    const setDropFn = useCallback(fn => setDrop(data => fn), [setDrop]);

    const extend = nodeId => {
        getEntity(ws2Axios, entityDefs.term, nodeId)
            .then(entity => {
                onChange(data => {
                    const node = data.nodes.find(node => node.id === nodeId);
                    if (!node.extra.isExtended) {
                        const [newNodes, newEdges] = appendTerm(entity, data.nodes, data.edges, MAX_EXPAND_NODES);
                        data.nodes.push(...newNodes);
                        data.edges.push(...newEdges);
                        data.nodes.forEach(node => {
                            const selectNode = node.id === nodeId;
                            if (selectNode && node.extra.isExtended === false) {
                                const upNode = makeExtendedTermNode(entity, true);
                                node.style = upNode.style;
                                node.extra = upNode.extra;
                                node.status = upNode.status;
                            }
                        });
                    }
                });
            });
    };

    const collapse = nodeId => {
        onChange(data => {
            const node = data.nodes.find(node => node.id === nodeId);
            if (node.extra.isExtended) {
                const upNode = makeCollapsedTermNode({ id: node.id, name: node.label });
                node.style = upNode.style;
                node.extra = upNode.extra;
                for (let i = 0; i < data.edges.length; i++) {
                    const edge = data.edges[i];
                    if (edge.source === node.id || edge.target === node.id) {
                        const otherNodeId = edge.source === node.id ? edge.target : edge.source;
                        const otherNodeIdx = data.nodes.findIndex(node => node.id === otherNodeId);
                        const edgesOfOtherNode = data.edges
                            .filter(edge => edge.source === otherNodeId || edge.target === otherNodeId);
                        if (!data.nodes[otherNodeIdx].extra.isExtended && edgesOfOtherNode.length < 2) {
                            data.nodes.splice(otherNodeIdx, 1);
                            data.edges.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
        });
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
            ref={drop}
        >
            <DndHighlight canDrop={dropStatus.canDrop} isOver={dropStatus.isOver} color1={entityDefs.term.color}
                          color2={entityDefs.term.bgColor} />
            <Graphin
                data={data}
                theme={{
                    mode: 'light',
                    primaryColor: '#009c8f',
                    edgeSize: 2
                }}
                layout={layout}
                style={{
                    boxShadow: 'inset gray 0px 0px 60px -12px'
                }}

            >
                <ZoomCanvas
                    maxZoom={1}
                    minZoom={0.5}
                    sensitivity={0.3}
                    enableOptimize
                />
                <DragNode />
                <ActivateRelations trigger="click" />
                <ClickSelect disabled={false} />
                <ResponsiveBehaviour />
                {activeTool !== 'select' &&
                    <MyDragCanvas />
                }
                {activeTool === 'select' &&
                    <LassoSelect trigger="drag" />
                }
                <DoubleClickOpen />
                {activeTool === 'expand' &&
                    <ClickBehavior onClick={extend} />
                }
                {activeTool === 'collapse' &&
                    <ClickBehavior onClick={collapse} />
                }
                <MyToolbar active={activeTool} setActive={setActiveTool} selectedNodes={selectedNodes} />
                <MiniMap style={{ left: '8px', bottom: '8px' }} />
                <FishEye
                    visible={activeTool === 'fisheye'}
                    handleEscListener={() => setActiveTool('move')}
                />
                <Legend bindType="node" sortKey="extra.legendType">
                    {(renderProps) => {
                        return <Legend.Node {...renderProps} onChange={() => undefined} />;
                    }}
                </Legend>
                <TrackSelection setSelectedNodes={setSelectedNodes} />
                <DropBehaviour
                    setDrop={setDropFn}
                    setDropStatus={setDropStatus}
                    onChange={onChange}
                />
                <FocusBehaviour data={data} focusId={focusId} />
            </Graphin>
        </div>
    );
};

const setNodeStatusSelected = (node, selected) => {
    if (selected) {
        if (!node.status?.selected) {
            if (node.status) {
                node.status.selected = true;
            } else {
                node.status = { selected: true };
            }
        }
    } else {
        if (node.status?.selected) {
            node.status.selected = false;
        }
    }
};

const NetworkSeed = ({ entity }) => {
    const nodes = [];
    nodes.push(makeExtendedTermNode(entity));
    const [newNodes, edges] = appendTerm(entity, nodes, [], MAX_EXPAND_NODES);
    const dataDefault = {
        nodes: [...nodes, ...newNodes],
        edges,
        combos: undefined
    };
    const [data, setData] = useImmer(dataDefault);
    const [selectedNodes, setSelectedNodes] = useImmer([]);
    const [focusId, setFocusId] = useState(undefined);

    const handleDataChange = newDataFn => {
        setData(data => {
            const newFocusId = newDataFn(data);
            setFocusId(newFocusId);
            const selection = current(data).nodes
                .filter(node => node.status?.selected);
            setSelectedNodes(selection);
        });
    };

    const handleChangeSelection = newNodes => {
        setSelectedNodes(newNodes);

        const selectedIds = newNodes.map(node => node.id);
        setData(prevData => {
            prevData.nodes.forEach(node => {
                setNodeStatusSelected(node, node.id in selectedIds);
            });
        });
    };

    return (
        <ResponsiveArea>
            <NetworkDisplay
                data={data}
                onChange={handleDataChange}
                selectedNodes={selectedNodes}
                setSelectedNodes={handleChangeSelection}
                focusId={focusId}
            />
        </ResponsiveArea>
    );

};

const NetworkPage = ({ entityId }) => {
    return (
        <EntityDetailLoader
            entityDef={entityDefs.term}
            entityId={entityId}
        >
            <NetworkSeed />
        </EntityDetailLoader>
    );
};

export default NetworkPage;
