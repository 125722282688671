import {Button, Col, Popconfirm, Row, Space} from "antd";
import React, {useContext} from "react";
import {FormExtContext} from "./FormExt";
import PropTypes from "prop-types";


const DetailButtonsCreate = (props) => {
    const {isDirty, onSubmit, onSubmitAndClose, onRevert} = props;
    const {isSubmitting} = useContext(FormExtContext);

    return (
        <>
            <Row style={{width: '100%'}}>
                <Col span={24} style={{marginTop: "8px"}}>
                    <Space>
                        <Button
                            type='primary'
                            loading={isSubmitting}
                            onClick={onSubmit}
                            disabled={!isDirty}
                            //className={"create-details-btn"}
                        >
                            Create
                        </Button>
                        {onSubmitAndClose &&
                        <Button
                            type='primary'
                            loading={isSubmitting}
                            onClick={onSubmitAndClose}
                            disabled={!isDirty}
                        >
                            Create & Close
                        </Button>
                        }
                        {onRevert &&
                        <Popconfirm
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={onRevert}
                        >
                            <Button
                                type='link'
                                disabled={!isDirty}
                            >
                                Revert
                            </Button>
                        </Popconfirm>
                        }
                    </Space>
                </Col>
            </Row>
            <div style={{height: "8px"}}/>
        </>
    )
}

DetailButtonsCreate.propTypes = {
    isDirty: PropTypes.bool,
    onSubmit: PropTypes.func,
    onRevert: PropTypes.func
}

export default DetailButtonsCreate;
