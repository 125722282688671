import React, {useCallback, useContext, useEffect, useState} from "react";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import {FormExtContext} from "../forms/FormExt";
import {Button, Popconfirm, Space, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {getEntity} from "../../lib/entityRequests";
import useWS2Axios from "../../hooks/useWS2Axios";
import FilterTag from "../bits/FilterTag";
import AddEntitySelect from "./AddEntitySelect";
import AddAttributesFromSchemaSelector from "./AddAttributesFromSchemaSelector";
import {useDrop} from "react-dnd";
import {dropEntities} from "../../lib/entityUtils";
import useEntitySearch from "../../hooks/useEntitySearch";
import {DndHighlight} from "../../lib/dropIndicatorStyle";
import AttributeFields from "./AttributeFields";
import {useFieldArray} from "react-hook-form";


const AttributeTableField = ({paramName, entityType}) => {
    const {control} = useContext(FormExtContext);
    const {fields, append, remove} = useFieldArray({
        control,
        name: paramName
    });
    const {ws2Axios} = useWS2Axios();
    const mainType = entityType.split('/')[0];
    const addedIds = fields.map(entry => entry.term.id)
    const [structure, setStructure] = useState([]);
    const entitySearch = useEntitySearch(entityDefs.term.entityType, undefined, 1000, ws2Axios);

    const appendRow = useCallback(entityRef => {
            const exists = fields.find(row => row.term.id === entityRef.id);
            if (exists) return;
            append({term: entityRef, value: ""});
        },
        [fields, append]
    );

    const [isLoading, setIsLoading] = useState(false);
    const [isStructureLoading, setIsStructureLoading] = useState(false);

    const [{canDrop, isOver}, drop] = useDrop(() =>
            dropEntities(
                entityDefs.term,
                () => undefined,
                entityRefs => {
                    console.log("DROP HERE: ", entityRefs)
                    return entityRefs.forEach(appendRow);
                },
                setIsLoading,
                entitySearch.doRsqlSearch
            ),
        [setIsLoading, appendRow, entitySearch.doRsqlSearch]
    );

    useEffect(() => {
        const findBroader = entity => {
            const broaderList = entity.termsRelated
                .filter(entry => entry.relationType.id === 1) // broader
                .map(entry => entry.termRelated);
            return broaderList[0];
        }

        const findContext = async (entity, context) => {
            const broader = findBroader(entity)
            if (!broader) {
                return undefined;

            } else if (broader.commonName.startsWith("level:")) {
                // We need to find the schema one level up
                const level = await getEntity(ws2Axios, entityDefs.term, broader.id);
                const schema = findBroader(level);
                return {
                    ...context,
                    schema: schema
                }

            } else {
                const sub = await getEntity(ws2Axios, entityDefs.term, broader.id);
                const newContext = {
                    sub: sub.me,
                    ...context
                }
                return await findContext(sub, newContext)
            }
        }

        const prepareTerms = async fields => {
            const list = []
            for (const field of fields) {
                const i = fields.indexOf(field);
                const entity = await getEntity(ws2Axios, entityDefs.term, field.term.id);
                const context = await findContext(entity)
                if (context) {
                    list.push({
                        i: i,
                        term: entity,
                        context: context,
                    });
                }
            }
            return list;
        }

        setIsStructureLoading(true)
        prepareTerms(fields)
            .then(list => {
                const structure = []

                for (const key in list) {
                    const value = list[key];
                    const schema = value.context.schema;
                    let sub = {id: -1, commonName: '___none'};
                    if (value.context.sub) {
                        sub = value.context.sub;
                    }
                    let structSchema = structure.find(schema2 => schema2.term.id === schema.id);
                    if (!structSchema) {
                        structSchema = {
                            term: schema,
                            subs: []
                        }
                        structure.push(structSchema);
                    }
                    let structSub = structSchema.subs.find(sub2 => sub2.term.id === sub.id);
                    if (!structSub) {
                        structSub = {
                            term: sub,
                            attributes: []
                        }
                        structSchema.subs.push(structSub)
                    }
                    const cvTerms = value.term.termsRelated
                        .filter(r => r.relationType.id === 12)
                        .map(r => r.termRelated);
                    if (cvTerms.length > 0) {
                        value.cvTerms = cvTerms;
                    }
                    structSub.attributes.push(value)
                }
                structure.sort((a, b) => a.term.commonName.localeCompare(b.term.commonName));
                for (const schema in structure) {
                    structure[schema].subs.sort((a, b) => a.term.commonName.localeCompare(b.term.commonName));
                    for (const sub in structure[schema].subs) {
                        structure[schema].subs[sub].attributes.sort((a, b) => {
                            const aname = a.term.abbreviation !== '' ? a.term.abbreviation : a.term.name
                            const bname = b.term.abbreviation !== '' ? b.term.abbreviation : b.term.name
                            return aname.localeCompare(bname);
                        });
                    }
                }
                setStructure(structure)
                setIsStructureLoading(false)
            });

    }, [setStructure, ws2Axios, fields]);

    return (
        <Spin spinning={isLoading || isStructureLoading}>
            <div ref={drop}>
                <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDefs.term.color}
                              color2={entityDefs.term.bgClor}/>
                <AttributeFields structure={structure} paramName={paramName} fields={fields} remove={remove}/>
                <Space style={{marginTop: '16px'}} wrap={true}>
                    <FilterTag
                        size='mini'
                        entityDef={entityDefs.term}
                        list={fields.map(row => row.term)}
                    />
                    <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => remove()}
                    >
                        <Button disabled={!fields || fields.length === 0} type='text'>
                            <DeleteOutlined/> Remove All
                        </Button>
                    </Popconfirm>
                    <AddAttributesFromSchemaSelector paramName={paramName} entityType={mainType} fields={fields}
                                                     append={append}/>
                    <AddEntitySelect
                        entityDef={entityDefs.term}
                        altEntityType={entityType}
                        onChange={v => {
                            if (v === undefined) {
                                return;
                            }
                            appendRow({id: v.value, commonName: v.label});
                        }}
                        onCreated={entityRef => {
                            appendRow(entityRef)
                        }}
                        filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
                        newRowFn={entityRef => ({
                            term: entityRef,
                            value: null
                        })}
                        paramName={paramName}
                    />
                </Space>
            </div>
        </Spin>
    )
}

export default AttributeTableField;
