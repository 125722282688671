import {emptyEntityRef, entityRef} from "../../../lib/entityUtils";
// import {labelColumn, meColumn, titleColumn} from "./entityDefTools";


const emptyInstitution = {
    abbreviation: '',
    name: '',
    city: '',
    contact: '',
    country: '',
    fax: '',
    phone: '',
    state: '',
    street: '',
    uri: '',
    crossrefFunderId: '',
    institutionType: emptyEntityRef,
    ror: '',
    keywords: []
}

const entityDefs = {
    attachment: {
        entityType: "attachment",
        apiGet: "attachment",
        label: "Attachment",
        labelPl: "Attachments",
        abbr: "Att",
        withCreate: false,
        withImport: false,
        noMenu: true,
        color: "#6e6a77",
        bgColor: "#a5aeed",
        idProp: "idAttachment",
        batchEdit: false,
        cloneable: false,
        emptyEntity: {
            title: '',
            attachmentID: '',
            filename: ''
        }
    },

    award: {
        entityType: "award",
        apiGet: "award",
        label: "Award",
        labelPl: "Awards",
        abbr: "A",
        withCreate: true,
        withImport: false,
        color: "#6e6a77",
        bgColor: "#a5aeed",
        idProp: "idAward",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            title: '',
            awardNumber: '',
            uri: '',
            comment: '',
            institution: emptyEntityRef,
            project: emptyEntityRef
        }
    },
    basis: {
        entityType: "basis",
        apiGet: "basis",
        label: "Basis",
        labelPl: "Bases",
        abbr: "B",
        withCreate: true,
        withImport: false,
        color: "#817d6f",
        bgColor: "#b5c9c3",
        idProp: "idBasis",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            callsign: '',
            imoNumber: '',
            uri: '',
            institution: emptyEntityRef
        }
    },
    campaign: {
        entityType: "campaign",
        apiGet: "campaign",
        label: "Campaign",
        labelPl: "Campaigns",
        abbr: "C",
        withCreate: true,
        withImport: true,
        importTemplate: "http://hdl.handle.net/10013/epic.48105.d002",
        color: "#7c6a11",
        bgColor: "#f3ec5d",
        idProp: "idCampaign",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            nameOptional: '',
            comment: '',
            datetimeBegin: null,
            datetimeEnd: null,
            uri: '',
            basis: emptyEntityRef,
            project: emptyEntityRef,
            reference: emptyEntityRef,
            staffs: [],
            attributes: []
        }
    },
    dataset: {
        entityType: "dataset",
        apiGet: "dataset",
        label: "Dataset",
        labelPl: "Datasets",
        abbr: "D",
        withCreate: true,
        withImport: false,
        color: "#5f7a39",
        bgColor: "#aada66",
        idProp: "idDataset",
        batchEdit: true,
        modalWidth: "90%",
        formButtonsOnTop: true,
        cloneable: true,
        clonePreprocessFn: entity => ({
            ...entity,
            history: [],
            loginOption: entityRef(3, 'access rights needed'),
            datasetStatus: {id: -2, name: "draft"},
            datetimePublication: null
        }),
        emptyEntity: {
            ticket: '',
            datasetStatus: emptyEntityRef,
            collectionType: emptyEntityRef,
            loginOption: emptyEntityRef,
            curationLevel: emptyEntityRef,
            processingLevel: emptyEntityRef,
            title: '',
            staffs: [],
            keywords: [],
            abstractText: '',
            license: emptyEntityRef,
            institution: emptyEntityRef,
            events: [],
            projects: [],
            awards: [],
            parameterElevation: emptyEntityRef,
            moratoriumUntil: null,
            datetimePublication: null,
            journal: emptyEntityRef,
            volume: '',
            filename: '',
            alternativeTitle: '',
            comment: '',
            topotype: emptyEntityRef,
            history: [],
            commentInternal: '',
            groupAccess: [],
            userAccess: [],
            references: [],
            datasets: [],
            attributes: [],
            parentDataset: emptyEntityRef
        },
        setDefaultsFn: entity => ({
            ...entity,
            datasetStatus: entityRef(-2, 'draft'),
            loginOption: entityRef(3, 'access rights needed'),
            license: entityRef(21, "CC-BY-4.0")
        })
    },
    event: {
        entityType: "event",
        apiGet: "event",
        label: "Event",
        labelPl: "Events",
        abbr: "E",
        withCreate: true,
        withImport: true,
        importTemplate: "https://store.pangaea.de/documentation/PANGAEA-Wiki/Intern/InpEvent.xlsx",
        color: "#813f68",
        bgColor: "#ff8fd6",
        idProp: "idEvent",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            label: '',
            labelOptional: '',
            comment: '',
            datetime: null,
            datetime2: null,
            elevation: null,
            elevation2: null,
            latitude: null,
            latitude2: null,
            longitude: null,
            longitude2: null,
            uri: '',
            method: emptyEntityRef,
            campaign: emptyEntityRef,
            project: emptyEntityRef,
            termLocation: emptyEntityRef,
            attributes: [],
            keywords: []
        }
    },
    funder: {
        entityType: "funder",
        apiGet: "funder",
        label: "Funder",
        labelPl: "Funders",
        abbr: "F",
        withCreate: true,
        withImport: false,
        color: "#817d6f",
        bgColor: "#c7c9b5",
        idProp: "idInstitution",
        batchEdit: true,
        cloneable: true,
        emptyEntity: emptyInstitution
    },
    institution: {
        entityType: "institution",
        apiGet: "institution",
        label: "Institution",
        labelPl: "Institutions",
        abbr: "I",
        withCreate: true,
        withImport: false,
        color: "#817d6f",
        bgColor: "#c7c9b5",
        idProp: "idInstitution",
        batchEdit: true,
        cloneable: true,
        emptyEntity: emptyInstitution
    },
    journal: {
        entityType: "journal",
        apiGet: "journal",
        label: "Journal",
        labelPl: "Journals",
        abbr: "J",
        withCreate: true,
        withImport: false,
        color: "#83693d",
        bgColor: "#ffcc76",
        idProp: "idJournal",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            discipline: '',
            issn: '',
            periodicity: '',
            uri: '',
            institution: emptyEntityRef,
            keywords: []
        }
    },
    method: {
        entityType: "method",
        apiGet: "method",
        label: "Method",
        labelPl: "Methods",
        abbr: "M",
        withCreate: true,
        withImport: true,
        color: "#617a7e",
        bgColor: "#a7d3da",
        idProp: "idMethod",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            description: '',
            uri: '',
            reference: emptyEntityRef,
            abbreviation: '',
            terms: []
        }
    },
    parameter: {
        entityType: "parameter",
        apiGet: "parameter",
        label: "Parameter",
        labelPl: "Parameters",
        abbr: "P",
        //detailComponent: <ParameterDetail/>,
        //overviewComponent: <ParameterOverview/>,
        withCreate: true,
        withImport: true,
        importTemplate: "http://hdl.handle.net/10013/epic.38464.d003",
        color: "#496385",
        bgColor: "#7eaae5",
        idProp: "idParameter",
        cloneable: true,
        batchEdit: true,
        emptyEntity: {
            abbreviation: '',
            name: '',
            unit: '',
            dataType: emptyEntityRef,
            description: '',
            format: '',
            rangeMin: null,
            rangeMax: null,
            uri: '',
            method: emptyEntityRef,
            reference: emptyEntityRef,
            ucum: '',
            keywords: [],
            terms: []
        },
        findDuplicates: {
            duplicateCheckMethod: 'parameter',
            fromAPIResponseToRenderObject: (response) => {
                return response.records.map(match =>
                    ({
                        entityRef: match.me,
                        score: 1.0,
                        matchInfo: "Same name and unit"
                    })
                )
            },
            fromInputToAPIQuery: (enteredValues) =>
                "(name=='" + enteredValues.name + "';unit=='" + enteredValues.unit + "')"
            //enteredValues  //API takes every value as input
        },
    },
    project: {
        entityType: "project",
        apiGet: "project",
        label: "Project",
        labelPl: "Projects",
        abbr: "PR",
        withCreate: true,
        withImport: false,
        color: "#5d5175",
        bgColor: "#bda5ed",
        idProp: "idProject",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            label: '',
            projectType: emptyEntityRef,
            institution: emptyEntityRef,
            staffs: emptyEntityRef,
            uri: '',
            uriData: '',
            comment: ''
        }
    },
    reference: {
        entityType: "reference",
        apiGet: "reference",
        label: "Reference",
        labelPl: "References",
        abbr: "R",
        withCreate: true,
        withImport: true,
        importTemplate: "https://store.pangaea.de/documentation/PANGAEA-Wiki/Intern/InpReference.xlsx",
        color: "#7c7121",
        bgColor: "#fff083",
        idProp: "idReference",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            title: '',
            abstractText: '',
            comment: '',
            pages: '',
            serialNumber: '',
            source: '',
            uri: '',
            volume: '',
            year: null,
            journal: emptyEntityRef,
            referenceStatus: emptyEntityRef,
            staffs: [],
            keywords: [],
            attachments: []
        },
        findDuplicates: {
            duplicateCheckMethod: 'reference',
            fromAPIResponseToRenderObject: (response) => {
                return response.map(match =>
                    ({
                        entityRef: {id: match.id, commonName: match.name},
                        score: match.score,
                        matchInfo: match.match_info
                    })
                )
            },
            fromInputToAPIQuery: (enteredValues) =>
                enteredValues  //API takes every value as input
        },
    },
    staffs: {
        entityType: "staffs",
        apiGet: "staffs",
        label: "Staffs",
        labelPl: "Staffs",
        abbr: "S",
        withCreate: true,
        withImport: true,
        color: "#817d6f",
        bgColor: "#b5c9bc",
        idProp: "idStaffs",
        batchEdit: true,
        cloneable: true,
        findDuplicates: {
            duplicateCheckMethod: 'staffs',
            fromAPIResponseToRenderObject: (response) => {
                // const matches = response.filter((match) => {
                //     const sameID = entityId ? entityId === match.id : false;
                //     return !sameID
                const matches = response[0].matches;
                return matches.map(match => {
                    return {
                        entityRef: {id: match.id, name: match.name},
                        score: match.score,
                        matchInfo: match.match_info,
                    }
                });
            },
            fromInputToAPIQuery: (enteredValues) =>
                ({
                    name_last: enteredValues.nameLast,
                    name_first: enteredValues.nameFirst,
                    email: enteredValues.email,
                    orcid: enteredValues.orcid
                })
        },
        emptyEntity: {
            nameLast: '',
            nameFirst: '',
            email: '',
            orcid: '',
            phone: '',
            uri: '',
            institution: emptyEntityRef,
            keywords: []
        }
    },
    term: {
        entityType: "term",
        apiGet: "term",
        label: "Term",
        labelPl: "Terms",
        abbr: "T",
        withCreate: true,
        withImport: true,
        color: "#2f7a6e",
        bgColor: "#97e9dc",
        idProp: "idTerm",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            abbreviation: '',
            comment: '',
            description: '',
            semanticUri: '',
            uri: '',
            termCategory: emptyEntityRef,
            termStatus: emptyEntityRef,
            terminology: emptyEntityRef,
            termsRelated: [],
            termsRelatedReverse: []
        }
    },
    terminology: {
        entityType: "terminology",
        apiGet: "terminology",
        label: "Terminology",
        labelPl: "Terminologies",
        abbr: "TM",
        withCreate: true,
        withImport: false,
        color: "#2f687a",
        bgColor: "#82b9cd",
        idProp: "idTerminology",
        cloneable: true,
        emptyEntity: {
            abbreviation: '',
            name: '',
            comment: '',
            creator: '',
            description: '',
            uri: ''
        }
    },
    series: {
        entityType: "series",
        apiGet: "series",
        label: "Data Series",
        labelPl: "Data Series",
        abbr: "DS",
        withCreate: false,
        withImport: false,
        color: "#77771e",
        bgColor: "#d5d436",
        idProp: "idDataSeries",
        batchEdit: true,
    },
    keyword: {
        entityType: "keyword",
        apiGet: "keyword",
        label: "Keyword",
        labelPl: "Keywords",
        abbr: "K",
        withCreate: true,
        withImport: false,
        color: "#2f7a6e",
        bgColor: "#97e9dc",
        idProp: "idTerm",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            abbreviation: '',
            comment: '',
            description: '',
            semanticUri: '',
            uri: '',
            termCategory: emptyEntityRef,
            termStatus: emptyEntityRef,
            terminology: emptyEntityRef,
            termsRelated: [],
            termsRelatedReverse: []
        },
        setDefaultsFn: entity => ({
            ...entity,
            termCategory: entityRef(7, "keywords"),
            termStatus: entityRef(2, "PANGAEA accepted"),
            terminology: entityRef(10, "Keywords, PANGAEA")
        })
    },
    location: {
        entityType: "location",
        apiGet: "location",
        label: "Location",
        labelPl: "Locations",
        abbr: "LC",
        withCreate: true,
        withImport: false,
        color: "#2f7a6e",
        bgColor: "#97e9dc",
        idProp: "idTerm",
        batchEdit: true,
        cloneable: true,
        emptyEntity: {
            name: '',
            abbreviation: '',
            comment: '',
            description: '',
            semanticUri: '',
            uri: '',
            termCategory: emptyEntityRef,
            termStatus: emptyEntityRef,
            terminology: emptyEntityRef,
            termsRelated: [],
            termsRelatedReverse: []
        },
        setDefaultsFn: entity => ({
            ...entity,
            termCategory: entityRef(5, "instances"),
            termStatus: entityRef(2, "PANGAEA accepted"),
            terminology: entityRef(4, "Locations, PANGAEA")
        })
    },
    panGroup: {
        entityType: "panGroup",
        apiGet: "panGroup",
        label: "Group",
        labelPl: "Groups",
        abbr: "G",
        withCreate: true,
        withImport: false,
        color: "#838071",
        bgColor: "#eee8cd",
        idProp: "idGroup",
        emptyEntity: {
            name: '',
            institution: emptyEntityRef,
            comment: ''
        }
    },
    panUser: {
        entityType: "panUser",
        apiGet: "panUser",
        label: "User",
        labelPl: "Users",
        abbr: "U",
        withCreate: false,
        withImport: false,
        noDeleteEntity: true,
        color: "#838071",
        bgColor: "#eee8cd",
        idProp: "idUser",
        emptyEntity: {
            username: '',
            fullName: '',
            created: null,
            staffs: emptyEntityRef,
            groups: []
        }
    },
    license: {
        entityType: "license",
        apiGet: "license",
        label: "License",
        labelPl: "Licenses",
        abbr: "L",
        withCreate: false,
        withImport: false,
        color: "#575757",
        bgColor: "#a0a0a0",
        idProp: "idLicense",
        noFollow: true
    },
    datasetConfigField: {
        entityType: "datasetConfigField",
        apiGet: "datasetConfigField",
        label: "Config Field",
        labelPl: "Config Fields",
        abbr: "CF",
        withCreate: false,
        withImport: false,
        color: "#575757",
        bgColor: "#a0a0a0",
        idProp: "idDatasetConfigField",
        noFollow: true,
        noMenu: true
    },
    datasetStatus: {
        entityType: "datasetStatus",
        typeName: 'datasetStatus',
        noFollow: true,
        noMenu: true
    },
    loginOption: {
        entityType: "loginOption",
        typeName: "loginOption",
        noFollow: true,
        noMenu: true
    },
    curationLevel: {
        entityType: "curationLevel",
        typeName: "curationLevel",
        noFollow: true,
        noMenu: true
    },
    processingLevel: {
        entityType: "processingLevel",
        typeName: "processingLevel",
        noFollow: true,
        noMenu: true
    },
    dataType: {
        entityType: "dataType",
        typeName: "data",
        noFollow: true,
        noMenu: true
    },
    referenceStatus: {
        entityType: "referenceStatus",
        typeName: "referenceStatuses",
        noFollow: true,
        noMenu: true
    },
    referenceType: {
        entityType: "referenceType",
        typeName: "referenceTypes",
        noFollow: true,
        noMenu: true
    },
    termStatus: {
        entityType: "termStatus",
        typeName: "termStatuses",
        noFollow: true,
        noMenu: true
    },
    termCategory: {
        entityType: "termCategory",
        typeName: "termCategories",
        noFollow: true,
        noMenu: true
    },
    institutionType: {
        entityType: "institutionType",
        typeName: "institution",
        noFollow: true,
        noMenu: true
    },
    projectType: {
        entityType: "projectType",
        typeName: "project",
        noFollow: true,
        noMenu: true
    },
    topotype: {
        entityType: "topotype",
        typeName: "topotype",
        noFollow: true,
        noMenu: true
    },
    collectionType: {
        entityType: "collectionType",
        typeName: "collection",
        noFollow: true,
        noMenu: true
    },
    dataStatus: {
        entityType: "dataStatus",
        noFollow: true,
        noMenu: true
    },
    fieldDesc: {
        entityType: "fieldDesc",
        dummy: true,
        noMenu: true
    }
}

export const findEntityDefByAbbr = abbr => {
    const found = Object.values(entityDefs)
        .filter(entityDef => entityDef.abbr)
        .map(entityDef => entityDef.abbr.toLowerCase() === abbr.toLowerCase() ? entityDef : null)
        .filter(entityDef => entityDef !== null);
    if (found.length > 0) {
        return found[0];
    }
    return undefined;
}

export const getEmptyWithDefaults = entityDef => {
    if (!entityDef.setDefaultsFn) {
        return entityDef.emptyEntity;
    }
    return entityDef.setDefaultsFn(entityDef.emptyEntity);
}

export default entityDefs;
