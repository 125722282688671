import React, { useCallback, useState } from 'react';
import KanbanBoard from './KanbanBoard';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { FloatButton, Select, Space, Spin, Tabs } from 'antd';
import DoneList from './DoneList';
//import KPIs from "./KPIs";
import useMyUser from '../../../hooks/useMyUser';
import useResource from '../../../hooks/useResource';
import { ReloadOutlined } from '@ant-design/icons';
import KPIs from './KPIs';

dayjs.extend(duration);

const getUserGroupOptions = (userGroupIds, options) => {
    if (options?.QS && userGroupIds.includes(options.QS.id)) {
        return options.QS.values;
    } else {
        return options.DEFAULT.values;
    }
};
const ControlBoard = ({ title, serviceClassDefs, columnDefs, options }) => {
    // const userGroup = "DEFAULT"
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [selectedServiceClasses, setSelectedServiceClasses] = useState([]);
    const { myUser } = useMyUser();
    const userGroupOptions = getUserGroupOptions(myUser.userGroups, options);
    const { result, isLoading, reload } = useResource('board/' + userGroupOptions.board);

    const componentFilter = useCallback(
        card => selectedComponents.length === 0 || card.components.some(c => selectedComponents.includes(c)),
        [selectedComponents]
    );
    const tagFilter = useCallback(
        card => selectedTags.length === 0 || card.tags.some(t => selectedTags.includes(t)),
        [selectedTags]
    );
    const assigneeFilter = useCallback(
        card => selectedAssignees.length === 0 || selectedAssignees.includes(card.assignee.name),
        [selectedAssignees]
    );
    const serviceClassFilter = useCallback(
        card => selectedServiceClasses.length === 0 || selectedServiceClasses.includes(card.serviceClass),
        [selectedServiceClasses]
    );

    const components = new Set();
    result.forEach(card => {
        card.components.forEach(component => components.add(component));
    });
    const componentOptions = [];
    components.forEach(component => {
        componentOptions.push({ label: component, value: component, key: component });
    });

    const tags = new Set();
    result.forEach(card => {
        card.tags.forEach(tag => tags.add(tag));
    });
    const tagOptions = [];
    tags.forEach(tag => {
        tagOptions.push({ label: tag, value: tag, key: tag });
    });

    const assignees = new Set();
    result.forEach(card => {
        if (card.assignee.name !== null) {
            assignees.add(card.assignee.name);
        }
    });
    const assigneeOptions = [];
    assignees.forEach(assignee => {
        assigneeOptions.push({ label: assignee, value: assignee, key: assignee });
    });

    const serviceClasses = new Set();
    result.forEach(card => {
        serviceClasses.add(card.serviceClass);
    });
    const serviceClassOptions = [];
    serviceClasses.forEach(serviceClass => {
        serviceClassOptions.push({ label: serviceClass, value: serviceClass, key: serviceClass });
    });

    const cards = result
        .filter(assigneeFilter)
        .filter(componentFilter)
        .filter(tagFilter)
        .filter(serviceClassFilter);

    const tabs = [
        {
            key: '1',
            label: 'Kanban Board',
            children: (
                <KanbanBoard
                    columnDefs={columnDefs}
                    serviceClassDefs={serviceClassDefs}
                    options={userGroupOptions}
                    cards={cards}
                    isFiltered={selectedTags.length > 0 || selectedComponents.length > 0 || selectedAssignees.length > 0 || selectedServiceClasses.length > 0}
                />
            )
        },
        {
            key: '2',
            label: 'Done',
            children: (
                <DoneList
                    serviceClassDefs={serviceClassDefs}
                    options={userGroupOptions}
                />
            )
        },
        {
            key: '3',
            label: 'KPIs',
            children: (
                <KPIs
                    columnDefs={columnDefs}
                    options={userGroupOptions}
                />
            )
        }
    ];
    return (
        <Spin spinning={isLoading}>
            <Tabs
                items={tabs}
                tabBarExtraContent={{
                    left: <div style={{ marginRight: '32px' }}>{title}</div>,
                    right: (
                        <Space direction={'vertical'}>
                            <BoardFilter
                                value={selectedServiceClasses}
                                onChange={setSelectedServiceClasses}
                                options={serviceClassOptions}
                                placeholder="Filter service classes"
                            />
                            <BoardFilter
                                value={selectedComponents}
                                onChange={setSelectedComponents}
                                options={componentOptions}
                                placeholder="Filter components"
                            />
                            <BoardFilter
                                value={selectedTags}
                                onChange={setSelectedTags}
                                options={tagOptions}
                                placeholder="Filter tags"
                            />
                            <BoardFilter
                                value={selectedAssignees}
                                onChange={setSelectedAssignees}
                                options={assigneeOptions}
                                placeholder="Filter assignees"
                            />
                        </Space>
                    )
                }}
            />
            <FloatButton
                onClick={reload}
                icon={<ReloadOutlined />}
            />
        </Spin>
    );
};

const BoardFilter = ({ placeholder, value, onChange, options }) => {
    if (options.length === 0) {
        return null;
    }
    options.sort((a, b) => a.label > b.label);
    return (
        <Select
            mode="multiple"
            allowClear
            style={{
                width: 400
            }}
            placeholder={placeholder}
            defaultValue={[]}
            value={value}
            onChange={onChange}
            options={options}
        />
    );
};

export default ControlBoard;
