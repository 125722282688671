import useEntityList from '../../../hooks/useEntityList';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Empty, Modal, Space, Spin, Table } from 'antd';
import BatchEditForm from './BatchEditForm';
import { ResponsiveContext } from '../../../tk/cards/ResponsiveArea';
import { ReloadOutlined } from '@ant-design/icons';
import { addEntityDetailTab } from '../../../lib/flexlayoutTabTools';
import useMyUser from '../../../hooks/useMyUser';
import { ColumnSelector } from './ColumnSelector';
import NotificationsContext from '../../../contexts/NotificationsContext';
import EntityTag from '../../../tk/bits/EntityTag';
import useLocalStorageNested from '../../../hooks/useLocalStorageNested';
import FilterTag from '../../../tk/bits/FilterTag';
import BigTableHeader from './BigTableHeader';


const unwrapEntityRef = entityRef => {
    if (entityRef === undefined) {
        return null;
    }
    if (entityRef.value !== undefined) {
        return entityRef.value;
    }
    if (entityRef.id !== undefined) {
        return entityRef.id;
    }
    return entityRef;
};

export const buildFilterString = (filters) => {
    let result = '';
    for (const key in filters) {
        if (filters[key] === null || filters[key].length === 0) continue;
        if (result.length > 0) {
            result += ';';
        }
        let keyname = key;
        if (keyname === 'me') {
            keyname = 'id';
        }

        const keyFilter = filters[key];
        if (Array.isArray(keyFilter) && keyFilter.length > 0) {
            if (keyFilter[0].mode !== undefined) {
                result += keyname + keyFilter[0].mode + '\'' + keyFilter[0].value + '\'';
                if (keyFilter.length > 1) {
                    result += ';' + keyname + keyFilter[1].mode + '\'' + keyFilter[1].value + '\'';
                }
            } else {
                if (keyFilter.length > 1) {
                    let entries = '';
                    for (const entry of keyFilter) {
                        if (entries.length > 0) {
                            entries += ',';
                        }
                        entries += '"' + unwrapEntityRef(entry) + '"';
                    }
                    result += keyname + '=in=(' + entries + ')';
                } else {
                    result += keyname + '=="' + unwrapEntityRef(keyFilter[0]) + '"';
                }
            }
        }
    }

    return result;
};

const isFiltersEmpty = filters => {
    for (const key in filters) {
        if (filters[key] !== null) {
            return false;
        }
    }
    return true;
};

const getDefaultFilters = (entityColumns) => {
    return entityColumns().defaultFilters === undefined ? {} : entityColumns().defaultFilters;
};

const getDefaultSelectedColumns = (entityColumns) => {
    return entityColumns().columns.map(
        (column) => ({ 'id': column.key, 'dataIndex': column.dataIndex, 'name': column.title, 'checked': true }));
};

const applyColumnSelection = (entityColumns, selectedColumns) => {
    const getEntityColumnByID = (entityColumnsArr, ID) => {
        for (const ind in entityColumnsArr) {
            if (entityColumnsArr[ind].key === ID) {
                return entityColumnsArr[ind];
            }
        }
    };
    return selectedColumns
        .filter(column => column.checked)
        .map(column => getEntityColumnByID(entityColumns().columns, column.id));
};

const buildQuery = (filters, myUser, delayedInput) => {
    let newFilterString = buildFilterString(filters);
    if (newFilterString.length > 0) {
        newFilterString = '(' + newFilterString + ')';
    }
    if (delayedInput.length > 0) {
        if (newFilterString.length > 0) {
            newFilterString += ';';
        }
        newFilterString += delayedInput;
    }
    return newFilterString;
};

export const slimTableWidthThreshold = 410;

const pageSizeOptions = [10, 20, 50, 100, 250, 500];

const BigEntityTable = (props) => {
    const { entityDef, entityColumns, refLayout, defaultRsql } = props;
    const [sort, setSort] = useState('undefined'); // WHY? This helps to deduplicate the first filter request after new list loaded
    const [filters, setFilters] = useState(getDefaultFilters(entityColumns));
    //useLocalStorage to save the Users' presets for column selection
    const defaultColumns = getDefaultSelectedColumns(entityColumns);
    const [selectedColumns, setSelectedColumns] = useLocalStorageNested('columnFilters', entityDef.entityType, defaultColumns);
    const defaultPageSize = entityDef.entityType === 'dataset' ? 50 : 100;
    const [pageSize, setPageSize] = useLocalStorageNested('pageSizes', entityDef.entityType, defaultPageSize);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: pageSize
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [batchEditVisible, setBatchEditVisible] = useState(false);
    const [rsqlInput, setRsqlInput] = useState(defaultRsql || '');
    const { myUser } = useMyUser();
    const cardContext = useContext(ResponsiveContext);
    const { notifySuccess } = useContext(NotificationsContext);
    const preparedColumns = useMemo(() => {
            const entityColumnsFiltered = applyColumnSelection(entityColumns, selectedColumns);
            const map1 = entityColumnsFiltered
                .filter(column => column !== undefined)
                .map(column =>
                    ({
                        ...column,
                        fixed: cardContext.dimensions.width < slimTableWidthThreshold ? undefined : column.fixed,
                        filteredValue: filters[column.dataIndex] || []
                    })
                );
            //console.log("COLUMN", map1);
            if (map1[0]) { //if no columns selected
                map1[0].render = (id, row) => <EntityTag entityRef={row['me']} entityDef={entityDef} display="id" />;
            }
            return map1;
        },
        [entityColumns, selectedColumns, cardContext.dimensions.width, filters, entityDef]
    );
    const defaultQuery = buildQuery(filters, myUser, rsqlInput);
    const [query, setQuery] = useState(defaultQuery || '');

    const fields = selectedColumns
        .filter(col => col.checked)
        .map(col => col.dataIndex)
        .join(',');
    const {
        entityList,
        loading,
        total,
        error,
        reload,
        findById
    } = useEntityList(entityDef, pagination, sort, query, fields);

    const entitySelection = useMemo(() =>
            selectedRowKeys
                .map(id => findById(id))
                .filter(entityRef => entityRef.id !== null),
        [findById, selectedRowKeys]
    );

    useEffect(() => {
            const newFilterString = buildQuery(filters, myUser, rsqlInput);
            setQuery(newFilterString);
        },
        [filters, rsqlInput, myUser, setQuery]
    );

    const handleTableChange = useCallback((newPagination, newFilters, sorter) => {
            //console.log("TABLE CHANGE", newFilters, filters)
            setPageSize(newPagination.pageSize);
            setPagination({
                current: newPagination.current,
                pageSize: newPagination.pageSize
            });
            setSort((sorter.order === 'descend' ? '-' : '') + sorter.field);
            setFilters(newFilters);
        },
        [setPageSize]
    );

    const rowSelectionConfig = useMemo(() => ({
            //selectedRowKeys,
            onChange:
                newSelectedRowKeys => {
                    setSelectedRowKeys(newSelectedRowKeys);
                }
        }),
        [setSelectedRowKeys]
    );

    const rowKey = useCallback(
        record => record[entityDef.idProp],
        [entityDef.idProp]
    );

    const collapsableTotalButtons = useMemo(() => ([
            entityDef.batchEdit ?
                <Button
                    size="small"
                    type="primary"
                    disabled={entitySelection.length === 0}
                    onClick={() => setBatchEditVisible(true)}
                >
                    Batch edit ({entitySelection.length})
                </Button> : undefined,
            <Button
                name="list-reload"
                size="small"
                onClick={reload}
            >
                <ReloadOutlined />
            </Button>,
            <Button
                size="small"
                disabled={isFiltersEmpty(filters)}
                onClick={() => setFilters({})}
            >
                Clear filters
            </Button>
        ]),
        [entityDef.batchEdit, entitySelection.length, filters, reload]
    );

    const totalAndFilters = useMemo(() => (
            <Space
                align="baseline"
                style={{ marginTop: -8 }}
            >
                <FilterTag
                    size="mini"
                    entityDef={entityDef}
                    list={entitySelection}
                    extraStyle={{ margin: '0' }}
                />
                {collapsableTotalButtons
                    .filter(c => c !== undefined)
                    .map((c, index) => React.cloneElement(c, { key: index.toFixed() }))
                }
                <ColumnSelector
                    selectedColumns={selectedColumns.filter(col => col.id !== 'id')}
                    setSelectedColumns={v => setSelectedColumns([{ id: 'id', name: 'ID', checked: true }, ...v])}
                    defaultColumns={defaultColumns.filter(col => col.id !== 'id')}
                />
            </Space>
        ),
        [collapsableTotalButtons, defaultColumns, entityDef, entitySelection, selectedColumns, setSelectedColumns]
    );

    const TOTAL_EMPTY = 99999999;

    const paginationOptions = useMemo(() => ({
            ...pagination,
            showSizeChanger: true,
            simple: false,
            // have additional buttons there, like "clear filters"
            total: total === undefined || total === 0 ? TOTAL_EMPTY : total,
            position: ['bottomRight'],
            pageSizeOptions: pageSizeOptions,
            showTotal: (total, range) => {
                if (total === TOTAL_EMPTY) {
                    return 'counting ...';
                }
                return `${range[0]}-${range[1]} of ${total} rows`;
            },
            responsive: true
        }),
        [pagination, total]
    );

    const scrollOptions = useMemo(() => ({
            y: cardContext.dimensions.height - (cardContext.dimensions.width < slimTableWidthThreshold ? 137 : 113) - 30
        }),
        [cardContext.dimensions.height, cardContext.dimensions.width]
    );

    const myBodyRow = useCallback(({ index, entityDef, data, className, style, ...restProps }) => {
            if (entityDef === undefined) {
                entityDef = {};
            }

            if (index === undefined) {
                return (
                    <tr>
                        <td colSpan="100%">
                            <Empty style={{ width: '200px', margin: '20px' }} />
                        </td>
                    </tr>
                );
            }

            return (
                <tr onDoubleClick={e => {
                    if(!entityDef.noFollow) {
                        addEntityDetailTab(refLayout.current, data.me, entityDef);
                    }
                }}>
                    {restProps.children}
                </tr>
            );
        },
        [refLayout]
    );

    const componentsConfig = useMemo(() => ({
            body: {
                row: myBodyRow
            }
        }),
        [myBodyRow]
    );

    const onRow = useCallback((record, index) => {
            return ({
                index,
                entityDef: entityDef,
                data: entityList[index]
            });
        },
        [entityDef, entityList]
    );

    const table = useMemo(() => (
            <Table
                className="maxi-table"
                showSorterTooltip={false}
                columns={preparedColumns}
                rowKey={rowKey}
                dataSource={entityList}
                pagination={paginationOptions}
                loading={loading}
                onChange={handleTableChange}
                scroll={scrollOptions}
                size="small"
                components={componentsConfig}
                onRow={onRow}
                showHeader={true}
                rowSelection={rowSelectionConfig}
            />
        ),
        [componentsConfig, entityList, handleTableChange, loading, onRow, paginationOptions, preparedColumns, rowKey, rowSelectionConfig, scrollOptions]
    );

    if (entityList === undefined) {
        return (
            <Spin>
                <div style={{ height: '100px', width: '100%' }} />
            </Spin>
        );
    }

    return (
        <div style={{ paddingTop: '8px' }}>
            <BigTableHeader
                entityDef={entityDef}
                entityColumns={entityColumns ? entityColumns().columns : undefined}
                defaultRsql={defaultRsql}
                query={rsqlInput}
                setQuery={setRsqlInput}
                loading={loading}
                error={error}
            />
            {table}
            <div>
                {totalAndFilters}
            </div>
            <Modal
                title={`Batch Edit (${entitySelection.length}) ${entityDef.labelPl}`}
                width="60%"
                open={batchEditVisible}
                onOk={() => setBatchEditVisible(false)}
                onCancel={() => setBatchEditVisible(false)}
                footer={null}
                styles={{ body: { paddingLeft: '0', paddingRight: '0', paddingBottom: '0' } }}
                keyboard={false}
            >
                <BatchEditForm
                    previewColumns={preparedColumns}
                    entityRefs={entitySelection}
                    entityDef={entityDef}
                    onOk={() => {
                        setBatchEditVisible(false);
                        notifySuccess('Batch edit success.');
                        reload();
                    }}
                    onCancel={() => setBatchEditVisible(false)}
                />
            </Modal>
        </div>
    );
};

export default BigEntityTable;
