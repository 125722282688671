import {useCallback, useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";
import {getEntity} from "../lib/entityRequests";
import useEntityCreate from "./useEntityCreate";
import {handleNetworkError} from "../lib/errorHandler";


const useEntity = (entityDef, id, defaultEntity) => {
    const [entity, setEntity] = useState(undefined);
    const [error, setError] = useState(undefined);
    const {ws2Axios} = useWS2Axios();
    const {createEntity} = useEntityCreate(entityDef);

    const updateEntity = useCallback(data => {
            return ws2Axios
                .put(apiBaseUrl + "crud/" + entityDef.apiGet + "/" + id, data)
                .then(response => {
                    const entity = response.data;
                    setEntity(entity);
                    return entity;
                })
        },
        [entityDef, id, ws2Axios]
    );

    const batchEntity = useCallback((batch, intercept) => {
            return ws2Axios
                .post(apiBaseUrl + "batch", batch)
                .then(response => {
                    const entity = intercept ? intercept(response.data) : response.data;
                    setEntity(entity);
                    return entity;
                })
        },
        [ws2Axios]
    );

    const deleteEntity = useCallback(id => {
            return ws2Axios
                .delete(apiBaseUrl + "crud/" + entityDef.apiGet + "/" + id)
        },
        [entityDef, ws2Axios]
    );

    const loadEntity = useCallback(() => {
            if (!id) {
                setEntity(defaultEntity);
                return;
            }
            return getEntity(ws2Axios, entityDef, id)
                .then(entity => {
                    setEntity(entity);

                    return entity;
                })
                .catch(error => setError(handleNetworkError(error)));
        },
        [defaultEntity, entityDef, id, ws2Axios]
    );

    useEffect(() => {
        loadEntity();
    }, [entityDef, loadEntity, id, ws2Axios]);

    return {
        entity,
        updateEntity,
        batchEntity,
        deleteEntity,
        setEntity,
        loadEntity,
        createEntity,
        error
    };
};

export default useEntity;
