import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    starStore: {
        staffs: ['id>10']
    }
}

const removeStarEntry = (rsql, entityType, starStore) => {
    const existingIndex = starStore[entityType].findIndex(e => e === rsql);
    if (existingIndex >= 0) {
        starStore[entityType].splice(existingIndex, 1);
    }
}

const clearStarEntries = (entityType, starStore) => {
    starStore[entityType] = [];
}

const filterStarsSlice = createSlice({
        name: 'filterStars',
        initialState,
        reducers: {
            setFilterStar(state, action) {
                const {isStarred, entityType, rsql} = action.payload;

                if (isStarred) {
                    if (!state.starStore[entityType]) {
                        state.starStore[entityType] = [rsql];
                    } else {
                        removeStarEntry(rsql, entityType, state.starStore);
                        state.starStore[entityType].unshift(rsql);
                    }

                } else {
                    if (state.starStore[entityType]) {
                        removeStarEntry(rsql, entityType, state.starStore);
                    }
                }
            },
            clearFilterStars(state, action) {
                const {entityType} = action.payload;
                clearStarEntries(entityType, state.starStore);
            }
        }
    }
);

export const isFilterStarred = (starStore, rsql, entityType) => {
    if (!starStore[entityType]) return false;
    return starStore[entityType].findIndex(e => e === rsql) >= 0;
}

export const {setFilterStar, clearFilterStars} = filterStarsSlice.actions;
export default filterStarsSlice.reducer;
