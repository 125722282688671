import React, {useContext, useEffect} from "react";
import useDatasetConfig from "../../../hooks/useDatasetConfig";
import {formatOptions, getAvailableConfigs} from "../dataset/DataMatrixHorizontal";
import {randomId} from "../../../lib/random";
import ContainerContext from "../../../contexts/ContainerContext";
import NetworkError from "../../../tk/error/NetworkError";
import {filterEmptyAttributes} from "./AttributesDecorator";
import {emptyEntityRef} from "../../../lib/entityUtils";

export const getDataMatrixContext = (datasetConfig, config) => {
    const series = datasetConfig.series[config.dataSeries.id];
    if (!series) return {title: "No series"};

    const parameter = datasetConfig.parameter[series.parameter.id];
    if (!parameter) return {title: "No parameter"}
    const configField = datasetConfig.fields[config.datasetConfigField.id];

    const title = configField !== undefined ? configField.name.replace('$', parameter.abbreviation) : parameter.abbreviation;
    const format = config.format || parameter.format;
    const dataTypeId = configField !== undefined && configField.dataType.id !== null ? configField.dataType.id :
        series.dataType.id !== null ? series.dataType.id :
            parameter.dataType.id;
    const dataTypeIdForConfigOptions = dataTypeId;
    const unit = !configField || configField.unit === '$' ? parameter.unit : configField.unit;

    const options = formatOptions[dataTypeId].map(v => ({label: v, value: v}));
    const availableConfigs = getAvailableConfigs(parameter.dataType.id, datasetConfig);
    return {series, title, format, dataTypeId, dataTypeIdForConfigOptions, unit, options, availableConfigs};
}

export const getDataMatrixContext2 = (datasetConfig, configRow, parameters) => {
    const series = datasetConfig.series[configRow.dataSeries.id];

    const parameter = parameters[configRow.parameter.id];
    if (!parameter) return {title: "No parameter"}
    const configField = datasetConfig.fields[configRow.datasetConfigField.id];

    const title = configField !== undefined ? configField.name.replace('$', parameter.abbreviation) : parameter.abbreviation;
    const format = configRow.format || parameter.format;
    const dataTypeId = configField !== undefined && configField.dataType.id !== null ? configField.dataType.id :
        series !== undefined && series.dataType.id !== null ? series.dataType.id :
            parameter.dataType.id;
    const dataTypeIdForConfigOptions = dataTypeId;
    const unit = !configField || configField.unit === '$' ? parameter.unit : configField.unit;

    const options = formatOptions[dataTypeId].map(v => ({label: v, value: v}));
    const availableConfigs = getAvailableConfigs(parameter.dataType.id, datasetConfig);
    return {
        series,
        title,
        format,
        dataTypeId,
        dataTypeIdForConfigOptions,
        unit,
        options,
        availableConfigs
    };
}

export const toMatrixConfigs = (configs, datasetConfig) => {
    if (!configs) return [];

    return configs.map(config => {
        const {
            series,
            title,
            format,
            dataTypeId,
            dataTypeIdForConfigOptions,
            unit
        } = getDataMatrixContext(datasetConfig, config);

        if (series) {
            return {
                title,
                series,
                dataSeries: {
                    ...config.dataSeries,
                    instanceId: randomId()
                },
                datasetConfigField: config.datasetConfigField,
                format,
                parameter: series.parameter,
                method: series.method,
                comment: series.comment,
                staffs: series.staffs,
                dataTypeId,
                dataTypeIdForConfigOptions,
                unit
            }
        } else {
            return {
                title,
                series: {id: randomId(), dummy: true},
                dataSeries: {
                    id: randomId(),
                    instanceId: randomId(),
                    dummy: true
                },
                datasetConfigField: config.datasetConfigField,
                format,
                parameter: {id: 500000, commonName: "Event"},
                method: {id: null},
                comment: "",
                staffs: {id: null},
                dataTypeId,
                dataTypeIdForConfigOptions,
                unit
            }
        }
    });
}

const DatasetDecorator = (props) => {
    const {entity, entityDef, batchEntity, deleteEntity, createEntity, loadEntity, children} = props;
    const [datasetConfig, loading, configError] = useDatasetConfig(entity.series);
    const {onDirtyChange} = useContext(ContainerContext);

    useEffect(() => {
            if (loading) {
                onDirtyChange(false);
            }
        },
        [onDirtyChange, loading]
    );

    if (loading) {
        return <div>Loading ...</div>
    }

    if (configError) {
        return <NetworkError {...configError}/>
    }

    const makeEntityPlus = (entity) => {
        const fixedReferences = entity.references.map(ref => {
            const ref2 = {...ref}
            if (ref2.comment === null) {
                ref2.comment = ''
            }
            return ref2
        })
        const fixedDatasets = entity.datasets.map(ref => {
            const ref2 = {...ref}
            if (ref2.comment === null) {
                ref2.comment = ''
            }
            return ref2
        })
        return ({
            ...entity,
            references: fixedReferences,
            datasets: fixedDatasets,
            datasetConfig: datasetConfig,
            matrixConfigs: toMatrixConfigs(entity.configs, datasetConfig)
        });
    };

    const entityPlus = makeEntityPlus(entity);

    return React.cloneElement(
        children,
        {
            entityDef,
            entity: entityPlus,
            createEntity,
            deleteEntity,
            loadEntity: () => loadEntity().then(makeEntityPlus),
            updateEntity: (entity, onSuccess, onFinish, onError) => {
                entity.attributes = filterEmptyAttributes(entity.attributes);
                const batch = [
                    {
                        action: "PUT",
                        entityType: "dataset",
                        datasetEntity: {
                            ...entity,
                            datasetConfig: undefined,
                            matrixConfigs: undefined,
                            configs: entity.matrixConfigs.map(matrixConfig => ({
                                    dataSeries: matrixConfig.dataSeries.dummy ? {id: null} : matrixConfig.dataSeries,
                                    datasetConfigField: matrixConfig.datasetConfigField,
                                    format: matrixConfig.format
                                })
                            )
                        }
                    },
                    ...entity.matrixConfigs
                        .filter(matrixConfig => !matrixConfig.dataSeries.dummy)
                        .map(matrixConfig => ({
                            action: "PATCH",
                            entityType: "series",
                            entityRefs: [
                                matrixConfig.dataSeries || emptyEntityRef
                            ],
                            patches: [
                                {
                                    field: "parameter",
                                    ops: "SET",
                                    entityRefValue: matrixConfig.parameter
                                },
                                {
                                    field: "method",
                                    ops: "SET",
                                    entityRefValue: matrixConfig.method
                                },
                                {
                                    field: "staffs",
                                    ops: "SET",
                                    entityRefValue: matrixConfig.staffs
                                },
                                {
                                    field: "comment",
                                    ops: "SET",
                                    stringValue: matrixConfig.comment
                                }
                            ]
                        }))
                ];
                batchEntity(
                    batch,
                    entity => makeEntityPlus(entity),
                    onSuccess,
                    onFinish,
                    onError
                )
                // TODO: CATCH
            }
        }
    );
}

export default DatasetDecorator;

