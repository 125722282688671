import DetailBarButton from "../../../tk/forms/DetailBarButton";
import React, {useState} from "react";
import Modal from "antd/es/modal/Modal";
import JiraImportDialog from "./JiraImportDialog";
import PropTypes from "prop-types";


const JiraMetadataLookupButton = (props) => {
    const {disabled, ticket, onImport, datasetId} = props;
    const [open, setOpen] = useState(false);

    const handleImport = data => {
        setOpen(false);
        onImport(data);
    }

    return (
        <>
            <DetailBarButton
                disabled={disabled}
                onClick={() => setOpen(true)}
            >
                Import Submission
            </DetailBarButton>
            <Modal
                title="Submission Import"
                open={open}
                footer={null}
                width="80%"
                maskClosable={false}
                onCancel={() => setOpen(false)}
                destroyOnClose={true}
                styles={{ body: { paddingLeft: '0', paddingRight: '0', paddingBottom: '0' } }}
            >
                <JiraImportDialog
                    ticket={ticket}
                    onCancel={() => setOpen(false)}
                    onImport={handleImport}
                    datasetId={datasetId}
                />
            </Modal>
        </>
    );
}

JiraMetadataLookupButton.propTypes = {
    disabled: PropTypes.bool,
    ticket: PropTypes.string,
    onImport: PropTypes.func,
    datasetId: PropTypes.number
}

export default JiraMetadataLookupButton;
