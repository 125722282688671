import {Select} from "antd";
import {formStyle} from "../forms/formStyle";
import React, {forwardRef} from "react";

const {Option} = Select;


const SelectInput = forwardRef(
    ({onChange, name, value, overrideValue, options, disabled, allowClear, style}, ref) => {

        const filterOption = (input, option) => (option?.children ?? '')
            .toLowerCase()
            .includes(input.toLowerCase());

        return (
            <Select
                style={{width: "200px", ...formStyle, ...style}}
                value={overrideValue ? overrideValue : value !== undefined ? {
                    key: value.id,
                    value: value.id,
                    label: value.name
                } : {id: null, name: null}}
                onChange={v => {
                    let value;
                    if (v === undefined) {
                        value = {id: null, name: null};
                    } else {
                        value = options.find(o => o.id === v);
                    }
                    onChange(value);
                }}
                allowClear={(allowClear === undefined && overrideValue === undefined) ? true : allowClear}
                disabled={disabled}
                ref={ref}
                showSearch
                filterOption={filterOption}
                name={name}
            >
                {options.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
            </Select>
        );
    });

export default SelectInput;
