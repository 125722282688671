import NetworkError from "../../../tk/error/NetworkError";
import {Col, Descriptions, Popover, Row, Space, Typography} from "antd";
import {LoadingOutlined, QuestionCircleFilled} from "@ant-design/icons";
import StarredFilter from "../../../tk/bits/StarredFilter";
import QueryInput from "./searchbar/QueryInput";
import QuickFilter from "./searchbar/QuickFilter";
import React, {useContext, useEffect, useMemo, useState} from "react";
import TypingIcon from "../../../tk/bits/TypingIcon";
import MyError from "../../../tk/bits/MyError";
import MyOk from "../../../tk/bits/MyOk";
import useMyUser from "../../../hooks/useMyUser";
import useAutofocus from "../../../hooks/useAutofocus";
import {parse} from "@rsql/parser";
import {isNumeric} from "../../../lib/stringUtils";
import {ResponsiveContext} from "../../../tk/cards/ResponsiveArea";
import {slimTableWidthThreshold} from "./BigEntityTable";

const {Title} = Typography;


const RsqlQuickReference = () => (
    <Row>
        <Col>
            <Descriptions title="Logic Operators" column={1} size="small"
                          style={{width: "200px"}}>
                <Descriptions.Item label="and">and ;</Descriptions.Item>
                <Descriptions.Item label="or">or ,</Descriptions.Item>
                <Descriptions.Item label="priority">( )</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Comparison" column={1} size="small"
                          style={{width: "200px"}}>
                <Descriptions.Item label="equals">==</Descriptions.Item>
                <Descriptions.Item label="not equals">!=</Descriptions.Item>
                <Descriptions.Item label="bigger">&gt;</Descriptions.Item>
                <Descriptions.Item label="smaller">&lt;</Descriptions.Item>
                <Descriptions.Item label="bigger or equals">&gt;=</Descriptions.Item>
                <Descriptions.Item label="smaller or equals">&lt;=</Descriptions.Item>
                <Descriptions.Item label="in">=in=</Descriptions.Item>
            </Descriptions>
        </Col>
        <Col>
            <Title level={5}>Selectors</Title>
            <div>Use the table's headings as selectors. Remove whitespace and
                punctuation, e.g.<br/>Lon. 2 =&gt; lon2<br/>Time begin =&gt; timebegin<br/>...
            </div>
            <Descriptions title="Values" column={1} size="small"
                          style={{width: "500px"}}>
                <Descriptions.Item label="Entities">to filter for entities, you can
                    search for ID or their name</Descriptions.Item>
                <Descriptions.Item label="Wildcard">use *</Descriptions.Item>
                <Descriptions.Item label="Whitespace">use quotes to include whitespace,
                    e.g. "Hello World"</Descriptions.Item>
            </Descriptions>
        </Col>
    </Row>
)

export const mapRsql = (ast, columns) => {
    if (ast.type === 'LOGIC') {
        return '(' + mapRsql(ast.left, columns) + ' ' + ast.operator + ' (' + mapRsql(ast.right, columns) + '))'

    } else if (ast.type === 'COMPARISON') {
        if (Array.isArray(ast.right.value)) {
            return '(' + mapRsql(ast.left, columns) + ast.operator + '(' + mapRsql(ast.right, columns) + '))'
        }
        return '(' + mapRsql(ast.left, columns) + ast.operator + mapRsql(ast.right, columns) + ')'

    } else if (ast.type === 'VALUE') {
        if (ast.value.includes(' ') || ast.value === '') {
            return "'" + ast.value + "'"
        }
        return ast.value;

    } else if (ast.type === 'SELECTOR') {
        const selector = ast.selector.toLowerCase();
        if (selector === 'fulltext') {
            return "fulltext";
        }
        const col = columns.find(col => {
            return col.title.toLowerCase().replace(/[\s.()]/g, "") === selector;
        });
        if (!col) {
            // This is probably an error with the selector. There could be a proper error message.
            return ast.selector;
        }
        return col.dataIndex;

    } else {
        return "TODO";
    }
}

const BigTableHeader = ({entityDef, entityColumns, defaultRsql, setQuery, loading, error}) => {

    const [delayedInput, setDelayedInput] = useState(defaultRsql || "");
    const [rsqlError, setRsqlError] = useState(false);
    const [quickMineEnabled, setQuickMineEnabled] = useState(false);
    const [queryInputDirty, setQueryInputDirty] = useState(false);
    const {myUser} = useMyUser();
    const inputRef = useAutofocus(true, "");
    const cardContext = useContext(ResponsiveContext);

    let icon;
    if (queryInputDirty) {
        icon = <TypingIcon/>
    } else if (error || rsqlError) {
        icon = <MyError/>
    } else if (loading) {
        icon = <LoadingOutlined/>
    } else if (delayedInput.length === 0) {
        icon = <span/>
    } else {
        icon = <MyOk/>
    }


    useEffect(() => {
            const mapInputToQuery = (input, preparedColumns) => {
                const rsqlDetectors = ["==", "!=", ">", "<", "=in="];
                const rsqlDetected = rsqlDetectors
                    .map(detector => input.indexOf(detector) >= 0)
                    .reduce((previousValue, currentValue) => previousValue || currentValue, false);

                if (rsqlDetected) {
                    try {
                        const parsedQuery = parse(input.replace("~", "=ft="));
                        //console.log("PARSED QUERY:", parsedQuery);
                        const mappedQuery = mapRsql(parsedQuery, preparedColumns);
                        //console.log("MAPPED QUERY:", mappedQuery);
                        setRsqlError(false);
                        return mappedQuery;
                    } catch (e) {
                        console.log("RSQL PARSING ERROR", e);
                        setRsqlError(true);
                        return undefined;
                    }
                }

                if (isNumeric(input)) {
                    return "(id==" + input + ")"
                }

                return "(fulltext=ft='" + input + "')";
            };

            const buildQuery = (quickMineEnabled, myUser, delayedInput, columns) => {
                let newFilterString = '';
                if (quickMineEnabled) {
                    newFilterString += "(userCreated=='" + myUser.id + "')";
                }
                if (delayedInput.length > 0) {
                    if (newFilterString.length > 0) {
                        newFilterString += ";";
                    }
                    const mappedQuery = mapInputToQuery(delayedInput, columns);
                    newFilterString += "(" + mappedQuery + ")";
                }
                return newFilterString;
            }

            const q = buildQuery(quickMineEnabled, myUser, delayedInput, entityColumns);
            setQuery(q);
        },
        [delayedInput, entityColumns, myUser, quickMineEnabled, setQuery]
    );

    const showMineFilter = useMemo(() => {
            const n = entityColumns
                .filter(col => col.dataIndex === 'userCreated')
            return n.length > 0;
        },
        [entityColumns]
    );

    function getLabel() {
        return (
            <Space>
                <Popover
                    trigger="click"
                    title="RSQL quick reference"
                    content={<RsqlQuickReference/>}
                >
                    <div style={{cursor: "pointer"}}>RSQL&nbsp;Filter <QuestionCircleFilled/></div>
                </Popover>
                {delayedInput.length > 0 &&
                    <StarredFilter entityDef={entityDef} rsql={delayedInput}/>
                }
                {icon}
            </Space>
        );
    }

    function getQueryInput() {
        return <QueryInput
            inputRef={inputRef}
            backgroundColor={entityDef.bgColor}
            value={delayedInput}
            onChange={setDelayedInput}
            error={(rsqlError || error) !== undefined}
            onDirtyChange={setQueryInputDirty}
        />;
    }

    function getQuickFilter() {
        return <QuickFilter
            text="Mine"
            isEnabled={quickMineEnabled}
            setIsEnabled={setQuickMineEnabled}
        />;
    }

    if (cardContext.dimensions.width < slimTableWidthThreshold) {
        return (<>
            {error &&
                <NetworkError code={error.code} message={error.message}/>
            }
                <div style={{width: "100%", backgroundColor: entityDef.bgColor, padding: "2px"}}>
                    <Row align="middle" gutter={[10, 0]} justify="space-between">
                        <Col flex="160px">
                            {getLabel()}
                        </Col>
                        {showMineFilter &&
                            <Col flex="50px">
                                {getQuickFilter()}
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col flex="auto">
                            {getQueryInput()}
                        </Col>
                    </Row>
                </div>
        </>)
    }

    return (<>
        {error &&
            <NetworkError code={error.code} message={error.message}/>
        }
        <div style={{width: "100%", backgroundColor: entityDef.bgColor, padding: "2px"}}>
            <Row align="middle" gutter={[10, 0]} justify="space-between">
                <Col flex="160px">
                    {getLabel()}
                </Col>
                <Col flex="auto">
                    {getQueryInput()}
                </Col>
                {showMineFilter &&
                    <Col flex="50px">
                        {getQuickFilter()}
                    </Col>
                }
            </Row>
        </div>
    </>)
}

export default BigTableHeader;
