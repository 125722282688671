import React, {useContext} from "react";
import EntityHeader from "../entities/EntityHeader";
import FormBackground from "../../../tk/forms/FormBackground";
import UsagesDisplay from "./UsagesDisplay";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";
import {entityFields, renderField} from "../entities/entityFields";
import ButtonsInHeader from "../entities/ButtonsInHeader";
import RorLookupButton from "../rorlookup/RorLookupButton";


const InstitutionDetail = (props) => {
    const {entity, entityDef} = props;

    const {formState, setValue, watch} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);
    const watchName = watch("name")
    const watchAbbreviation = watch("abbreviation")
    const watchCity = watch("city")
    const watchCountry = watch("country")

    const makeAffiliation = () => {
        let aff = watchName
        const abbreviation = watchAbbreviation
        if (abbreviation) {
            aff += ", " + abbreviation
        }
        const city = watchCity
        if (city) {
            aff += ", " + city
        }
        const country = watchCountry
        if (country) {
            aff += ", " + country
        }
        return aff;
    }

    const handleImport = data => {
        if (data.fundRef) {
            setValue("crossrefFunderId", data.fundRef, {shouldDirty: true})
        }
        if (data.rorId) {
            setValue("ror", data.rorId, {shouldDirty: true})
        }
        if (data.name) {
            setValue("name", data.name, {shouldDirty: true})
        }
        if (data.country) {
            setValue("country", data.country, {shouldDirty: true})
        }
        if (data.city) {
            setValue("city", data.city, {shouldDirty: true})
        }
        if (data.state) {
            setValue("state", data.state, {shouldDirty: true})
        }
    }

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <ButtonsInHeader style={{marginTop: '8px'}}>
                <RorLookupButton
                    query={makeAffiliation()}
                    onImport={handleImport}
                >
                    ROR Lookup
                </RorLookupButton>
            </ButtonsInHeader>
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["name", "abbreviation", "city", "contact", "country", "fax", "phone"].map(
                        name => renderField(entityFields.institution.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["state", "street", "uri", "crossrefFunderId", "institutionType", "ror", "keywords"].map(
                        name => renderField(entityFields.institution.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2}>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default InstitutionDetail;
