import FormExt, { FormExtContext } from '../../../tk/forms/FormExt';
import React, { useContext, useMemo, useState } from 'react';
import { nextInstanceId } from '../EditorialApp';
import ResponsiveArea from '../../../tk/cards/ResponsiveArea';
import EntityDetailLoader from './EntityDetailLoader';
import Color from 'color';
import DetailButtons from '../../../tk/forms/DetailButtons';
import { deleteTab } from '../../../lib/flexlayoutTabTools';
import CardColorBackground from '../../../tk/cards/CardColorBackground';
import { Button, Space, Spin } from 'antd';
import DetailButtonsCreate from '../../../tk/forms/DetailButtonsCreate';
import ContainerContext from '../../../contexts/ContainerContext';
import FlexLayoutContext from '../../../contexts/FlexLayoutContext';
import NotificationsContext from '../../../contexts/NotificationsContext';
import GenericCreateFooter from '../../../tk/bits/GenericCreateFooter';
import { handleApplicationError } from '../../../lib/errorHandler';
import { detailComponentFactory } from './componentFactories';
import { submitWithDuplicationWarning } from '../../../lib/duplicateCheck';
import useWS2Axios from '../../../hooks/useWS2Axios';
import { setStar } from '../../../features/stars/entityStarsSlice';
import { useDispatch } from 'react-redux';


export const EntityFormContext = React.createContext({});

const MyEntityForm = (props) => {
    const {
        entity,
        entityDef,
        updateEntity,
        deleteEntity,
        reloadEntity,
        createEntity,
        setGlobalErrors,
        entityId,
        loadEntity
    } = props;
    const {
        handleSubmit,
        setError,
        reset,
        clearErrors,
        isSubmitting,
        setIsSubmitting,
        formState
    } = useContext(FormExtContext);
    const { notifyError, notifyEntityCreateSuccess } = useContext(NotificationsContext);
    const { closeContainer } = useContext(ContainerContext);
    const { addEntityDetailTab } = useContext(FlexLayoutContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [entityRefList, setEntityRefList] = useState([]);
    const { ws2Axios } = useWS2Axios();

    const onDefaultSubmit = (data, onSuccess) => {
        return updateEntity(
            data,
            entity => {
                if (onSuccess) onSuccess();
                return reset(entity);
            },
            () => setIsSubmitting(false),
            error => handleApplicationError(error, setError, setGlobalErrors)
        );
    };
    const handleOkDuplicate = () => {
        setIsModalOpen(false);
        handleEntitySubmit({ isDefaultSubmit: true });
        //by default never closes editing tab,
    };
    const handleCancelDuplicate = () => {
        setIsModalOpen(false);
        setIsSubmitting(false);
    };
    const duplicationWarning = (duplicateList) => {
        setEntityRefList(duplicateList);
        setIsModalOpen(true);
    };
    const handleEntitySubmit = ({ onSuccess, isDefaultSubmit }) => {
        clearErrors();
        setIsSubmitting(true);
        handleSubmit(data => {
            // This could be used to send patches
            /*
                for (var prop in data) {
                    if (Object.prototype.hasOwnProperty.call(data, prop)) {
                        if (!formState.dirtyFields[prop] && prop !== 'me') {
                            delete data[prop];
                        }
                    }
                }
                console.log('Removed unchanged fields: ', data);
                */
                if (entityDef?.findDuplicates?.fromInputToAPIQuery && !isDefaultSubmit) {
                    submitWithDuplicationWarning(
                        data,
                        (matches) => duplicationWarning(matches),
                        () => onDefaultSubmit(data, onSuccess),
                        ws2Axios,
                        entityDef,
                        entityId);
                } else {
                    onDefaultSubmit(data, onSuccess);
                }
            },
            (errors, e) => {
                console.log('ERRRRROROROORORO', errors, e);
                notifyError(entityDef.label + ' validation errors. Update cancelled.');
                setIsSubmitting(false);
            })()
            .catch(e => {
                console.log('ERROR HERE', e);
                setIsSubmitting(false);
            });
    };

    const handleEntityCreate = (e, onSuccess) => {
        clearErrors();
        setIsSubmitting(true);
        handleSubmit(data => {
                createEntity(
                    data,
                    entity => {
                        if (onSuccess) onSuccess(entity);
                        return reset(entity);
                    },
                    () => setIsSubmitting(false),
                    error => handleApplicationError(error, setError, setGlobalErrors)
                );
            },
            (errors, e) => {
                console.log('ERRRRROROROORORO', errors, e);
                notifyError(entityDef.label + ' validation errors. Update cancelled.');
                setIsSubmitting(false);
            })(e)
            .catch(e => {
                console.log('ERROR HERE', e);
                setIsSubmitting(false);
            });
    };

    const handleEntityCreateAndOpen = e => {
        handleEntityCreate(
            e,
            newEntity => {
                //console.log("SUCCESS", newEntity, entityDef);
                addEntityDetailTab(newEntity.me, entityDef);
                closeContainer();
                notifyEntityCreateSuccess(newEntity.me, entityDef);
            }
        );
    };

    return (
        <>
            {entityId && entityDef.formButtonsOnTop &&
                <DetailButtons
                    isDirty={formState.isDirty}
                    onSubmit={() => handleEntitySubmit({ isDefaultSubmit: false })}
                    onSubmitAndClose={() => handleEntitySubmit({
                        onSuccess: () => closeContainer(),
                        isDefaultSubmit: false
                    })}
                    onRevert={() => reset()}
                    onDelete={entityDef.noDeleteEntity ? undefined : () => deleteEntity(entity.me.id)}
                    renderItem={() =>
                        <EntityFormContext.Provider value={{ entityId }}>
                            <GenericCreateFooter
                                entityDef={entityDef}
                                entityRefList={entityRefList}
                                isModalOpen={isModalOpen}
                                handleOkDuplicate={handleOkDuplicate}
                                handleCancelDuplicate={handleCancelDuplicate}
                                loading={isSubmitting}
                                renderItem={() =>
                                    <Space>
                                        <Button
                                            type="primary"
                                            loading={isSubmitting}
                                            onClick={
                                                () => handleEntitySubmit({ isDefaultSubmit: false })
                                            }
                                            disabled={!formState.isDirty}
                                        >Submit</Button>
                                        <Button
                                            type="primary"
                                            loading={isSubmitting}
                                            onClick={
                                                () => handleEntitySubmit({
                                                    onSuccess: () => closeContainer(),
                                                    isDefaultSubmit: false
                                                })
                                            }
                                            disabled={!formState.isDirty}
                                        >Submit & Close</Button>
                                    </Space>
                                }
                            />
                        </EntityFormContext.Provider>
                    }
                />
            }
            <Spin spinning={isSubmitting}>
                <EntityFormContext.Provider value={{
                    handleEntitySubmit
                }}
                >
                    {React.cloneElement(detailComponentFactory(entityDef.entityType), {
                        entity,
                        entityDef,
                        loadEntity
                    })}
                </EntityFormContext.Provider>
            </Spin>
            {entityId &&
                <DetailButtons
                    isDirty={formState.isDirty}
                    onSubmit={handleEntitySubmit}
                    onSubmitAndClose={() => undefined}
                    onRevert={() => reset()}
                    onDelete={entityDef.noDeleteEntity ? undefined : () => deleteEntity(entity.me.id)}
                    onReload={reloadEntity}
                    renderItem={() => (
                        <EntityFormContext.Provider value={{ entityId }}>
                            <GenericCreateFooter
                                entityDef={entityDef}
                                entityRefList={entityRefList}
                                isModalOpen={isModalOpen}
                                handleOkDuplicate={handleOkDuplicate}
                                handleCancelDuplicate={handleCancelDuplicate}
                                loading={isSubmitting}
                                renderItem={() =>
                                    <Space>
                                        <Button
                                            type="primary"
                                            loading={isSubmitting}
                                            onClick={
                                                () => handleEntitySubmit({ isDefaultSubmit: false })
                                            }
                                            disabled={!formState.isDirty}
                                        >Submit</Button>
                                        <Button
                                            type="primary"
                                            loading={isSubmitting}
                                            onClick={
                                                () => handleEntitySubmit({
                                                    onSuccess: () => closeContainer(),
                                                    isDefaultSubmit: false
                                                })
                                            }
                                            disabled={!formState.isDirty}
                                        >Submit & Close</Button>
                                    </Space>
                                }
                            />
                        </EntityFormContext.Provider>
                    )}
                />
            }
            {!entityId &&
                <DetailButtonsCreate
                    isDirty={formState.isDirty}
                    onSubmit={handleEntityCreateAndOpen}
                    onSubmitAndClose={handleEntityCreate}
                    onRevert={() => reset()}
                />
            }
        </>
    );
};

export const Loaded = (props) => {
    const { entity, entityId, entityInit, entityDef, updateEntity, deleteEntity, createEntity, loadEntity } = props;
    const [globalErrors, setGlobalErrors] = useState([]);
    const instanceId = useMemo(
        () => nextInstanceId(),
        []
    );
    return (
        <FormExt
            defaultEntity={entity}
            entityInit={entityInit}
            updateEntity={(e) => {
                console.log('UPDATE ENTITY', e);
            }}
            instanceId={instanceId}
            globalErrors={globalErrors}
            setGlobalErrors={setGlobalErrors}
        >
            <MyEntityForm
                entity={entity}
                entityDef={entityDef}
                updateEntity={updateEntity}
                deleteEntity={deleteEntity}
                createEntity={createEntity}
                setGlobalErrors={setGlobalErrors}
                entityId={entityId}
                loadEntity={loadEntity}
            />
        </FormExt>
    );
};

const EntityEditor = (props) => {
    const { entityId, entityDef, model, tabId } = props;
    const cardColor = Color(entityDef.bgColor).lighten(0.2);
    const dispatch = useDispatch();
    return (
        <ResponsiveArea>
            <CardColorBackground color={cardColor}>
                <EntityDetailLoader
                    entityDef={entityDef}
                    entityId={entityId}
                    onDelete={() => {
                        deleteTab(model, tabId);
                        dispatch(setStar({
                            entityRef: { id: entityId, commonName: null },
                            entityType: entityDef.entityType,
                            isStarred: false
                        }));
                    }}
                >
                    <Loaded
                        entityId={entityId}
                    />
                </EntityDetailLoader>
            </CardColorBackground>
        </ResponsiveArea>
    );
};

export default EntityEditor;
