import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    starStore: {
        staffs: [{name: 'Hello redux', id: 10}]
    }
}

const removeStarEntry = (entityRef, entityType, newStore) => {
    const existingIndex = newStore[entityType].findIndex(e => e.id === entityRef.id);
    if (existingIndex >= 0) {
        newStore[entityType].splice(existingIndex, 1);
    }
}

const clearStarEntries = (entityType, newStore) => {
    newStore[entityType] = [];
}

const entityStarsSlice = createSlice({
        name: 'entityStars',
        initialState,
        reducers: {
            setStar(state, action) {
                const {isStarred, entityType, entityRef} = action.payload;

                if (isStarred) {
                    if (!state.starStore[entityType]) {
                        state.starStore[entityType] = [entityRef];
                    } else {
                        removeStarEntry(entityRef, entityType, state.starStore);
                        state.starStore[entityType].unshift(entityRef);
                    }

                } else {
                    if (state.starStore[entityType]) {
                        removeStarEntry(entityRef, entityType, state.starStore);
                    }
                }
            },
            clearStars(state, action) {
                const {entityType} = action.payload;
                clearStarEntries(entityType, state.starStore);
            }
        }
    }
);

export const isStarred = (starStore, entityRef, entityType) => {
    if (!starStore[entityType]) return false;
    return starStore[entityType].findIndex(e => e.id === entityRef.id) >= 0;
}

export const {setStar, clearStars} = entityStarsSlice.actions;
export default entityStarsSlice.reducer;
