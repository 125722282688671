import React, {useContext} from "react";
import entityDefs from "../../pages/editorial/entities/entityDefs";
import TableField2 from "./TableField2";
import {renderEntityRef} from "../../pages/test/TestPage2";
import SelectField from "../input/SelectField";
import useEnumType from "../../hooks/useEnumType";
import useMyUser from "../../hooks/useMyUser";
import {FormExtContext} from "../forms/FormExt";


const RelatedTermsTableField = ({paramName, relationTypes, termDataIndex, terminologyDataIndex}) => {
    const termRelationTypes = useEnumType(relationTypes);
    const {myUser} = useMyUser();
    const {watch} = useContext(FormExtContext);
    const addedIds = watch(paramName)?.map(entry => entry[termDataIndex].id)

    return (
        <TableField2
            filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
            paramName={paramName}
            entityDef={entityDefs.term}
            columns={[
                {
                    title: 'Relation type',
                    dataIndex: 'relationType',
                    width: 130,
                    render: (entityRef, _, index) =>
                        <SelectField
                            paramName={paramName + '.' + index + '.relationType'}
                            values={termRelationTypes}
                            style={{width: '155px'}}
                        />,
                    multiEdit: 'type',
                    multiEditOptions: termRelationTypes
                },
                {
                    title: 'ID',
                    dataIndex: termDataIndex,
                    width: 80,
                    align: 'right',
                    render: entityRef => entityRef?.id
                },
                {
                    title: 'Term name',
                    dataIndex: termDataIndex,
                    width: 270,
                    indexColumn: true,
                    render: renderEntityRef(entityDefs.term)
                },
                {
                    title: 'ID',
                    dataIndex: terminologyDataIndex,
                    width: 60,
                    align: 'right',
                    render: entityRef => entityRef?.id
                },
                {
                    title: 'Terminology name',
                    dataIndex: terminologyDataIndex,
                    width: 270,
                    //indexColumn: true,
                    render: renderEntityRef(entityDefs.terminology)
                },
            ]}
            newRowFn={entityRef => {
                const newRow = {
                    relationType: {
                        id: 1,
                        commonName: 'has broader term'
                    },
                    userCreated: {
                        id: myUser.id,
                        commonName: myUser.commonName
                    },
                    userUpdated: {
                        id: null,
                        commonName: null
                    }
                };
                newRow[termDataIndex] = entityRef;
                return newRow;
            }}
        />
    )
}

export default RelatedTermsTableField;
