import { Alert } from 'antd';
import EntityTagList from '../bits/EntityTagList';
import React from 'react';

const DuplicateAlert = ({ matches, entityDef, excludeReferences }) => {

    let existingMatches = [];
    if (matches) {
        console.log('EXISTING: ', matches);
        console.log('EXCLUDE: ', excludeReferences);
        existingMatches = matches
            .map((item) => ({
                id: item.id,
                name: item.name
            }))
            .filter(match => excludeReferences !== match.id);
    }

    if (existingMatches.length > 0) {
        return (
            <>
                <Alert
                    message="Potential existing matches found"
                    description={<EntityTagList entityDef={entityDef} list={existingMatches} />}
                    type="warning"
                    showIcon
                />
                <br />
            </>
        );
    } else {
        return undefined;
    }
};

export default DuplicateAlert;
