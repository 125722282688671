import {
    collectionTypeColumn,
    createUpdateColumns,
    curationLevelColumn,
    datasetStatusColumn,
    dataTypeColumn,
    dateColumn,
    dateTimeColumn,
    ellipsisColumn,
    entityRefColumn,
    entityRefListColumn,
    floatColumn,
    institutionTypeColumn,
    integerColumn,
    labelColumn,
    loginOptionColumn,
    meColumn,
    orcidColumn,
    processingLevelColumn,
    projectTypeColumn,
    referenceStatusColumn,
    referenceTypeColumn,
    termCategoryColumn,
    termStatusColumn,
    ticketColumn,
    titleColumn,
    topotypeColumn,
    uriColumn
} from "./entityDefTools";

import entityDefs from "./entityDefs";


const entityColumns = {

    award: () => ({
        columns: [
            meColumn("me", entityDefs.award),
            titleColumn("title", "Title"),
            labelColumn("awardNumber", "Award number"),
            entityRefColumn("institution", "Funder", entityDefs.institution),
            entityRefColumn("project", "Project", entityDefs.project),
            uriColumn("uri", "URI"),
            ellipsisColumn("comment", "Comment"),
            entityRefListColumn("datasetsReverse", "Datasets", entityDefs.dataset),
            ...createUpdateColumns()
        ]
    }),
    basis: () => ({
        columns: [
            meColumn("me", entityDefs.basis),
            labelColumn("name", "Name"),
            labelColumn("callsign", "Callsign"),
            labelColumn("imoNumber", "IMO number"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            uriColumn("uri", "URI"),
            entityRefListColumn("campaignsReverse", "Campaigns", entityDefs.campaign),
            ...createUpdateColumns()
        ]
    }),
    campaign: () => ({
        columns: [
            meColumn("me", entityDefs.campaign),
            labelColumn("name", "Name"),
            labelColumn("nameOptional", "Name optional"),
            dateColumn("datetimeBegin", "Date begin"),
            dateColumn("datetimeEnd", "Date end"),
            uriColumn("uri", "URI"),
            entityRefListColumn("staffs", "Staffs", entityDefs.staffs),
            entityRefColumn("basis", "Basis", entityDefs.basis),
            entityRefColumn("project", "Project", entityDefs.project),
            entityRefColumn("reference", "Reference", entityDefs.reference),
            ellipsisColumn("comment", "Comment"),
            entityRefListColumn("eventsReverse", "Events", entityDefs.event),
            ...createUpdateColumns()
        ]
    }),
    dataset: () => ({
        columns: [
            meColumn("me", entityDefs.dataset),
            titleColumn("title", "Title"),
            entityRefListColumn("staffs", "Author(s)", entityDefs.staffs, entry => entry.staffs),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            datasetStatusColumn("datasetStatus", "Status"),
            dateColumn("doiRegistered", "DOI registered"),
            loginOptionColumn("loginOption", "Protection"),
            entityRefColumn("parentDataset", "Parent", entityDefs.dataset),
            entityRefColumn("license", "License", entityDefs.license, 150),
            dateTimeColumn("moratoriumUntil", "Morat. until"),
            dateTimeColumn("datetimePublication", "Pub. date"),
            curationLevelColumn("curationLevel", "Curation level"),
            processingLevelColumn("processingLevel", "Process. level"),
            ticketColumn("ticket", "Ticket"),
            ellipsisColumn("abstractText", "Abstract"),
            topotypeColumn("topotype", "Topotype"),
            collectionTypeColumn("collectionType", "Collection type"),
            ellipsisColumn("filename", "Filename"),
            ellipsisColumn("comment", "Comment"),
            entityRefColumn("parentDataset", "Parent", entityDefs.dataset),
            entityRefListColumn("awards", "Awards", entityDefs.award),
            entityRefListColumn("projects", "Projects", entityDefs.project),
            entityRefListColumn("events", "Events", entityDefs.event),
            entityRefListColumn("keywords", "Keywords", entityDefs.keyword),
            entityRefListColumn("groupAccess", "Group access", entityDefs.panGroup),
            entityRefListColumn("userAccess", "User access", entityDefs.panUser),
            entityRefListColumn("references", "References", entityDefs.reference, entry => entry.reference),
            entityRefListColumn("datasets", "Datasets", entityDefs.dataset, entry => entry.datasetRelated),
            entityRefListColumn("attributes", "Attributes", entityDefs.term, entry => entry.term),
            entityRefListColumn("series", "Series", entityDefs.series),
            ...createUpdateColumns()
        ],
        defaultFilters: {'datasetStatus': ['draft', 'in review', 'published', 'questionable', 'validated']},
    }),
    event: () => ({
        columns: [
            meColumn("me", entityDefs.event),
            labelColumn("label", "Label"),
            labelColumn("labelOptional", "Label optional"),
            dateTimeColumn("datetime", "Time begin"),
            dateTimeColumn("datetime2", "Time end"),
            floatColumn("longitude", "Lon."),
            floatColumn("longitude2", "Lon. 2"),
            floatColumn("latitude", "Lat."),
            floatColumn("latitude2", "Lat. 2"),
            floatColumn("elevation", "Ele."),
            floatColumn("elevation2", "Ele. 2"),
            entityRefColumn("campaign", "Campaign", entityDefs.campaign),
            entityRefColumn("project", "Project", entityDefs.project),
            entityRefColumn("termLocation", "Location", entityDefs.location),
            entityRefColumn("method", "Method", entityDefs.method),
            uriColumn("uri", "URI"),
            ellipsisColumn("comment", "Comment"),
            entityRefListColumn("datasetsReverse", "Datasets Extra", entityDefs.dataset),
            ...createUpdateColumns()
        ]
    }),
    funder: () => ({
        columns: [
            meColumn("me", entityDefs.funder),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            uriColumn("ror", "ROR"),
            uriColumn("uri", "URI"),
            uriColumn("crossrefFunderId", "Crossref Funder ID"),
            ellipsisColumn("contact", "Contact"),
            ellipsisColumn("country", "Country"),
            ellipsisColumn("state", "State"),
            ellipsisColumn("city", "City"),
            ellipsisColumn("street", "Street"),
            ellipsisColumn("phone", "Phone"),
            ellipsisColumn("fax", "Fax"),
            ...createUpdateColumns()
        ]
    }),
    institution: () => ({
        columns: [
            meColumn("me", entityDefs.institution),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            institutionTypeColumn("institutionType", "Institution type"),
            uriColumn("ror", "ROR"),
            uriColumn("uri", "URI"),
            uriColumn("crossrefFunderId", "Crossref Funder ID"),
            ellipsisColumn("contact", "Contact"),
            ellipsisColumn("country", "Country"),
            ellipsisColumn("state", "State"),
            ellipsisColumn("city", "City"),
            ellipsisColumn("street", "Street"),
            ellipsisColumn("phone", "Phone"),
            ellipsisColumn("fax", "Fax"),
            entityRefListColumn("journalsReverse", "Journals", entityDefs.journal),
            entityRefListColumn("panGroupsReverse", "Groups", entityDefs.panGroup),
            entityRefListColumn("staffsReverse", "Staffs", entityDefs.staffs),
            entityRefListColumn("basesReverse", "Bases", entityDefs.basis),
            entityRefListColumn("projectsReverse", "Projects", entityDefs.project),
            entityRefListColumn("awardsReverse", "Awards", entityDefs.award),
            entityRefListColumn("datasetsReverse", "Datasets", entityDefs.dataset),
            ...createUpdateColumns()
        ]
    }),
    journal: () => ({
        columns: [
            meColumn("me", entityDefs.journal),
            titleColumn("name", "Name"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            uriColumn("uri", "URI"),
            labelColumn("issn", "ISSN"),
            ellipsisColumn("discipline", "Discipline"),
            ellipsisColumn("periodicity", "Periodicity"),
            entityRefListColumn("referencesReverse", "References", entityDefs.reference),
            ...createUpdateColumns()
        ]
    }),
    keyword: () => ({
        columns: [
            meColumn("me", entityDefs.keyword),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            termCategoryColumn("termCategory", "Category"),
            termStatusColumn("termStatus", "Status"),
            entityRefColumn("terminology", "Terminology", entityDefs.terminology),
            ellipsisColumn("description", "Description"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
            ellipsisColumn("semanticUri", "Semantic URI"),
            ...createUpdateColumns()
        ]
    }),
    license: () => ({
        columns: [
            meColumn("me", entityDefs.license),
            labelColumn("label", "Label"),
            titleColumn("name", "Name"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
        ]
    }),
    location: () => ({
        columns: [
            meColumn("me", entityDefs.location),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            termCategoryColumn("termCategory", "Category"),
            termStatusColumn("termStatus", "Status"),
            entityRefColumn("terminology", "Terminology", entityDefs.terminology),
            ellipsisColumn("description", "Description"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
            ellipsisColumn("semanticUri", "Semantic URI"),
            ...createUpdateColumns()
        ]
    }),
    method: () => ({
        columns: [
            meColumn("me", entityDefs.method),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            ellipsisColumn("description", "Description"),
            uriColumn("uri", "URI"),
            entityRefColumn("reference", "Reference", entityDefs.reference),
            entityRefListColumn("parametersReverse", "Parameters", entityDefs.parameter),
            entityRefListColumn("eventsReverse", "Events", entityDefs.event),
            entityRefListColumn("seriesReverse", "Series", entityDefs.series),
            ...createUpdateColumns()
        ]
    }),
    panGroup: () => ({
        columns: [
            meColumn("me", entityDefs.panGroup),
            titleColumn("name", "Name"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            ellipsisColumn("comment", "Comment"),
            entityRefListColumn("panUsersReverse", "Users", entityDefs.panUser),
            ...createUpdateColumns()
        ]
    }),
    panUser: () => ({
        columns: [
            meColumn("me", entityDefs.panUser),
            labelColumn("username", "Username"),
            labelColumn("fullName", "Full name"),
            entityRefColumn("staffs", "Staffs", entityDefs.staffs),
            ellipsisColumn("email", "Email"),
            orcidColumn("orcid", "ORCID"),
            ellipsisColumn("phone", "Phone"),
            ellipsisColumn("institution", "Institution"),
            entityRefListColumn("groups", "Groups", entityDefs.panGroup),
            integerColumn("logins", "#Logins"),
            dateTimeColumn("lastLogin", "Last login"),
            dateTimeColumn("createdat", "Created at"),
        ]
    }),
    parameter: () => ({
        columns: [
            meColumn("me", entityDefs.parameter),
            titleColumn("name", "Name", "stringValue"),
            labelColumn("abbreviation", "Abbreviation", "stringValue"),
            ellipsisColumn("unit", "Unit", "stringValue"),
            ellipsisColumn("ucum", "UCUM", "stringValue"),
            dataTypeColumn("dataType", "Data type", "entityRef"),
            //TODO: add dataTypes to be able to batch edit dataTypeColumn
            ellipsisColumn("format", "Format", "stringValue"),
            floatColumn("rangeMin", "Rng. min.", "doubleValue"),
            floatColumn("rangeMax", "Rng. max.", "doubleValue"),
            entityRefColumn("method", "Method", entityDefs.method),
            entityRefColumn("reference", "Reference", entityDefs.reference),
            ellipsisColumn("description", "Description", "stringValue"),
            uriColumn("uri", "URI", "stringValue"),
            entityRefListColumn("terms", "Terms", entityDefs.term, entry => entry.term),
            entityRefListColumn("dataSeriesReverse", "Data Series", entityDefs.series),
            ...createUpdateColumns()
        ]
    }),
    project: () => ({
        columns: [
            meColumn("me", entityDefs.project),
            titleColumn("name", "Name"),
            labelColumn("label", "Label"),
            projectTypeColumn("projectType", "Project type"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            entityRefColumn("staffs", "Coordinator", entityDefs.staffs),
            uriColumn("uri", "URI"),
            uriColumn("uriData", "URI data"),
            ellipsisColumn("comment", "Comment"),
            entityRefListColumn("campaignsReverse", "Campaigns", entityDefs.campaign),
            entityRefListColumn("eventsReverse", "Events", entityDefs.event),
            entityRefListColumn("awardsReverse", "Awards", entityDefs.award),
            entityRefListColumn("datasetsReverse", "Datasets", entityDefs.dataset),
            ...createUpdateColumns()
        ]
    }),
    reference: () => ({
        columns: [
            meColumn("me", entityDefs.reference),
            titleColumn("title", "Title"),
            titleColumn("source", "Source"),
            entityRefListColumn("staffs", "Author(s)", entityDefs.staffs),
            integerColumn("year", "Year"),
            entityRefColumn("journal", "Journal", entityDefs.journal),
            ellipsisColumn("volume", "Volume"),
            ellipsisColumn("pages", "Pages"),
            uriColumn("uri", "URI"),
            referenceStatusColumn("referenceStatus", "Status"),
            referenceTypeColumn("referenceType", "Type"),
            entityRefListColumn("attachments", "Attachments", entityDefs.attachment, data => data.me),
            entityRefListColumn("parametersReverse", "Parameters", entityDefs.parameter),
            entityRefListColumn("methodsReverse", "Methods", entityDefs.method),
            entityRefListColumn("campaignsReverse", "Campaigns", entityDefs.campaign),
            entityRefListColumn("datasetsReverse", "Datasets", entityDefs.dataset),
            ...createUpdateColumns()
        ]
    }),
    series: () => ({
        columns: [
            meColumn("me", entityDefs.series),
            entityRefColumn("dataset", "Dataset", entityDefs.dataset),
            entityRefColumn("parameter", "Parameter", entityDefs.parameter),
            entityRefColumn("staffs", "PI", entityDefs.staffs),
            entityRefColumn("method", "Method", entityDefs.method),
            dataTypeColumn("dataType", "Data type"),
            ellipsisColumn("format", "Format"),
            integerColumn("nDataPoints", "#Datapoints"),
            ellipsisColumn("comment", "Comment"),
            ...createUpdateColumns()
        ]
    }),
    staffs: () => ({
        columns: [
            meColumn("me", entityDefs.staffs),
            labelColumn("nameLast", "Last name"),
            labelColumn("nameFirst", "First name"),
            ellipsisColumn("email", "Email"),
            orcidColumn("orcid", "ORCID"),
            entityRefColumn("institution", "Institution", entityDefs.institution),
            uriColumn("uri", "URI"),
            ellipsisColumn("phone", "Phone"),
            entityRefListColumn("users", "Users rev", entityDefs.panUser),
            // No index, super slow
            //entityRefListColumn("dataSeriesReverse", "Data Series rev", entityDefs.series),
            entityRefListColumn("projectsReverse", "Projects rev", entityDefs.project),
            entityRefListColumn("referencesReverse", "References rev", entityDefs.reference),
            entityRefListColumn("datasetsReverse", "Datasets rev", entityDefs.dataset),
            entityRefListColumn("campaignsReverse", "Campaigns rev", entityDefs.campaign),
            ...createUpdateColumns()
        ]
    }),
    term: () => ({
        columns: [
            meColumn("me", entityDefs.term),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            termCategoryColumn("termCategory", "Category"),
            termStatusColumn("termStatus", "Status"),
            entityRefColumn("terminology", "Terminology", entityDefs.terminology),
            ellipsisColumn("description", "Description"),
            ellipsisColumn("comment", "Comment"),
            uriColumn("uri", "URI"),
            ellipsisColumn("semanticUri", "Semantic URI"),
            ...createUpdateColumns()
        ]
    }),
    terminology: () => ({
        columns: [
            meColumn("me", entityDefs.terminology),
            titleColumn("name", "Name"),
            labelColumn("abbreviation", "Abbreviation"),
            ellipsisColumn("comment", "Comment"),
            ellipsisColumn("creator", "Creator"),
            ellipsisColumn("description", "Description"),
            uriColumn("uri", "URI"),
            ...createUpdateColumns()
        ]
    })
}

export default entityColumns;
