import * as FlexLayout from "flexlayout-react";
import React, {useCallback, useContext, useEffect, useState} from "react";
import entityDefs from "./entities/entityDefs";
import Color from "color";
import ResponsiveArea from "../../tk/cards/ResponsiveArea";
import CardColorBackground from "../../tk/cards/CardColorBackground";
import EntityEditor, {Loaded} from "./entities/EntityEditor";
import SplashScreen from "./splash/SplashScreen";
import FourthDMenu from "./FourthDMenu";
import StarPage from "./StarPage";
import EntityTable from "./bigentitytable/EntityTable";
import EntityDetailLoader from "./entities/EntityDetailLoader";
import {addEntityDetailTab, deleteTab} from "../../lib/flexlayoutTabTools";
import JiraDirectImport from "./dataset/JiraDirectImport";
import ContainerContext from "../../contexts/ContainerContext";
import NotificationsContext from "../../contexts/NotificationsContext";
import DatafileDirectImport from "./dataset/DatafileDirectImport";
import ListFileImporter from "./listimport/ListFileImporter";
import ContainerContextWrapper from "../../contexts/ContainerContextWrapper";
import MixerPage from "./mixer/MixerPage";
import PDIBoardPage from "./board/PDIBoardPage";
import PMWBoardPage from "./board/PMWBoardPage";
import NetworkPage from "./network/NetworkPage";


const NewEntityTab = (props) => {
    const {entityDef, defaultEntity, initEntity} = props;
    const [newEntity, setNewEntity] = useState();
    const {notifyEntityCreateSuccess} = useContext(NotificationsContext);
    const {closeContainer} = useContext(ContainerContext);

    useEffect(() => {
            if (!newEntity) return;
            notifyEntityCreateSuccess(newEntity.me, entityDef);
            closeContainer();
        },
        [closeContainer, entityDef, newEntity, notifyEntityCreateSuccess]
    );

    return (
        <ResponsiveArea>
            <CardColorBackground color={Color.blue}>
                <EntityDetailLoader
                    entityDef={entityDef}
                    defaultEntity={defaultEntity}
                    onEntityCreated={setNewEntity}
                >
                    <Loaded
                        entity={defaultEntity}
                        entityInit={initEntity}
                    />
                </EntityDetailLoader>
            </CardColorBackground>
        </ResponsiveArea>
    );
}

const isDirtyInMap = (dirtyMap, tabId) => dirtyMap[tabId] !== undefined && dirtyMap[tabId] === true;

const EditorialFlexLayout = (props) => {
    const {refLayout, model, setModel, setIsUnsavedTabModalOpen, setIsUnsavedTabsetModalOpen} = props;
    const [cache, setCache] = useState({});
    const [dirtyMap, setDirtyMap] = useState({});

    const handleDirtyChange = useCallback((tabId, isDirty) => {
            setDirtyMap(prevMap => {
                const newDirtyMap = {
                    ...prevMap
                };
                newDirtyMap[tabId] = isDirty;
                return newDirtyMap;
            });
        },
        [setDirtyMap]
    );

    const onJiraImportDone = useCallback((newEntity, tabId) => {
            addEntityDetailTab(refLayout.current, newEntity.me, entityDefs.dataset);
            deleteTab(model, tabId);
        },
        [model, refLayout]
    );

    const makeComponent = useCallback((component, tabId) => {
            const entityDef = entityDefs[component.entityType];
            //const cardColor = entityDef ? Color(entityDef.bgColor).lighten(0.2) : "#eee";
            switch (component.type) {
                case "list":
                    return (
                        <EntityTable
                            refLayout={refLayout}
                            entityDef={entityDef}
                            defaultRsql={component.rsql}
                        />
                    );
                case "entity":
                    return (
                        <EntityEditor
                            entityDef={entityDef}
                            entityId={Number(component.entityId)}
                            model={model}
                            tabId={tabId}
                        />
                    );
                case "new":
                    return (
                        <NewEntityTab
                            entityDef={entityDef}
                            defaultEntity={component.defaultEntity}
                            initEntity={component.initEntity}
                            tabId={tabId}
                            model={model}
                            refLayout={refLayout}
                        />
                    )
                case "mixer":
                    return <MixerPage/>;
                case "board":
                    if (component.board === 'PDI') return <PDIBoardPage/>;
                    else return <PMWBoardPage/>;
                case "network":
                    return <NetworkPage entityId={component.entityId}/>
                case "splash":
                    return <SplashScreen/>;
                case "fourthdmenu":
                    return <FourthDMenu/>
                case "starPage":
                    return <StarPage/>
                case "jiraDirectImport":
                    return (
                        <div style={{margin: "16px"}}>
                            <JiraDirectImport
                                ticket={component.ticket}
                                initEntity={component.initEntity}
                                onDone={newEntity => onJiraImportDone(newEntity, tabId)}
                                tabId={tabId}
                            />
                        </div>
                    )
                case "datafileDirectImport":
                    return (
                        <div style={{margin: "16px"}}>
                            <DatafileDirectImport
                                initState={component.importState}
                            />
                        </div>
                    )
                case "listImport":
                    return (
                        <div style={{margin: "16px"}}>
                            <ListFileImporter
                                initState={component.importState}
                            />
                        </div>
                    )
                default:
                    return component;
            }
        },
        [model, onJiraImportDone, refLayout]
    );

    const factory = useCallback(node => {
            const component = node.getComponent();
            const tabId = node.attributes.id;

            if (cache[tabId]) {
                //console.log("factory from cache", tabId, component, node)
                return cache[tabId];
            }

            //console.log("factory to cache", tabId, component, node)
            const newCache = {...cache};

            newCache[tabId] = (
                <ContainerContextWrapper
                    model={model}
                    tabId={tabId}
                    onDirtyChange={handleDirtyChange}
                >
                    {makeComponent(component, tabId)}
                </ContainerContextWrapper>
            )

            // We need to set the state here in setTimeout to avoid the
            // "Cannot update a component while rendering a different component"
            // error
            setTimeout(() => {
                setCache(newCache);
            }, 0);
            return newCache[tabId];
        },
        [cache, handleDirtyChange, makeComponent, model]
    );

    const onAction = useCallback(action => {
            //console.log("ON ACTION", action);
            if (action.type === 'FlexLayout_DeleteTab') {
                const tabId = action.data.node;
                if (isDirtyInMap(dirtyMap, tabId)) {
                    setIsUnsavedTabModalOpen({
                        open: true,
                        tabId: tabId
                    });
                } else {
                    return action;
                }
            } else if (action.type === 'FlexLayout_DeleteTabset') {
                let tabsetId = action.data.node;
                let isAnyDirty;
                model.visitNodes((node, level) => {
                    if (node.attributes.id === tabsetId) {
                        isAnyDirty = node.children.reduce(
                            (previousValue, currentValue) => {
                                return isDirtyInMap(dirtyMap, currentValue.attributes.id) || previousValue
                            }, false
                        )
                    }
                })
                if (isAnyDirty) {
                    setIsUnsavedTabsetModalOpen({
                        open: true,
                        tabsetId: tabsetId
                    })
                } else {
                    return action;
                }
            } else {
                return action;
            }
        },
        [dirtyMap, model, setIsUnsavedTabModalOpen, setIsUnsavedTabsetModalOpen]
    );

    return (
        <FlexLayout.Layout
            ref={refLayout}
            model={model}
            onModelChange={setModel}
            factory={factory}
            realtimeResize={true}
            font={{size: "12px"}}
            onAction={onAction}
        />
    )
}

export default EditorialFlexLayout;
