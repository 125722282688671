import React from "react";
import {Col, Row} from "antd";

const MultiColumn = ({items, maxPerColumn}) => {
    const colNum = items.length / maxPerColumn + 1;
    const cols = [];
    for (let i = 0; i < colNum; i++) {
        cols.push(items.slice(i * maxPerColumn, (i + 1) * maxPerColumn));
    }

    return (
        <div style={{backgroundColor: "white"}}>
            <Row>
                {cols.map((col, i) => (
                    <Col key={i}>
                        {col.map((item, index) => (
                            <div key={index}>
                                {item}
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        </div>

    )
}

export default MultiColumn;