import {Button, Col, Collapse, Row, Space, Upload} from "antd";
import {DeleteOutlined, InboxOutlined, LoadingOutlined} from "@ant-design/icons";
import React, {useContext, useEffect, useState} from "react";
import {importMatrix, uploadFile} from "../../../lib/importFile";
import useWS2Axios from "../../../hooks/useWS2Axios";
import entityDefs from "../entities/entityDefs";
import MyOk from "../../../tk/bits/MyOk";
import MyError from "../../../tk/bits/MyError";
import ImportErrorDisplay from "../listimport/ImportErrorDisplay";
import FileSize from "../../../tk/bits/FileSize";
import DatasetImportStatus from "./DatasetImportStatus";
import ContainerContext from "../../../contexts/ContainerContext";
import EntityTag from "../../../tk/bits/EntityTag";
import FilterTag from "../../../tk/bits/FilterTag";


const DatafileDirectImport = (props) => {
    const {onChange, initState} = props;
    const {ws2Axios} = useWS2Axios();
    const [fileList, setFileList] = useState(initState?.fileList || []);
    const [isImporting, setIsImporting] = useState(initState?.isImporting || false);
    const [isImportingDone, setIsImportingDone] = useState(initState?.isImportingDone || false);
    const {closeContainer, onDirtyChange} = useContext(ContainerContext);

    useEffect(() => {
            if (onDirtyChange) {
                onDirtyChange(true);
            }
        },
        [onDirtyChange]
    );

    useEffect(() => {
            if (!onChange) return;
            onChange({fileList, isImporting, isImportingDone})
        },
        [fileList, isImporting, isImportingDone, onChange]
    );

    const readyForImport = fileList.length > 0 && fileList.every(value => value.status === "done");

    const handleImport = () => {
        setIsImporting(true);

        const importFileList = fileList.map((file, index, array) => {
            const idDataset = file.response.dataset.idDataset;
            importMatrix(
                ws2Axios,
                undefined,
                undefined,
                () => undefined,
                entityDefs.dataset.apiGet + "/" + idDataset + "/import2?hasData=" + file.response.hasData,
                file.response
            );
            return {
                ...file,
                idDataset
            }
        })
        setFileList(importFileList);

        setIsImporting(false);
        setIsImportingDone(true);
    };

    const onCancel = () => {
        onResetAll();
        closeContainer();
    }

    const onResetAll = () => {
        setFileList([]);
        setIsImporting(false);
        setIsImportingDone(false);
    }

    const rsql = "id=in=(" + fileList.reduce((acc, file) => acc + (acc === '' ? '' : ',') + file.idDataset, '') + ")";

    const itemRender = (originNode, file, fileList1, actions) => {
        const someError = file.error && file.error.errors.length > 0;

        let icon = <LoadingOutlined/>
        if (someError) icon = <MyError/>
        else if (file.status === "done" || file.status === "success") icon = <MyOk/>

        let errorStyle = {};
        if (someError) {
            errorStyle = {color: "red"}
        }

        return <Row align="middle">
            <Col span={1}>
                {icon}
            </Col>
            <Col span={9}>
                <span style={{height: "32px", marginTop: "6px", ...errorStyle}}>
                    {file.name} <small style={{color: "gray"}}>_____&nbsp;<FileSize
                    sizeInBytes={file.size}/></small>
                </span>
            </Col>
            <Col span={10}>
                {someError &&
                    <Collapse ghost items={[{
                        key: "1",
                        label: "Errors (" + file.error.errors.length + ")",
                        children: <ImportErrorDisplay result={file.error}/>
                    }]}/>
                }
                {!someError && !file.idDataset &&
                    <span>Ready for import</span>
                }
                {!someError && file.idDataset &&
                    <DatasetImportStatus idDataset={file.idDataset}/>
                }
            </Col>
            <Col span={2}>
                {file.idDataset &&
                    <EntityTag entityRef={{id: file.idDataset}} entityDef={entityDefs.dataset}/>
                }
            </Col>
            <Col span={1}>
                <Space>
                    <Button
                        onClick={actions.remove} type="link"
                        style={{color: "red", paddingTop: "0", paddingBottom: "0"}}
                        disabled={isImporting || isImportingDone}
                    >
                        <DeleteOutlined/>
                    </Button>
                </Space>
            </Col>
        </Row>
    }

    return (
        <div>
            <Upload.Dragger
                name="files"
                multiple={true}
                disabled={isImporting || isImportingDone}
                fileList={fileList}
                itemRender={itemRender}
                onChange={info => {
                    setFileList(info.fileList);
                }}
                customRequest={options =>
                    uploadFile(
                        ws2Axios,
                        undefined,
                        undefined,
                        options.file,
                        result => {
                            const newResult = {
                                ...result,
                                counts: result.importerStatus?.counts['de.pangaea.importer.processor.Validator'],
                                detectedFormats: result.importerStatus?.formats
                            }
                            if (result.errors && result.errors.length > 0) {
                                options.onError(newResult);
                            } else {
                                options.onSuccess(newResult);
                            }
                        },
                        entityDefs.dataset.apiGet + "/import1"
                    )}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">
                    Click or drag data matrix file(s) to this area
                </p>
            </Upload.Dragger>
            <div>
                {fileList.length} file(s)
            </div>
            {isImportingDone &&
                <div>
                    <br/>
                    List of imported Datasets: <FilterTag entityDef={entityDefs.dataset} rsql={rsql}/>
                    <br/><br/>Your imports are being processed in the background ... you can close this dialog, now.
                </div>
            }
            <Row justify="end" style={{paddingTop: "24px"}}>
                <Space>
                    <Button
                        key="reset"
                        type="link"
                        onClick={onResetAll}
                    >
                        Reset
                    </Button>
                    <Button
                        key="cancel"
                        type={isImportingDone ? "primary" : ""}
                        onClick={onCancel}
                    >
                        {isImportingDone ? "Close" : "Cancel"}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleImport}
                        loading={isImporting}
                        disabled={!readyForImport || isImporting || isImportingDone}
                    >
                        Import
                    </Button>
                </Space>
            </Row>
        </div>
    )
}

export default DatafileDirectImport;
