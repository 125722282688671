import EntityHeader from '../entities/EntityHeader';
import EntityFormItem from '../../../tk/forms/EntityFormItem';
import React, { useCallback, useContext } from 'react';
import FormBackground from '../../../tk/forms/FormBackground';
import { Button } from 'antd';
import ResponsiveCardCol from '../../../tk/cards/ResponsiveCardCol';
import UsagesDisplay from './UsagesDisplay';
import { FormExtContext } from '../../../tk/forms/FormExt';
import MySettingsContext from '../../../contexts/MySettingsContext';
import TableField2 from '../../../tk/inputComplex/TableField2';
import {
    renderCheckbox,
    renderEditableCheckbox,
    renderEntityRef,
    renderNumberInput,
    renderNumberInputCustom,
    renderTextAreaCustom
} from '../../test/TestPage2';
import { normaliseParameterTerms, suggestParameterAnnotation } from '../../../lib/queryParameters';
import dayjs from 'dayjs';
import MyUserContext from '../../../contexts/MyUserContext';
import { entityFields, renderField } from '../entities/entityFields';
import entityDefs from '../entities/entityDefs';
import useWS2Axios from '../../../hooks/useWS2Axios';
import DisabledTooltip from '../../../tk/bits/DisabledTooltip';


const ParameterDetail = (props) => {
    const { entity, entityDef } = props;

    const { formState } = useContext(FormExtContext);
    const { onClone } = useContext(MySettingsContext);
    const { myUser } = useContext(MyUserContext);
    const { watch } = useContext(FormExtContext);
    const watchTerms = watch('terms')?.map(entry => entry.term.id);
    const addedIds = watchTerms || [];
    const { setValue } = useContext(FormExtContext);
    const { getValues } = useContext(FormExtContext);

    const { ws2Axios } = useWS2Axios();

    const isDef = x => x !== undefined && x !== null;

    const eqEntities = (e1, e2) => {
        const bothDefined = isDef(e1) && isDef(e2);
        const noneDefined = !isDef(e1) && !isDef(e2);
        const diffDefined = !bothDefined && !noneDefined;
        if (diffDefined) {
            return false;
        }

        if (noneDefined) {
            return true;
        }

        return e1.commonName === e2.commonName && e1.id === e2.id;
    };


    const eqParameterTerms = (pt1, pt2, omitDatetimeCreated) => {
        const bothDefined = isDef(pt1) && isDef(pt2);
        const noneDefined = !isDef(pt1) && !isDef(pt2);
        const diffDefined = !bothDefined && !noneDefined;
        if (diffDefined) {
            return false;
        }

        if (noneDefined) {
            return true;
        }

        return pt1.autoAssigned === pt2.autoAssigned &&
            pt1.comment === pt2.comment &&
            pt1.confirmed === pt2.confirmed &&
            (omitDatetimeCreated || pt1.datetimeCreated === pt2.datetimeCreated) &&
            pt1.datetimeUpdated === pt2.datetimeUpdated &&
            pt1.startOffset === pt2.startOffset &&
            pt1.endOffset === pt2.endOffset &&
            pt1.sequence === pt2.sequence &&
            eqEntities(pt1.term, pt2.term) &&
            eqEntities(pt1.userCreated, pt2.userCreated) &&
            eqEntities(pt1.userUpdated, pt2.userUpdated);
    };


    const eqParameterTermArrays = (ar1, ar2, omitDatetimeCreated) => {
        const bothDefined = isDef(ar1) && isDef(ar2);
        const noneDefined = !isDef(ar1) && !isDef(ar2);
        const diffDefined = !bothDefined && !noneDefined;
        if (diffDefined) {
            return false;
        }

        if (noneDefined) {
            return true;
        }

        const l1 = ar1.length;
        const l2 = ar2.length;
        if (l1 !== l2) {
            return false;
        }

        for (let i = 0; i < l1; i++) {
            if (!eqParameterTerms(ar1[i], ar2[i], omitDatetimeCreated)) {
                return false;
            }
        }

        return true;
    };


    const mergeSuggestedTermsInForm = (newFormTerms) => {
        const existingTerms = getValues('terms');
        const keptExistingTermIds = existingTerms.filter(t => t.autoAssigned === false)
            .map(t => t.term.id);

        const newTerms = [];
        existingTerms.forEach((t, i, a) => {
            if (t.autoAssigned === false) {
                newTerms.push(t);
            }
        });

        newFormTerms.forEach((t, i, a) => {
            if (t?.term === undefined) {
                console.log('Invalid item:', t);
            } else if (!keptExistingTermIds.includes(t.term?.id)) {
                newTerms.push(t);
            }
        });

        return newTerms;
    };


    const onSuggestParameterAnnotationSuccess = (response) => {
        const terms = response?.data?.terms;
        if (Array.isArray(terms)) {
            const newFormTerms = mergeSuggestedTermsInForm(terms);

            normaliseParameterTerms(ws2Axios, newFormTerms, onNormaliseParameterTermsSuccess, onRequestError);
        }

        const ucum = response?.data?.unit?.ucum;
        if (ucum !== undefined) {
            setValue('ucum', ucum, { shouldTouch: true, shouldDirty: true });
        }
    };


    const onNormaliseParameterTermsSuccess = (response) => {
        const normalisedTerms = response?.data;
        if (Array.isArray(normalisedTerms)) {
            const omitDatetimeCreated = true;
            const relevantChanges = !eqParameterTermArrays(getValues('terms'), normalisedTerms, omitDatetimeCreated);
            if (relevantChanges) {
                setValue('terms', normalisedTerms, { shouldTouch: true, shouldDirty: true });
            }
        }
    };


    const onRequestError = (error) => {
        console.log('Failed request:', error);
    };


    const valueForId = (values, id) => {
        for (const value of values) {
            if (value !== undefined && value?.term?.id === id) {
                return value;
            }
        }

        return undefined;
    };

    const fieldChanged = (obj1, obj2, fieldName) => {
        return obj1[fieldName] !== obj2[fieldName];
    };

    const changedParameterTerm = useCallback((props, defaultTerms, currentTerms) => {
            if (!entity) {
                return false;
            }
            const termId = props['data-row-key'];

            // Old value.
            const defaultTerm = valueForId(defaultTerms, termId);

            // New value;
            const currentTerm = valueForId(currentTerms, termId);

            if (defaultTerm === undefined && currentTerm !== undefined) {
                return true;
            }

            if (defaultTerm !== undefined && currentTerm === undefined) {
                return true;
            }

            if (defaultTerm !== undefined && currentTerm !== undefined) {
                return ['confirmed', 'comment', 'startOffset', 'endOffset'].reduce(
                    (acc, fieldName) => acc || fieldChanged(defaultTerm, currentTerm, fieldName), false);
            }

            return false;
        },
        // eslint-disable-next-line
        [entity]);


    const disableAutoAssignedFunc = (index) => {
        const key = 'terms.' + index + '.autoAssigned';

        return getValues(key);
    };


    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + ' has unsaved changes' : undefined}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {['name', 'abbreviation', 'unit', 'dataType', 'description', 'format'].map(
                        name => renderField(entityFields.parameter.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {['rangeMin', 'rangeMax', 'uri', 'method', 'reference', 'ucum', 'keywords'].map(
                        name => renderField(entityFields.parameter.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2} wide>
                    <DisabledTooltip
                        conditions={[
                            { isTrue: formState.isDirty, message: 'Dataset has unsaved changes' },
                            { isTrue: !entity, message: 'Not possible in create dialog' }
                        ]}
                    >
                        <Button type="primary"
                                onClick={(event) => {
                                    const parameter_id = entity?.idParameter;

                                    suggestParameterAnnotation(ws2Axios,
                                        parameter_id,
                                        onSuggestParameterAnnotationSuccess,
                                        onRequestError);
                                }}
                        >
                            Suggest Annotation
                        </Button>
                    </DisabledTooltip>
                    <EntityFormItem label="Terms" paramName="terms" border={true} entityDef={entityDefs.term}>
                        <TableField2
                            filterFn={(entityRef) => !(addedIds.includes(entityRef.id))}
                            changedFunc={changedParameterTerm}
                            columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'term',
                                    width: 60,
                                    align: 'right',
                                    render: entityRef => entityRef?.id
                                },
                                {
                                    title: 'Term',
                                    dataIndex: 'term',
                                    width: 210,
                                    indexColumn: true,
                                    entityDef: entityDefs.term,
                                    render: renderEntityRef(entityDefs.term)
                                },
                                {
                                    title: 'Comment',
                                    dataIndex: 'comment',
                                    width: 300,
                                    render: renderTextAreaCustom('terms', 'comment', disableAutoAssignedFunc, getValues, 'term'),
                                    multiEdit: 'text'
                                },
                                {
                                    title: 'Start',
                                    dataIndex: 'startOffset',
                                    width: 130,
                                    render: renderNumberInputCustom('terms',
                                        'startOffset',
                                        /* min */ undefined,
                                        /* styleOverride */ {},
                                        /* disableFunc */ disableAutoAssignedFunc,
                                        getValues,
                                        'term')
                                },
                                {
                                    title: 'End',
                                    dataIndex: 'endOffset',
                                    width: 130,
                                    render: renderNumberInputCustom('terms',
                                        'endOffset',
                                        /* min */ undefined,
                                        /* styleOverride */ {},
                                        /* disableFunc */ disableAutoAssignedFunc,
                                        getValues,
                                        'term')
                                },
                                {
                                    title: 'Sequence',
                                    dataIndex: 'sequence',
                                    width: 130,
                                    render: renderNumberInput('terms', 'sequence', /* min */ undefined, /* styleOverride */ {}, /* disabled */ true)
                                },
                                {
                                    title: 'Confirmed',
                                    dataIndex: 'confirmed',
                                    width: 80,
                                    render: renderEditableCheckbox('terms', 'confirmed')
                                },
                                {
                                    title: 'Autoassigned',
                                    dataIndex: 'autoAssigned',
                                    width: 100,
                                    render: renderCheckbox('terms', 'autoAssigned', /* disabled */ true)
                                }
                            ]}
                            newRowFn={entityRef => ({
                                term: entityRef,
                                comment: '',
                                startOffset: null,
                                endOffset: null,
                                sequence: null,
                                confirmed: true,
                                autoAssigned: false,
                                datetimeCreated: dayjs.utc(),
                                userCreated: {
                                    id: myUser.id,
                                    commonName: myUser.fullname
                                },
                                datetimeUpdate: null,
                                userUpdated: { id: null, commonName: null }
                            })}
                        />
                    </EntityFormItem>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef} />
                </ResponsiveCardCol>
            </FormBackground>
        </>
    );
};

export default ParameterDetail;
