import entityDefs from "../entities/entityDefs";
import OverviewLine from "./OverviewLine";
import {fileSize} from "../../../tk/bits/FileSize";

const AttachmentOverview = (props) => {
    const {entity} = props;
    return (
        <div>
            <OverviewLine label="Filename" value={entity.filename}/>
            <OverviewLine label="Size" value={fileSize(entity.size)}/>
            <OverviewLine label="Reference" value={entity.reference} entityDef={entityDefs.reference}/>
        </div>
    )
}

export default AttachmentOverview;