import EntityTag from "../../../tk/bits/EntityTag";
import {Tag} from "antd";
import CardHeading from "../../../tk/cards/CardHeading";
import {darken} from "../../../lib/globalColors";
import PropTypes from "prop-types";
import HelpLink from "../../../tk/bits/HelpLink";


const EntityHeader = (props) => {
    const {entityDef, entity, heading, onClone, cloneDisabledMessage, isDirty, noCreatedUpdated, helpHref} = props;

    if (!entity?.me) {
        return (
            <h4 style={{paddingTop: "4px", marginTop: "0"}}>
                <Tag>NEW</Tag>
                <span>{entityDef.label}</span> <HelpLink category="Entities" title={entityDef.label} href={helpHref}/>
            </h4>
        );
    }

    return (
        <CardHeading
            color={darken(entityDef.color)}
            text={heading || entity.me.commonName}
            label={entityDef.label}
            entityDef={entityDef}
            onClone={onClone}
            cloneDisabledMessage={cloneDisabledMessage}
            isDirty={isDirty}
            entity={entity}
            noCreatedUpdated={noCreatedUpdated}
            helpHref={helpHref}
        >
            <EntityTag entityDef={entityDef} entityRef={entity.me} display="id" style={{marginBottom: "-1px"}}/>
        </CardHeading>
    );
}

EntityHeader.propTypes = {
    entityDef: PropTypes.object.isRequired,
    heading: PropTypes.string,
    onClone: PropTypes.func,
    cloneDisabledMessage: PropTypes.string,
    isDirty: PropTypes.bool,
    entity: PropTypes.object,
    noCreatedUpdated: PropTypes.bool,
    helpHref: PropTypes.string
}

export default EntityHeader;