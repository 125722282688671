import EntityHeader from "../entities/EntityHeader";
import React, {useContext} from "react";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import UsagesDisplay from "./UsagesDisplay";
import {FormExtContext} from "../../../tk/forms/FormExt";
import MySettingsContext from "../../../contexts/MySettingsContext";
import {entityFields, renderField} from "../entities/entityFields";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import RelatedTermsTableField from "../../../tk/inputComplex/RelatedTermsTableField";
import FlexLayoutContext from "../../../contexts/FlexLayoutContext";
import ButtonsInHeader from "../entities/ButtonsInHeader";
import DetailBarButton from "../../../tk/forms/DetailBarButton";
import BetaTag from "../../../tk/bits/BetaTag";
import FormGroupTitle from "../../../tk/forms/FormGroupTitle";
import Color from "color";
import TermHierarchy from "./TermHierarchy";


const TermDetail = (props) => {
    const {entity, entityDef} = props;
    const {formState} = useContext(FormExtContext);
    const {onClone} = useContext(MySettingsContext);
    const {addNetworkTab} = useContext(FlexLayoutContext);

    const groupTitleColor = Color(entityDef.bgColor).darken(0.8);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <ButtonsInHeader style={{marginTop: '8px'}}>
                <DetailBarButton
                    onClick={() => addNetworkTab(entity.me.id)}
                >
                    Network Graph
                </DetailBarButton>
                <BetaTag/>
            </ButtonsInHeader>
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["name", "abbreviation", "comment", "description", "semanticUri", "uri", "termCategory", "termStatus", "terminology"].map(
                        name => renderField(entityFields.term.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                {entity &&
                <ResponsiveCardCol no={1}>
                    <FormGroupTitle color={groupTitleColor}>Hierarchy</FormGroupTitle>
                    <TermHierarchy entity={entity}/>
                </ResponsiveCardCol>
                }
                <ResponsiveCardCol no={2}>
                    <EntityFormItem label='Related Terms' paramName='termsRelated' border={true}>
                        <RelatedTermsTableField relationTypes='termRelationTypes' termDataIndex='termRelated' terminologyDataIndex='terminologyRelated'/>
                    </EntityFormItem>
                    <EntityFormItem label='Related Reverse Terms' paramName='termsRelatedReverse' border={true}>
                        <RelatedTermsTableField relationTypes='termRelationTypesReverse' termDataIndex='term' terminologyDataIndex='terminology'/>
                    </EntityFormItem>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default TermDetail;
