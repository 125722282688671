import { Layout, message, Modal } from 'antd';
import * as FlexLayout from 'flexlayout-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import FlexLayoutContext from '../../contexts/FlexLayoutContext';
import {
    addEntityDetailTab,
    deleteTab,
    deleteTabset,
    networkScreen,
    openList,
    tabCreateEntity,
    tabDatafileDirectImport,
    tabJiraDirectImport,
    tabListImport
} from '../../lib/flexlayoutTabTools';
import MySettingsContext from '../../contexts/MySettingsContext';
import EditorialFlexLayout from './EditorialFlexLayout';
import NotificationsContext, { newNotificationsContext } from '../../contexts/NotificationsContext';
import EditorialHeaderMenu from './EditorialHeaderMenu';

const { Content } = Layout;

export const nextInstanceId = () => {
    return Math.random();
};

const findFirstTabSetId = node => {
    if (!node) return undefined;
    if (node.attributes.type === 'tabset') {
        return node.attributes.id;
    }
    return findFirstTabSetId(node.children[0]);
};

const stdLayoutDef = {
    global: {
        tabEnableRename: false,
        tabEnableFloat: false,
        tabSetEnableMaximize: false,
        tabSetEnableClose: true
    },
    borders: [
        {
            type: 'border',
            location: 'left',
            children: [
                {
                    type: 'tab',
                    enableClose: false,
                    component: { type: 'fourthdmenu' },
                    name: '4D Menu'
                },
                {
                    type: 'tab',
                    enableClose: false,
                    component: { type: 'starPage' },
                    name: 'Star Page'
                }
            ]
        },
        {
            type: 'border',
            location: 'right',
            children: []
        }
    ],
    layout: {
        type: 'row',
        weight: 100,
        children: [
            {
                type: 'tabset',
                weight: 50,
                children: [/*{
                    type: 'tab',
                    component: {type: "splash", myUser},
                    name: 'Splash'
                }*/]
            }
        ]
    }
};

const EditorialApp = (props) => {
    const [messageApi, contextHolder] = message.useMessage();

    const [layoutModel, setLayoutModel] = useLocalStorage(
        'editorial_layout_model_8',
        { model: stdLayoutDef }
    );

    if (layoutModel.model.borders[0].children.length === 1) {
        layoutModel.model.borders[0].children.push({
            type: 'tab',
            enableClose: false,
            component: { type: 'starPage' },
            name: 'Star Page'
        });
    }

    if (layoutModel.model.borders.length === 1) {
        layoutModel.model.borders.push({
            type: 'border',
            location: 'right',
            children: []
        });
    }

    const [model/*, setModel*/] = useState(FlexLayout.Model.fromJson(layoutModel.model));

    model.setOnAllowDrop(((dragNode, dropInfo) => {
        /*
        const dropNode = dropInfo.node;

        // prevent non-border tabs dropping into borders
        if (dropNode.getType() === "border" && (dragNode.getParent() === null || dragNode.getParent().getType() !== "border"))
            return false;

        // prevent border tabs dropping into main layout
        if (dropNode.getType() !== "border" && (dragNode.getParent() != null && dragNode.getParent().getType() === "border"))
            return false;
        */
        return true;
    }));

    // If there is no active tabset, set it
    if (!model._activeTabSet) {
        const tabSetId = findFirstTabSetId(model._root);
        if (tabSetId) {
            model.doAction(FlexLayout.Actions.setActiveTabset(tabSetId));
        }
    }

    const refLayout = useRef(null);

    const [, setModelBg] = useState();
    const blockCloseListener = useCallback((ev) => {
            ev.preventDefault();
            setModelBg(prevState => {
                if (prevState) {
                    setLayoutModel({ model: prevState.toJson() });
                }
                return prevState;
            });
            return ev.returnValue = 'Are you sure you want to close?';
        },
        [setLayoutModel]
    );

    useEffect(() => {
            window.addEventListener('beforeunload', blockCloseListener);
            return () => {
                window.removeEventListener('beforeunload', blockCloseListener);
            };
        },
        [blockCloseListener]
    );

    const [isUnsavedTabModalOpen, setIsUnsavedTabModalOpen] = useState({ open: false });
    const [isUnsavedTabsetModalOpen, setIsUnsavedTabsetModalOpen] = useState({ open: false });
    const [handleClone, setHandleClone] = useState();

    const setHandleCloneWrapper = useCallback(fn => {
            setHandleClone(() => {
                return fn;
            });
        },
        []
    );

    return (
        <NotificationsContext.Provider value={newNotificationsContext(messageApi, refLayout)}>
            <MySettingsContext.Provider value={{
                wideTags: false, //settingsWideTags,
                messageApi,
                onClone: handleClone
            }}>
                {contextHolder}
                <Modal
                    open={isUnsavedTabModalOpen.open}
                    onOk={() => {
                        setIsUnsavedTabModalOpen({ open: false });
                        deleteTab(model, isUnsavedTabModalOpen.tabId);
                    }}
                    onCancel={() => setIsUnsavedTabModalOpen({ open: false })}
                >
                    Tab has unsaved changes. Discard and close?
                </Modal>
                <Modal
                    open={isUnsavedTabsetModalOpen.open}
                    onOk={() => {
                        setIsUnsavedTabsetModalOpen({ open: false });
                        deleteTabset(model, isUnsavedTabsetModalOpen.tabsetId);
                    }}
                    onCancel={() => setIsUnsavedTabsetModalOpen({ open: false })}
                >
                    Tabs have unsaved data. Discard changes and close all tabs in tabset?
                </Modal>
                <Layout>
                    <FlexLayoutContext.Provider value={{
                        addEntityDetailTab: (entityRef, entityDef) => {
                            addEntityDetailTab(refLayout.current, entityRef, entityDef);
                        },
                        addEntityCreateTab: (defaultEntity, initEntity, entityDef) => {
                            tabCreateEntity(entityDef, refLayout, defaultEntity, initEntity);
                        },
                        addDatafileDirectImportTab: importState => {
                            tabDatafileDirectImport(refLayout, importState);
                        },
                        addListTab: (entityDef, rsql) => {
                            openList(entityDef, refLayout, rsql);
                        },
                        addListImportTab: state => {
                            tabListImport(refLayout, state);
                        },
                        addJiraDirectImportTab: (ticket, initEntity) => {
                            tabJiraDirectImport(refLayout, ticket, initEntity);
                        },
                        addNetworkTab: (entityId) => {
                            networkScreen(refLayout, entityId);
                        }
                    }}>
                        <EditorialHeaderMenu
                            refLayout={refLayout}
                            setHandleClone={setHandleCloneWrapper}
                        />
                        <Layout className="site-layout-background">
                            <Layout>
                                <Content>
                                    <EditorialFlexLayout
                                        refLayout={refLayout}
                                        model={model}
                                        setModel={setModelBg}
                                        setIsUnsavedTabModalOpen={setIsUnsavedTabModalOpen}
                                        setIsUnsavedTabsetModalOpen={setIsUnsavedTabsetModalOpen}
                                    />
                                </Content>
                            </Layout>
                        </Layout>
                    </FlexLayoutContext.Provider>
                </Layout>
            </MySettingsContext.Provider>
        </NotificationsContext.Provider>
    );
};

export default EditorialApp;
