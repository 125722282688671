import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";


const SelectField = ({paramName, values, disabled, allowClear, rules, onChange, style}) => {
    const {control, getValues} = useContext(FormExtContext);
    const valuesTrans = values.map(v => {
        if (v.name) {
            return v;
        }
        return {...v, name: v.commonName};
    });
    return (
        <Controller
            name={paramName}
            control={control}
            rules={rules}
            render={({field}) => (
                <>
                    <SelectInput
                        {...field}
                        style={style}
                        value={getValues(paramName)} // Fix for revert on TableField2
                        onChange={v => {
                            field.onChange(v);
                            if (onChange) {
                                onChange(v)
                            }
                        }}
                        allowClear={allowClear}
                        disabled={disabled}
                        options={valuesTrans}
                        name={paramName}
                    />
                    <FormFieldError paramName={paramName}/>
                </>
            )}
        />
    )
};

SelectField.propTypes = {
    paramName: PropTypes.string,
    values: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    allowClear: PropTypes.bool,
    onChange: PropTypes.func,
    rules: PropTypes.object,
    tagStyle:PropTypes.shape({
        width: PropTypes.string
    }),
    style:PropTypes.object
};

export default SelectField;