import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Col, Input, Popconfirm, Row, Select, Space, Spin, Table } from 'antd';
import Color from 'color';
import { useFieldArray } from 'react-hook-form';
import { FormExtContext } from '../forms/FormExt';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import FilterTag from '../bits/FilterTag';
import { useDrop } from 'react-dnd';
import { DndHighlight } from '../../lib/dropIndicatorStyle';
import useEntitySearch from '../../hooks/useEntitySearch';
import useWS2Axios from '../../hooks/useWS2Axios';
import SelectEntityInput from '../input/SelectEntityInput';
import SelectMultiEntityInput from '../input/SelectMultiEntityInput';
import FormFieldError from '../forms/FormFieldError';
import PropTypes, { object } from 'prop-types';
import SelectInput from '../input/SelectInput';
import { dropEntities } from '../../lib/entityUtils';
import { pangaeaColor } from '../../lib/globalColors';
import AddEntitySelect from './AddEntitySelect';


const MultiEditTextSetOptions = (props) => {
    const { onClick } = props;
    const [textSet, setTextSet] = useState('');
    return (
        <Row wrap={false} gutter={[16, 16]}>
            <Col span={18}>
                <Input
                    placeholder="Update selected"
                    value={textSet}
                    onChange={e => setTextSet(e.target.value)}
                />
            </Col>
            <Col span={6}>
                <Button
                    onClick={() => onClick(textSet)}
                >
                    Go!
                </Button>
            </Col>
        </Row>
    );
};

const MultiEditTextReplaceOptions = (props) => {
    const { onClick } = props;
    const [textSet, setTextSet] = useState('');
    const [textReplace, setTextReplace] = useState('');
    return (
        <Row wrap={false} gutter={[16, 16]}>
            <Col span={9}>
                <Input
                    placeholder="Search"
                    value={textSet}
                    onChange={e => setTextSet(e.target.value)}
                />
            </Col>
            <Col span={9}>
                <Input
                    placeholder="Replace"
                    value={textReplace}
                    onChange={e => setTextReplace(e.target.value)}
                />
            </Col>
            <Col span={6}>
                <Button
                    onClick={() => onClick(textSet, textReplace)}
                >
                    Go!
                </Button>
            </Col>
        </Row>
    );
};

const MultiEditTextTitle = (props) => {
    const { enableEditor, title, setFn, replaceFn } = props;
    const [mode, setMode] = useState('set');
    if (!enableEditor) {
        return title;
    } else {
        return (
            <div>
                <div>
                    {title}
                    <Select
                        style={{ width: '65%' }}
                        value={mode}
                        onChange={setMode}
                        placeholder="Multi-Edit"
                        options={[
                            { label: 'Set', value: 'set' },
                            { label: 'Replace', value: 'replace' }
                        ]}
                        allowClear={true}
                    />
                </div>
                <div>
                    {mode === 'set' &&
                        <MultiEditTextSetOptions onClick={setFn} />
                    }
                    {mode === 'replace' &&
                        <MultiEditTextReplaceOptions onClick={replaceFn} />
                    }
                </div>
            </div>
        );
    }
};

const MultiEditEntityRefSetOptions = (props) => {
    const { onClick, entityDef } = props;
    const [entityRefSelected, setEntityRefSelected] = useState({ id: null, commonName: null });
    return (
        <Row justify="space-between" align="middle">
            <Col flex="auto">
                <SelectEntityInput
                    entityDef={entityDef}
                    placeholder="Update selected"
                    onSelect={setEntityRefSelected}
                    value={entityRefSelected}
                    onChange={setEntityRefSelected}
                    size="full"
                />
            </Col>
            <Col flex="none">
                <Button onClick={() => onClick(entityRefSelected)} type="text">
                    Go!
                </Button>
            </Col>
        </Row>
    );
};

const MultiEditEntityRefTitle = (props) => {
    const { enableEditor, title, setFn, entityDef, defaultEntities, auxSelect } = props;
    const [mode, setMode] = useState('set');
    const { setValue } = useContext(FormExtContext);
    const defaultOptions = [{ label: 'Set', value: 'set' }];
    const options = auxSelect?.selectOptions ? [...defaultOptions, ...auxSelect.selectOptions] : defaultOptions;
    // e.g. options={[
    //     {label: "Set", value: "set"},  - defaultOptions
    //     {label: "Set default", value: "setDefault"}, - aux options
    // ]}
    if (!enableEditor) {
        return title;
    } else {
        return <div>
            <Row justify="space-between" align="middle">
                <Col>
                    {title}
                </Col>
                <Col>
                    <Select
                        style={{ minWidth: '100px' }}
                        value={mode}
                        onChange={setMode}
                        placeholder="Multi-Edit"
                        options={options}
                        allowClear={true}
                    />
                </Col>
            </Row>
            {mode === 'set' &&
                <MultiEditEntityRefSetOptions onClick={setFn} entityDef={entityDef} />
            }
            {
                auxSelect && auxSelect.selectRender(mode, defaultEntities, setValue)
            }
        </div>;
    }
};

const MultiEditTypeSetOptions = (props) => {
    const { onClick, options } = props;
    const [entityRefSelected, setEntityRefSelected] = useState({ id: null, commonName: null });
    return (
        <Row justify="space-between" align="middle">
            <Col flex="auto">
                <SelectInput
                    placeholder="Update selected"
                    onSelect={setEntityRefSelected}
                    value={entityRefSelected}
                    onChange={setEntityRefSelected}
                    options={options}
                    style={{ minWidth: '100%', width: '100%' }}
                    //allowClear={true}
                />
            </Col>
            <Col flex="none">
                <Button onClick={() => onClick(entityRefSelected)} type="text">
                    Go!
                </Button>
            </Col>
        </Row>
    );
};

const MultiEditTypeTitle = (props) => {
    const { enableEditor, title, setFn, options } = props;
    const [mode, setMode] = useState('set');
    if (!enableEditor) {
        return title;
    } else {
        return (
            <div>
                <Row justify="space-between" align="middle">
                    <Col>
                        {title}
                    </Col>
                    <Col>
                        <Select
                            style={{ width: '100px' }}
                            value={mode}
                            onChange={setMode}
                            placeholder="Multi-Edit"
                            options={[
                                { label: 'Set', value: 'set' }
                            ]}
                            allowClear={true}
                        />
                    </Col>
                </Row>
                {mode === 'set' &&
                    <MultiEditTypeSetOptions onClick={setFn} options={options} />
                }
            </div>
        );
    }
};

const MultiEditEntityRefListSetOptions = (props) => {
    const { onClick, entityDef, placeholder } = props;
    const [entityRefSelected, setEntityRefSelected] = useState([]);
    return (
        <Row wrap={false} gutter={[16, 16]}>
            <Col span={18}>
                <SelectMultiEntityInput
                    entityDef={entityDef}
                    placeholder={placeholder}
                    onSelect={setEntityRefSelected}
                    value={entityRefSelected}
                    onChange={setEntityRefSelected}
                />
            </Col>
            <Col span={6}>
                <Button onClick={() => onClick(entityRefSelected)}>
                    Go!
                </Button>
            </Col>
        </Row>
    );
};

const MultiEditEntityRefListTitle = (props) => {
    const { enableEditor, title, setFn, addFn, removeFn, entityDef } = props;
    const [mode, setMode] = useState('set');
    if (!enableEditor) {
        return title;
    } else {
        return (
            <div>
                <div>
                    {title}
                    <Select
                        style={{ width: '65%' }}
                        value={mode}
                        onChange={setMode}
                        placeholder="Multi-Edit"
                        options={[
                            { label: 'Set', value: 'set' },
                            { label: 'Add', value: 'add' },
                            { label: 'Remove', value: 'remove' }
                        ]}
                        allowClear={true}
                    />
                </div>
                <div>
                    {mode === 'set' &&
                        <MultiEditEntityRefListSetOptions
                            onClick={setFn}
                            entityDef={entityDef}
                            placeholder="Update selected"
                        />
                    }
                    {mode === 'add' &&
                        <MultiEditEntityRefListSetOptions
                            onClick={addFn}
                            entityDef={entityDef}
                            placeholder="Add to selected"
                        />
                    }
                    {mode === 'remove' &&
                        <MultiEditEntityRefListSetOptions
                            onClick={removeFn}
                            entityDef={entityDef}
                            placeholder="Remove from selected"
                        />
                    }
                </div>
            </div>
        );
    }
};

export const findUnsortedIndex = (key, getValues, paramName, subParamName) => {
    let i = 0;
    while (true) {
        let f = getValues(paramName + '.' + i + '.' + subParamName);
        if (f === undefined) {
            console.error('NO F, i');
            return undefined;
        }
        if (f.id === key) {
            return i;
        }
        i++;
    }
};


const TableRow = ({ children, paramName, changedFunc, ...props }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: props['data-row-key']
    });

    const { formState, watch } = useContext(FormExtContext);
    const param = watch(paramName);

    const textColor = pangaeaColor;
    const pgBackground = Color(pangaeaColor).lighten(0.2).toString();

    const changed = (props, formState, param) => {
        if (formState.isDirty && changedFunc !== undefined) {
            return changedFunc(props, formState.defaultValues[paramName], param);
        } else {
            return false;
        }
    };

    const styleWithRowColour = (props, formState, param) => {
        return changed(props, formState, param) ? {
            ...props.style,
            color: textColor,
            background: pgBackground
        } : props.style;
    };


    const rowStyle = styleWithRowColour(props, formState, param);

    const style = {
        ...props.style,
        ...rowStyle,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1
            }
        )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999
            }
            : {})
    };
    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === 'sort') {
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{
                                    touchAction: 'none',
                                    cursor: 'move'
                                }}
                                {...listeners}
                            />
                        )
                    });
                }
                return child;
            })}
        </tr>
    );
};

const TableField2 = (props) => {
    const {
        paramName,
        columns,
        entityDef,
        prefabAxios,
        newRowFn,
        withOrder,
        altEntityType,
        defaultEntities = [],
        filterFn,
        changedFunc
    } = props;
    const { control, setValue, getValues } = useContext(FormExtContext);
    const sourceFields = getValues(paramName);

    const [isLoading, setIsLoading] = useState(false);
    const { remove, move, append } = useFieldArray({
        control,
        name: paramName
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const rowSelectionConfig = {
        selectedRowKeys,
        onChange: setSelectedRowKeys
    };

    const { ws2Axios } = useWS2Axios(prefabAxios);
    const entitySearch = useEntitySearch(altEntityType || entityDef.entityType, undefined, 1000, ws2Axios);

    const indexColumn = useMemo(() => {
        return columns.find(column => column.indexColumn === true).dataIndex;
    }, [columns]);

    const fields = sourceFields.map(f => ({ key: f[indexColumn].id, ...f }));
    const findIndex = useCallback(
        key => fields.findIndex(field => key === field.key),
        [fields]
    );

    const setValueOptions = useMemo(() => ({
            shouldDirty: true
        }),
        []
    );

    const handleRemoveAll = useCallback(() => {
            setValue(paramName, [], setValueOptions);
            setSelectedRowKeys([]);
        },
        [paramName, setValue, setValueOptions]
    );

    const handleRemoveSelected = useCallback(() => {
            const selectedIndexes = selectedRowKeys.map(findIndex);
            remove(selectedIndexes);
            setSelectedRowKeys([]);
        },
        [findIndex, remove, selectedRowKeys]
    );

    const entityRefList = useMemo(() => {
            return selectedRowKeys.map(id => {
                const row = fields.find(row => row.key === id);
                return row[indexColumn];
            });
        },
        [fields, indexColumn, selectedRowKeys]
    );

    const appendRow = useCallback(entityRef => {
            const exists = fields.find(row => row[indexColumn].id === entityRef.id);
            if (exists) return;
            append({ ...newRowFn(entityRef), id: entityRef.id });
        },
        [append, fields, indexColumn, newRowFn]
    );

    const [{ canDrop, isOver }, drop] = useDrop(() =>
            dropEntities(entityDef, entityRefs => entityRefs.forEach(appendRow), setIsLoading, entitySearch.doRsqlSearch),
        [setIsLoading, entityDef, appendRow, entitySearch.doRsqlSearch]
    );

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            const activeIndex = findIndex(active.id);
            const overIndex = findIndex(over?.id);
            move(activeIndex, overIndex);
        }
    };


    const components = useMemo(() => {
            return {
                body: {
                    row: (props) => {
                        return TableRow({ paramName: paramName, changedFunc: changedFunc, ...props });
                    }
                }
            };
        },
        [paramName, changedFunc]
    );

    const setValueFn = useCallback((newText, dataIndex) => {
            //console.log("SetValueFn", newText, dataIndex, selectedRowKeys, fields)
            selectedRowKeys
                .map(findIndex)
                .forEach(index => {
                    //console.log("... setValueFn", index, newText, paramName, dataIndex);
                    setValue(paramName + '.' + index + '.' + dataIndex, newText, setValueOptions);
                });
        },
        [findIndex, paramName, selectedRowKeys, setValue, setValueOptions]
    );

    const replaceTextFn = useCallback((search, replace, dataIndex) => {
            selectedRowKeys
                .map(findIndex)
                .forEach(index => {
                    const field = paramName + '.' + index + '.' + dataIndex;
                    const value = getValues(field);
                    const s = value.replaceAll(search, replace);
                    setValue(field, s, setValueOptions);
                });
        },
        [findIndex, getValues, paramName, selectedRowKeys, setValue, setValueOptions]
    );

    const addToListFn = useCallback((addEntityRefs, dataIndex) => {
            selectedRowKeys
                .map(findIndex)
                .forEach(index => {
                    const field = paramName + '.' + index + '.' + dataIndex;
                    const list = getValues(field) || [];
                    const addDisjunct = addEntityRefs
                        .filter(addEntityRef => list.find(entityRef => entityRef.id === addEntityRef.id) === undefined);
                    setValue(field, [...list, ...addDisjunct], setValueOptions);
                });
        },
        [findIndex, getValues, paramName, selectedRowKeys, setValue, setValueOptions]
    );

    const removeFromListFn = useCallback((removeEntityRefs, dataIndex) => {
            selectedRowKeys
                .map(findIndex)
                .forEach(index => {
                    const field = paramName + '.' + index + '.' + dataIndex;
                    const list = getValues(field);
                    let newList = [...list];
                    removeEntityRefs
                        .forEach(removeEntityRef => {
                            const i = newList.findIndex(entityRef => entityRef.id === removeEntityRef.id);
                            if (i > -1) {
                                newList.splice(i, 1);
                            }
                        });
                    setValue(field, newList, setValueOptions);
                });
        },
        [findIndex, getValues, paramName, selectedRowKeys, setValue, setValueOptions]
    );

    const extColumns = useMemo(() => {
            let mapped = columns.map(column => {
                let newCol;
                if (column.multiEdit === 'text') {
                    newCol = {
                        ...column,
                        title: <MultiEditTextTitle
                            enableEditor={selectedRowKeys.length > 0}
                            title={column.title}
                            setFn={newText => setValueFn(newText, column.dataIndex)}
                            replaceFn={(search, replace) => replaceTextFn(search, replace, column.dataIndex)}
                        />
                    };
                } else if (column.multiEdit === 'entityRef') {
                    newCol = {
                        ...column,
                        title: <MultiEditEntityRefTitle
                            enableEditor={selectedRowKeys.length > 0}
                            title={column.title}
                            setFn={newEntityRef => setValueFn(newEntityRef, column.dataIndex)}
                            entityDef={column.entityDef}
                            defaultEntities={defaultEntities}
                            auxSelect={column.auxSelect ? column.auxSelect : undefined}
                        />
                    };
                } else if (column.multiEdit === 'entityRefList') {
                    newCol = {
                        ...column,
                        title: <MultiEditEntityRefListTitle
                            enableEditor={selectedRowKeys.length > 0}
                            title={column.title}
                            setFn={newEntityRefList => setValueFn(newEntityRefList, column.dataIndex)}
                            addFn={newEntityRefList => addToListFn(newEntityRefList, column.dataIndex)}
                            removeFn={newEntityRefList => removeFromListFn(newEntityRefList, column.dataIndex)}
                            entityDef={column.entityDef}
                        />
                    };
                } else if (column.multiEdit === 'type') {
                    newCol = {
                        ...column,
                        title: <MultiEditTypeTitle
                            enableEditor={selectedRowKeys.length > 0}
                            title={column.title}
                            setFn={value => setValueFn(value, column.dataIndex)}
                            options={column.multiEditOptions}
                        />
                    };
                } else {
                    newCol = {
                        ...column
                    };
                }
                if (selectedRowKeys.length > 0) {
                    newCol.sorter = undefined;
                }
                return newCol;
            });
            if (withOrder) {
                mapped = [
                    {
                        dataIndex: 'sort',
                        width: 30
                    },
                    ...mapped
                ];
            }
            mapped = [
                ...mapped,
                {
                    width: 50,
                    render: (text, record, index) => {
                        return (
                            <Button type="link" onClick={() => {
                                const foundIndex = fields.findIndex(field => field.key === record.key);
                                selectedRowKeys.splice(foundIndex, 1);
                                remove(foundIndex);
                            }}>
                                <DeleteOutlined />
                            </Button>
                        );
                    }
                }
            ];
            return mapped;
        },
        [columns, withOrder, selectedRowKeys, setValueFn, replaceTextFn, defaultEntities, addToListFn, removeFromListFn, fields, remove]
    );

    if (isLoading) {
        return <Spin spinning={true}>
            <div>Loading ...</div>
        </Spin>;
    }

    return (
        <Spin spinning={isLoading}>
            <DndContext onDragEnd={onDragEnd}>
                <div ref={drop}>
                    <DndHighlight canDrop={canDrop} isOver={isOver} color1={entityDef.color}
                                  color2={entityDef.bgClor} />
                    {fields.length > 0 &&
                        <SortableContext
                            // rowKey array
                            items={fields.map(i => i.key)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Table
                                columns={extColumns}
                                dataSource={fields}
                                rowKey="key"
                                rowSelection={rowSelectionConfig}
                                components={components}
                                className="mini-table"
                                size="small"
                                pagination={false}
                                sticky
                                scroll={{
                                    x: 600
                                }}
                            />
                        </SortableContext>
                    }
                    <Space style={{ marginTop: '16px' }}>
                        {selectedRowKeys.length === 0 && fields.length > 0 &&
                            <>
                                <FilterTag
                                    size="mini"
                                    entityDef={entityDef}
                                    list={fields.map(row => row[indexColumn])}
                                />
                                <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={handleRemoveAll}
                                >
                                    <Button disabled={!fields || fields.length === 0} type="text">
                                        <DeleteOutlined /> Remove All
                                    </Button>
                                </Popconfirm>
                            </>
                        }
                        {selectedRowKeys.length > 0 && fields.length > 0 &&
                            <>
                                <FilterTag
                                    size="mini"
                                    entityDef={entityDef}
                                    list={entityRefList}
                                />
                                <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={handleRemoveSelected}
                                >
                                    <Button
                                        disabled={!fields || fields.length === 0 || selectedRowKeys.length === 0}
                                        type="text">
                                        <DeleteOutlined /> Remove Selected
                                    </Button>
                                </Popconfirm>
                            </>
                        }

                        <AddEntitySelect
                            entityDef={entityDef}
                            altEntityType={altEntityType}
                            onChange={v => {
                                if (v === undefined) {
                                    return;
                                }
                                appendRow({ id: v.value, commonName: v.label });
                            }}
                            onCreated={entityRef => {
                                appendRow(entityRef);
                            }}
                            filterFn={filterFn}
                            paramName={paramName}
                        />
                        <FormFieldError paramName={paramName} />
                    </Space>
                </div>
            </DndContext>
        </Spin>
    );
};

TableField2.propTypes = {
    entityDef: PropTypes.object,
    paramName: PropTypes.string,
    columns: PropTypes.arrayOf(object),
    newRowFn: PropTypes.func
};
export default TableField2;
