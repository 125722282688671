import EntityTag from '../../../tk/bits/EntityTag';
import ResolveUri from '../../../tk/bits/ResolveUri';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import EntityTagList from '../../../tk/bits/EntityTagList';
import JiraLink from '../../../tk/bits/JiraLink';
import EllipsisText from '../../../tk/bits/EllipsisText';
import OrcidLink from '../../../tk/bits/OrcidLink';

export const labelStyle = {
    color: '#888',
    fontSize: '12px'
};

export const OverviewLabel = (props) => {
    const { label } = props;
    return <><span style={labelStyle}>{label}:</span>&nbsp;&nbsp;</>;
};

const OverviewLine = (props) => {
    const { label, value, entityDef, type, max } = props;

    if (value === null) {
        return (
            <>
                <OverviewLabel label={label} />
                <span style={labelStyle}>blank</span>
                <br />
            </>
        );
    }

    if (value === undefined || value === '' || value.id === null || (Array.isArray(value) && value.length === 0)) {
        return <span />;

    } else if (entityDef !== undefined) {
        if (Array.isArray(value)) {
            return (
                <>
                    <span>
                        <OverviewLabel label={label} />
                        <EntityTagList list={value} entityDef={entityDef} max={max} />
                    </span>
                    <br />
                </>
            );
        } else {
            return (
                <>
                    <span>
                        <OverviewLabel label={label} />
                        <EntityTag entityRef={value} entityDef={entityDef} />
                    </span>
                    <br />
                </>
            );
        }

    } else if (type === 'uri') {
        return (
            <>
                <span>
                    <OverviewLabel label={label} />
                    <ResolveUri uri={value} />
                </span>
                <br />
            </>
        );

    } else if (type === 'orcid') {
        return (
            <>
                <span>
                    <OverviewLabel label={label} />
                    <OrcidLink orcid={value} />
                </span>
                <br />
            </>
        );

    } else if (type === 'ticket') {
        return (
            <>
                <span>
                    <OverviewLabel label={label} />
                    <JiraLink ticket={value} />
                </span>
                <br />
            </>
        );

    } else if (type === 'date') {
        return (
            <>
                <span>
                    <OverviewLabel label={label} />
                    {dayjs(value).format('YYYY-MM-DD')}
                </span>
                <br />
            </>
        );

    } else if (type === 'datetime') {
        return (
            <>
                <span>
                    <OverviewLabel label={label} />
                    {dayjs.utc(value).format('YYYY-MM-DD HH:mm')}
                </span>
                <br />
            </>
        );
    }


    return (
        <span>
            <OverviewLabel label={label} />
            <EllipsisText text={value.toString()} max={max} />
            <br />
        </span>
    );
};

OverviewLine.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    entityDef: PropTypes.object,
    type: PropTypes.string
};

export default OverviewLine;
