import './DatasetSteps.css';
import { Steps } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import EntityTag from '../../../tk/bits/EntityTag';
import entityDefs from '../entities/entityDefs';
import DatasetImportStatus from './DatasetImportStatus';

dayjs.extend(utc);

const subStyle = { lineHeight: '1.5', paddingTop: '8px' };
const stepsStyle = { marginBottom: '0px' };

const makeSteps = (dataset, series, doiRegistered) => {

    const createdDesc = (
        <div style={subStyle}>
            {dayjs.utc(dataset.datetimeCreated).format('YYYY-MM-DD')} / <EntityTag
            entityRef={dataset.userCreated} entityDef={entityDefs.panUser} />
        </div>
    );
    const updatedDesc = (
        <div style={subStyle}>
            last update {dayjs.utc(dataset.datetimeUpdated).format('YYYY-MM-DD')} / <EntityTag
            entityRef={dataset.userUpdated} entityDef={entityDefs.panUser} />
        </div>
    );
    const uploadedDesc = (
        <div style={subStyle}>
            {dataset.collectionType.id === null &&
                <DatasetImportStatus idDataset={dataset.me.id} />
            }
            {dataset.collectionType.id !== null &&
                <span>No data for collections</span>
            }
        </div>
    );
    const uploadedTitle = () => {
        if (dataset.collectionType.id === null) {
            return <span>Data uploaded</span>;
        }
        return <span>Skipped data upload</span>;
    };

    let moratoriumDesc = (
        <div style={subStyle}>
            No moratorium specified
        </div>
    );
    if (dataset.moratoriumUntil !== undefined && dataset.moratoriumUntil !== null) {
        moratoriumDesc = (
            <div style={subStyle}>
                until {dayjs.utc(dataset.moratoriumUntil).format('YYYY-MM-DD')}
            </div>
        );
    }

    let publicationDesc = (
        <div style={subStyle}>
        </div>
    );
    if (doiRegistered) {
        publicationDesc = (
            <div style={subStyle}>
                on {dayjs.utc(doiRegistered).format('YYYY-MM-DD')}
            </div>
        );
    }

    const items = [
        {
            title: 'Created',
            description: createdDesc
        },
        {
            title: uploadedTitle(),
            description: uploadedDesc
        },
        {
            title: 'In preparation',
            description: updatedDesc
        },
        {
            title: 'Moratorium passed',
            description: moratoriumDesc
        },
        {
            title: 'DOI registered',
            description: publicationDesc
        }
    ];

    const currentStep = () => {
        if (doiRegistered) {
            return 4;
        } else if ((series === undefined || series.length === 0) && dataset.collectionType.id === null) {
            return 0;
        } else if (dataset.datasetStatus.id === 4 /*published*/ &&
            dataset.moratoriumUntil !== undefined &&
            dayjs.utc().isBefore(dataset.moratoriumUntil)
        ) {
            return 3;
        } else if (dataset.datasetStatus.id !== 4) {
            return 2;
        } else {
            return 3;
        }
    };

    return (
        <Steps current={currentStep()} items={items} style={stepsStyle} />
    );
};

const DatasetSteps = (props) => {
    const { dataset, series, doiRegistered } = props;
    return (
        <div style={{ margin: '4px', width: '100%' }}>
            {makeSteps(dataset, series, doiRegistered)}
        </div>
    );
};

DatasetSteps.propTypes = {
    dataset: PropTypes.object,
    series: PropTypes.array,
    doiRegistered: PropTypes.object
};

export default DatasetSteps;
