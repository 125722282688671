import EntityHeader from "../entities/EntityHeader";
import React, {useContext} from "react";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import entityDefs from "../entities/entityDefs";
import {FormExtContext} from "../../../tk/forms/FormExt";
import { entityFields, renderField } from '../entities/entityFields';


const PanUserDetail = (props) => {
    const {entity} = props;
    const {formState} = useContext(FormExtContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDefs.panUser}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["username", "fullName", "email", "orcid", "phone", "institution", "description"].map(
                        name => renderField(entityFields.panUser.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["staffs", "groups", "created", "lastLogin", "logins"].map(
                        name => renderField(entityFields.panUser.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default PanUserDetail;
