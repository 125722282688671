import { Badge, Button, List, Popover, Typography } from 'antd';
import { ArrowUpOutlined, BellFilled } from '@ant-design/icons';
import useWS2Axios from '../../hooks/useWS2Axios';
import { useEffect, useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import dayjs from 'dayjs';
import JiraLink from '../../tk/bits/JiraLink';

const { Text } = Typography;

const Title = (props) => {
    return <Text strong>Recent Changes</Text>;
};

const Comment = ({ author, datetime, content, ticket }) => {
    return (
        <div style={{ marginBottom: '16px', marginTop: '16px' }}>
            <div style={{ marginBottom: '8px', fontSize: '0.9em' }}>
                {author} <span style={{ marginLeft: '8px', color: 'grey' }}>{datetime}</span>
            </div>
            <div>
                {content}
            </div>
            <div>
                <JiraLink ticket={ticket} />
            </div>
        </div>
    );
};

const Content = (props) => {
    const { changes, onMarkAsRead } = props;
    return (
        <>
            {changes.length > 0 &&
                <Button onClick={onMarkAsRead}><ArrowUpOutlined />Upgrade</Button>
            }
            <List
                dataSource={changes}
                renderItem={item => (
                    <li>
                        <Comment
                            author={item.author}
                            datetime={dayjs.utc(item.datetime).format('YYYY-MM-DD HH:mm')}
                            content={item.content}
                            ticket={item.ticket}
                        />
                    </li>
                )}
            />
            {changes.length > 0 &&
                <Button onClick={onMarkAsRead}><ArrowUpOutlined />Upgrade</Button>
            }
        </>
    );
};

const RecentChangesButton = (props) => {
    const [changes, setChanges] = useState([]);
    const [recentChanges, setRecentChanges] = useState([]);
    const [requestNo, setRequestNo] = useState(0);
    const [lastRead, setLastRead] = useLocalStorage('lastReadRecentChanges', 0);
    const { ws2Axios } = useWS2Axios();

    useEffect(() => {
            const doStatusRequest = () => {
                ws2Axios
                    .get('/changes.json')
                    .then(response => {
                        setChanges(response.data);
                        setRecentChanges(response.data.slice(lastRead));
                    });
            };

            doStatusRequest();

            const timeout = setTimeout(() => {
                setRequestNo(requestNo + 1);
                doStatusRequest();
            }, 60000);
            return () => clearTimeout(timeout);
        },
        [ws2Axios, requestNo, lastRead]
    );

    if (recentChanges.length === 0) {
        return <span />;
    }

    return (
        <div style={{ marginBottom: '-5px' }}>
            <Popover
                title={<Title />}
                content={
                    <Content
                        changes={recentChanges}
                        onMarkAsRead={() => {
                            setLastRead(changes.length);
                            window.location.reload();
                        }} />
                }
                overlayStyle={{ width: '400px' }}
                trigger="click"
            >
                <Badge count={recentChanges.length} offset={[-16, 10]}>
                    <Button type="link">
                        <BellFilled style={{ fontSize: '25px' }} />
                    </Button>
                </Badge>
            </Popover>
        </div>
    );
};

export default RecentChangesButton;
