import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import FormFieldError from "../forms/FormFieldError";
import {FormExtContext} from "../forms/FormExt";
import PropTypes from "prop-types";
import SelectMultiEntityInput from "./SelectMultiEntityInput";


const SelectMultiEntityField = (props) => {
    const {
        paramName,
        entityDef,
        noNewButton,
        dropdownVisible,
        setDropdownVisible,
        dropdownButtons,
        sortable
    } = props;
    const {control} = useContext(FormExtContext);

    return (
        <Controller
            name={paramName}
            control={control}
            render={({field}) =>
                <div>
                    <SelectMultiEntityInput
                        entityDef={entityDef}
                        {...field}
                        value={field.value}
                        onChange={field.onChange}
                        noNewButton={noNewButton}
                        dropdownOpen={dropdownVisible}
                        setDropdownOpen={setDropdownVisible}
                        dropdownButtons={dropdownButtons}
                        sortable={sortable}
                        paramName={paramName}
                    />
                    <FormFieldError paramName={paramName}/>
                </div>
            }
        />
    )
}

SelectMultiEntityField.propTypes = {
    paramName: PropTypes.string,
    entityDef: PropTypes.object,
    noNewButton: PropTypes.bool,
    sortable: PropTypes.bool
}

export default SelectMultiEntityField;
