import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear'; // dependent on weekOfYear plugin
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(timezone);


const FormattedDatetime = (props) => {
    const { value, format, style } = props;
    if (value === null) {
        return '';
    }
    if (format === undefined || format === null) {
        return value.toString();
    }

    let format2 = format;
    format2 = format2.replaceAll('y', 'Y');
    format2 = format2.replaceAll('d', 'D');
    format2 = format2.replaceAll('\'T\'', 'T');
    format2 = format2.replaceAll('DDD', 'ddd');

    return (
        <div style={style}>
            {dayjs.utc(value).format(format2)}
        </div>
    );
};

FormattedDatetime.propTypes = {
    value: PropTypes.string,
    format: PropTypes.string,
    style: PropTypes.object
};

export default FormattedDatetime;
