import {useEffect, useState} from "react";
import {apiBaseUrl} from "../lib/config";
import useWS2Axios from "./useWS2Axios";


const useUsages = (entityDef, id) => {
    const [usages, setUsages] = useState(undefined);
    const {ws2Axios} = useWS2Axios();

    useEffect(() => {
        ws2Axios
            .get(apiBaseUrl + "crud/" + entityDef.apiGet + "/" + id + "/usages")
            .then(response => {
                let data = response.data;
                setUsages(data.usages)
            });
    }, [entityDef.entityType, id, entityDef.apiGet, ws2Axios]);

    return {
        usages
    };
};

export default useUsages;
