import React from "react";
import EntityTagNonInteractive from "../tk/bits/EntityTagNonInteractive";
import {addEntityDetailTab} from "../lib/flexlayoutTabTools";

export const newNotificationsContext = (messageApi, refLayout) => ({
    notifyEntityCreateSuccess: (entityRef, entityDef) => {
        messageApi.success({
            content: <>{entityDef.label} &nbsp;<EntityTagNonInteractive
                entityDef={entityDef} entityRef={entityRef}/> created successfully.</>,
            onClick: () => addEntityDetailTab(refLayout.current, entityRef, entityDef)
        });
    },
    notifySuccess: message => {
        messageApi.success(message);
    },
    notifyError: (message, error) => {
        let content = "";
        if (message) {
            content = message;
        }
        if (error) {
            if (content.length > 0) {
                content += ": ";
            }
            if (error.response) {
                if (error.response.data) {
                    content += error.response.data.code + " " + error.response.data.message;
                } else {
                    content += error.response.status + " " + error.response.statusText;
                }
            } else if (typeof error === "string") {
                content += error;
            } else {
                content += JSON.stringify(error);
            }
        }
        messageApi.error(content);
    }

});

const NotificationsContext = React.createContext({});

export default NotificationsContext;