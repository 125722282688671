import useEnumType from "../../hooks/useEnumType";
import SelectField from "./SelectField";
import React from "react";


export const SelectInputField = (props) => {
    const {enumType, ...restProps} = props;
    const options = useEnumType(enumType.typeName);
    return (
        <SelectField
            values={options}
            {...restProps}
        />
    );
};
