import useLocalStorage from "./useLocalStorage";
import {useCallback} from "react";


const useMyUser = () => {
    const [myUser, setMyUser] = useLocalStorage(
        "myUser",
        {id: null, commonName: null}
    );

    const login = useCallback(data => {
            setMyUser({
                ...data,
                id: data.userId,
                commonName: data.username
            });
        },
        [setMyUser]
    );

    const logout = useCallback(() => {
            setMyUser({userId: null});
        },
        [setMyUser]
    );

    return {
        myUser,
        login,
        logout
    }
}

export default useMyUser;
