import EntityHeader from "../entities/EntityHeader";
import React, {useContext} from "react";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import entityDefs from "../entities/entityDefs";
import {FormExtContext} from "../../../tk/forms/FormExt";
import { entityFields, renderField } from '../entities/entityFields';


const PanGroupDetail = (props) => {
    const {entity} = props;
    const {formState} = useContext(FormExtContext);

    return (
        <>
            <EntityHeader
                entity={entity}
                entityDef={entityDefs.panGroup}
                isDirty={formState.isDirty}
            />
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    {["name", "institution"].map(
                        name => renderField(entityFields.panGroup.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    {["comment"].map(
                        name => renderField(entityFields.panGroup.find(field => field.paramName === name))
                    )}
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default PanGroupDetail;
