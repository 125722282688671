import FileImportForm from './FileImportForm';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { uploadFile } from '../../../lib/importFile';
import useWS2Axios from '../../../hooks/useWS2Axios';
import UploadResponseView from './UploadResponseView';
import { Switch } from 'antd';
import HelpLink from '../../../tk/bits/HelpLink';
import MyOk from '../../../tk/bits/MyOk';
import MyError from '../../../tk/bits/MyError';
import { randomId } from '../../../lib/random';
import ContainerContext from '../../../contexts/ContainerContext';
import ParameterUploadReportView from './ParameterUploadReportView';
import { enrichParameterImportResponse } from './ParameterImportUtil';


const doNothing = () => undefined;

const requestImport = (options, process, extraQuery, ws2Axios, apiGet) => {
    if (!options.file || !options.file.name) {
        options.onError({ message: 'File handle gone' });
        return;
    }
    const filename = options.file.name.toLowerCase();
    let endpoint = 'bulk';
    if (filename.endsWith('.zip')) {
        endpoint = 'bulkzip';
    } else if (filename.endsWith('.ris')) {
        endpoint = 'bulkris';
    }
    const query = new URLSearchParams({
        ...extraQuery,
        process
    });

    const setResult = apiGet === 'parameter' ? doNothing : options.setResult;
    uploadFile(
        ws2Axios,
        response => {
            if (apiGet === 'parameter') {
                enrichParameterImportResponse(ws2Axios, response, options.onSuccess, options.onError, options.setResult, options.file);
            } else {
                options.onSuccess(response);
            }
        },
        response => {
            options.onError(response);
        },
        options.file,
        setResult,
        apiGet + '/' + endpoint + '?' + query.toString()
    );
};


const ListFileImporter = (props) => {
    const { initState, entityDef, onChange } = props;
    const [overwriteEnabled, setOverwriteEnabled] = useState(initState ? initState.overwriteEnabled : false);
    const [extraQuery, setExtraQuery] = useState({
            upsert: initState?.overwriteEnabled ? true : false
        })
    ;
    const [importState, setImportState] = useState({});
    const [reuploadToken, setReuploadToken] = useState(100);
    const { ws2Axios } = useWS2Axios();
    const { onDirtyChange } = useContext(ContainerContext);

    useEffect(() => {
            if (onDirtyChange) {
                onDirtyChange(true);
            }
        },
        [onDirtyChange]
    );

    const handleToggleOverwriteEnabled = useCallback(enabled => {
            setOverwriteEnabled(enabled);
            setExtraQuery(enabled ? { upsert: true } : {});
            setReuploadToken(randomId());
        },
        []
    );

    const extraSwitch = useMemo(() => (
            <div style={{ width: '200px', height: '100px', margin: '0 auto', marginTop: '50px' }}>
                Preserve&nbsp;&nbsp;&nbsp;
                <Switch
                    onChange={handleToggleOverwriteEnabled}
                    checked={overwriteEnabled}
                    //disabled={disabled}
                />
                &nbsp;&nbsp;&nbsp;Update
            </div>
        ),
        [handleToggleOverwriteEnabled, overwriteEnabled]
    );

    const [extraOptions, setExtraOptions] = useState(extraSwitch);

    useEffect(() => {
            setExtraOptions(extraSwitch);
        },
        [extraSwitch]
    );

    const entityDefWrapper = useMemo(() => initState?.entityDef || entityDef,
        [entityDef, initState?.entityDef]
    );

    const title = useMemo(() => (
            <span>Import {entityDefWrapper.label} List <HelpLink category="Import Entity Lists"
                                                                 title={entityDefWrapper.label} /></span>
        ),
        [entityDefWrapper.label]
    );

    const uploadHint = useMemo(() => (
            'Click or drag ' + entityDefWrapper.label + ' list to this area'
        ),
        [entityDefWrapper.label]
    );

    const composedState = useMemo(() => ({
            ...importState,
            overwriteEnabled
        }),
        [importState, overwriteEnabled]
    );

    useEffect(() => {
            if (!onChange) return;
            onChange(composedState);
        },
        [composedState, onChange]
    );

    const handleTest = useCallback(options => {
            requestImport(options, false, extraQuery, ws2Axios, entityDefWrapper.apiGet);
        },
        [entityDefWrapper.apiGet, extraQuery, ws2Axios]
    );

    const handleImport = useCallback(options => {
            requestImport(options, true, extraQuery, ws2Axios, entityDefWrapper.apiGet);
        },
        [entityDefWrapper.apiGet, extraQuery, ws2Axios]
    );

    const customError = useCallback(uploadResponse => (
            <>
                {entityDefWrapper?.entityType === 'parameter' &&
                    <ParameterUploadReportView
                        data={uploadResponse}
                        entityDef={entityDefWrapper}
                        preview={true}
                        dimensions={{
                            width: 300,
                            height: 800
                        }}
                    />
                }
                {entityDefWrapper?.entityType !== 'parameter' &&
                    <UploadResponseView
                        data={uploadResponse}
                        entityDef={entityDefWrapper}
                        dimensions={{
                            width: 300,
                            height: 800
                        }}
                    />
                }
            </>
        ),
        [entityDefWrapper]
    );

    const customResult = useCallback(result => {
            if (result.success) {
                return (
                    <div>
                        <h3>Import finished <MyOk /></h3>
                        {entityDefWrapper?.entityType === 'parameter' &&
                            <ParameterUploadReportView
                                data={result}
                                entityDef={entityDefWrapper}
                                preview={false}
                                dimensions={{
                                    width: 300,
                                    height: 800
                                }}
                            />
                        }
                    </div>
                );
            }
            return (
                <div>
                    <h3>Import failed <MyError /></h3>
                </div>
            );
        },
        [entityDefWrapper]
    );

    const handleReset = useCallback(() => {
            setOverwriteEnabled(false);
        },
        []
    );

    return (
        <FileImportForm
            initState={initState}
            title={title}
            uploadHint={uploadHint}
            onImport={handleImport}
            onTest={handleTest}
            customError={customError}
            customResult={customResult}
            onReset={handleReset}
            extraOptions={extraOptions}
            //extraDeps={extraDeps}
            onChange={setImportState}
            entityDef={entityDefWrapper}
            reuploadToken={reuploadToken}
            setReuploadToken={setReuploadToken}
        />

    );
};

export default ListFileImporter;
